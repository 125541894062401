<template>
  <DocumentsAISummaryChatMessage
    class="request-summary-message"
    :class="{ 'request-error': requestError }"
    :message-date="requestDate"
    :enable-actions="areActionsEnabledOnSummary"
  >
    {{ summaryText }}
  </DocumentsAISummaryChatMessage>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

import DocumentsAISummaryChatMessage from '@/project/documents/ai-summary/DocumentsAISummaryChatMessage.vue'
import documentsService from '@/services/documents.service'

export default defineComponent({
  name: 'DocumentsAISummaryRequestChatMessage',
  components: { DocumentsAISummaryChatMessage },
  emits: ['error', 'success'],
  props: {
    documentId: {
      type: Number,
      required: true,
    },
    requestDate: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      requestError: false,
      requestPending: false,
      summaryText: '...',
    }
  },
  async mounted () {
    await this.requestSummary()
  },
  computed: {
    ...mapGetters('room', ['roomMnemo']),
    areActionsEnabledOnSummary () {
      return !this.requestPending && !this.requestError
    },
  },
  methods: {
    async requestSummary () {
      this.requestPending = true

      try {
        const { data } = await documentsService.requestDocumentAISummary(this.roomMnemo, this.documentId)
        this.summaryText = data.summary
        this.$emit('success')
      } catch (error) {
        console.error(error)
        this.summaryText = this.$t('project.documents.ai-summary.DocumentsAISummaryRequestChatMessage.requestSummaryErrorText')
        this.requestError = true
        this.$emit('error')
      } finally {
        this.requestPending = false
      }
    },
  },
})
</script>

<style scoped lang="scss">
.request-summary-message.request-error {
  ::v-deep .message-bubble {
    background-color: var(--v-error-lighten1);
    border: 1px solid var(--v-error-base);
  }
}
</style>
