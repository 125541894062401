import axios from 'axios'

export default {
  getSigningView (mnemo, envelopeId, token, withoutApiToken) {
    if (withoutApiToken) {
      return axios.get(`room/${mnemo}/envelope/${envelopeId}/signingViewUrl/${token}`, { headers: { Authorization: '' } })
    }
    return axios.get(`room/${mnemo}/envelope/${envelopeId}/signingViewUrl/${token}`)
  },
}
