import { DISPLAY } from '@/projects/display'
export const initialRoomFiltersPaneState = {
  creationDate: [],
  dueDate: [],
  selectedLabels: [],
  projectType: [],
  role: [],
}

export const state = {
  processing: false,
  rooms: null,
  error: null,
  loadingRoomsInfo: false,
  roomTypes: null,
  roomLabels: null,
  filtersPane: {
    isOpen: false,
    filters: { ...initialRoomFiltersPaneState },
  },
  sort: 'creationDate',
  displayType: DISPLAY.grid,
  roomsLabelsError: null,
  roomsLabelsPending: false,
  subscriptionLabelsPending: false,
  subscriptionLabels: null,
  subscriptionLabelsError: null,
  dataRoomPricingPending: false,
  dataRoomPricing: null,
  dataRoomPricingError: null,
  dataRoomCustomPricingPending: false,
  dataRoomCustomPricing: null,
  dataRoomCustomPricingError: null,
  lastVisitedRooms: [],
  lastVisitedRoomsPending: false,
  recentRoomsPending: false,
  recentRooms: [],
  recentRoomsError: null,
  searchModeEnabled: false,
  roomSearchResultsPending: false,
  roomSearchResults: [],
  roomSearchResultsError: null,
}
