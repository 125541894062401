<template>
  <app-button
    type="outlined"
    full-width
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #left-icon>
      <slot name="left-icon" />
    </template>

    <slot />
  </app-button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DocumentsAISummaryRequestButton',
})
</script>

<style scoped lang="scss">
button {
  color: var(--v-purple-darken4) !important;
  outline-color: var(--v-purple-darken1) !important;

  &:hover {
    background-color: var(--v-purple-lighten5) !important;
  }

  &:disabled {
    color: var(--v-grey-lighten3) !important;
    outline-color: var(--v-grey-lighten4) !important;
  }
}
</style>
