<template>
  <div class="d-flex align-center justify-center h-100">
    <div v-if="signingViewPending" class="text-center">
      <p>{{$t('project.views.SigningView.loadingLabel')}}</p>
      <v-progress-circular
        style="width: 64px; height: 64px"
        color="primary"
        indeterminate
        size="64"
      />
    </div>
    <div v-else>
      <template v-if="canUserRefreshPage">
        <div class="d-flex flex-column align-center">
          <app-icon
            icon-name="exclamation-triangle"
            icon-weight="far"
            class="mb-2"
          />
          <app-text variant="large-bold">
            {{ $t('project.views.SigningView.errors.signatureUpdating.text') }}
          </app-text>
          <app-text variant="small-regular">
            {{ $t('project.views.SigningView.errors.signatureUpdating.subtext') }}
          </app-text>
          <app-button class="mt-8" @click="getSigningView">
            {{ $t('project.views.SigningView.errors.signatureUpdating.refreshButton') }}
          </app-button>
        </div>
      </template>
      <template v-else-if="signingViewError">
        <p v-html="computedSigningViewErrorMessage"></p>
      </template>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { isUserMissingIdCheck } from '@/common/utils/users'
import { GET_SIGNING_VIEW } from '@/store/modules/signature/action_types'
import { SET_SHOW_IDENTITY_CHECK_MODAL } from '@/store/modules/user/mutation_types'

const { mapState, mapActions } = createNamespacedHelpers('signature')
// Currently synced with the Id check auto close delay
const REFETCH_SIGNING_VIEW_DELAY = 3000

export default {
  name: 'SigningView',
  props: {
    mnemo: {
      type: String,
      required: true,
    },
    envelopeId: {
      type: [String, Number],
      required: true,
    },
    token: {
      type: String,
      default: '',
    },
    withoutApiToken: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['signingViewPending', 'signingView', 'signingViewError']),
    signingViewErrorMessageType () {
      let type = ''
      if (this.signingViewError && this.signingViewError.response) {
        type = this.signingViewError.response.data.errorCode
      }
      return type
    },
    isUserMissingIdCheck () {
      return isUserMissingIdCheck(this.$store.state.user.profile)
    },
    canUserRefreshPage () {
      return this.signingViewErrorMessageType === 'ERR_SIGNATURE_UPDATING'
    },
    computedSigningViewErrorMessage () {
      let message = ''
      if (this.signingViewError && this.signingViewError.response) {
        switch (this.signingViewErrorMessageType) {
          case 'ERR_ENVELOPE_INVALID_TOKEN':
            message = this.$t('project.views.SigningView.errors.invalidToken')
            break
          case 'ERR_SIGNATURE_ALREADY_SIGNED':
            message = this.$t('project.views.SigningView.errors.alreadySigned')
            break
          case 'ERR_SIGNATURE_VOIDED':
            message = this.$t('project.views.SigningView.errors.envelopeVoided')
            break
          case 'ERR_ENVELOPE_EXPIRED_TOKEN':
            message = this.$t('project.views.SigningView.errors.expiredToken')
            break
          case 'ERR_SIGNATURE_WRONG_STATUS':
            message = this.$t('project.views.SigningView.errors.wrongStatus')
            break
          case 'ERR_ID_CHECK_REQUIRED':
            message = this.$t('project.views.SigningView.errors.idCheckRequired')
            break
          default:
            message = this.$t('common.msgFailErrorOccurred')
        }
      }
      return message
    },
  },
  watch: {
    isUserMissingIdCheck (value) {
      // If we passed the id check, it's now false during the runtime of this component
      if (!value) {
        // Don't instantly re-fetch
        // this call is pretty fast and the user may get instantly redirected
        setTimeout(() => {
          this.GET_SIGNING_VIEW({
            mnemo: this.mnemo,
            envelopeId: this.envelopeId,
            token: this.token,
          })
        }, REFETCH_SIGNING_VIEW_DELAY)
      }
    },
  },
  async created () {
    await this.getSigningView()
  },
  methods: {
    ...mapActions([GET_SIGNING_VIEW]),
    async getSigningView () {
      try {
        await this.GET_SIGNING_VIEW({
          mnemo: this.mnemo,
          envelopeId: this.envelopeId,
          token: this.token,
          withoutApiToken: this.withoutApiToken,
        })
      } catch (e) {
        if (this.signingViewErrorMessageType) {
          if (this.signingViewErrorMessageType === 'ERR_ID_CHECK_REQUIRED') {
            this.showIdCheckModal()
          }
        } else {
          throw e
        }
      }
    },
    showIdCheckModal () {
      this.$store.commit('user/' + SET_SHOW_IDENTITY_CHECK_MODAL, true)
    },
  },
}
</script>
