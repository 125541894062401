<template>
  <AppDialog ref="PolyOfficeDialog"
             :is-open="isDialogOpened"
             hide-header
             hide-footer
             size="xxxl"
  >
    <template #default>
      <v-card>
        <div v-if="dialogPending" class="text-center">
          <AppLoader/>
        </div>

        <iframe v-if="dialogIframeUrl"
                class="PolyOfficeDialog-iframe"
                :src="dialogIframeUrl"
        ></iframe>
        <AppButton v-if="closeButton"
                   class="close-button"
                   icon
                   @click="handleCloseDialog"
        >
          <font-awesome-icon :icon="['fal', 'xmark']" size="lg"/>
        </AppButton>
      </v-card>
    </template>
  </AppDialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import AppLoader from '@/common/AppLoader.vue'
import AppButton from '@/common/buttons/AppButton.vue'
import AppDialog from '@/common/dialogs/AppDialog.vue'
import { dialogMixin } from '@/mixins/dialogMixin'
import { CANCELED, COMPLETED } from '@/services/polyOffice.service'
import { RESET_DIALOG } from '@/store/modules/poly-office/action_types'

export const POLY_OFFICE_DIALOG_STATUS_SUCCESS = 'success'

export default {
  name: 'PolyOfficeDialog',
  components: {
    AppButton,
    AppLoader,
    AppDialog,
  },
  mixins: [dialogMixin],
  props: {
    /**
     * If close button is displayed
     */
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('polyOffice', [
      'dialogPending',
      'dialogIframeUrl',
    ]),
  },
  mounted: function () {
    window.addEventListener('message', this.handleEventReturn, false)
  },
  methods: {
    ...mapActions('polyOffice', [
      RESET_DIALOG,
    ]),
    handleEventReturn (e) {
      if (e.data) {
        const event = e.data
        switch (event.type) {
          case CANCELED:
            this.handleCloseDialog()
            break
          case COMPLETED:
            console.info('handle event completed !', event)
            this.$emit('callback', {
              status: POLY_OFFICE_DIALOG_STATUS_SUCCESS,
              event,
            })
            this.handleCloseDialog()
            break
        }
      }
    },
    handleCloseDialog () {
      window.removeEventListener('message', this.handleEventReturn, false)
      this.closeDialog()
      this.RESET_DIALOG()
    },
  },
}
</script>

<style scoped lang="scss">
.PolyOfficeDialog-iframe {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  border: none;
}

.close-button {
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2000
}
</style>
