<template>
  <Pane v-model="checklistVersioningPane.isOpen"
        class="ChecklistVersioningPane"
        :temporary="!isDocumentOnGoingEditionDialogOpen"
        :tabs="tabs"
        :current-tab.sync="tab"
        @input="onClosePane"
  >
    <template #content-manage>
      <!-- Custom overlay to manage overlay with pane and other dialogs -->
      <v-overlay v-if="isDocumentOnGoingEditionDialogOpen"></v-overlay>

      <DocumentVersioning :loading="attachedFileVersionsPending || postAttachedFileVersionsPending"
                          :selected-documents="checklistVersioningPane.documents"
                          @setIsDocumentOnGoingEditionDialogOpen="isDocumentOnGoingEditionDialogOpen = $event"
                          @add-version="onAddVersion"
                          @close-pane="onClosePane"
      />
    </template>
  </Pane>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DocumentVersioning from '@/common/document-versioning/DocumentVersioning'
import Pane from '@/common/Pane'
import {
  CHANGE_CHECKLIST_VERSIONING_PANE_TAB,
  CLOSE_CHECKLIST_VERSIONING_PANE,
  GET_GLOBAL_TASK_FOLDER,
  GET_TASK_FOLDER,
  POST_ATTACHED_FILE_VERSION,
} from '@/store/modules/checklist/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

const TRANSLATION_KEY_PREFIX = 'project.checklist.pane.ChecklistVersioningPane'

export default {
  name: 'ChecklistVersioningPane',
  components: {
    DocumentVersioning,
    Pane,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      isDocumentOnGoingEditionDialogOpen: false,
    }
  },
  computed: {
    ...mapState('documents', ['numberingEnabled']),
    ...mapState('checklist', ['attachedFileVersionsPending', 'checklistVersioningPane', 'globalAttachedFilesDialogIsOpen', 'postAttachedFileVersionsPending']),
    tab: {
      get () {
        return this.checklistVersioningPane.tab
      },
      set (val) {
        this.CHANGE_CHECKLIST_VERSIONING_PANE_TAB(val)
      },
    },
    tabs () {
      const tabs = []
      if (this.checklistVersioningPane.documents.length === 1 && this.checklistVersioningPane.documents[0].type === 'file') {
        tabs.push({
          title: this.$t(`${TRANSLATION_KEY_PREFIX}.tabManage`),
          id: 'manage',
        })
      }
      return tabs
    },
  },
  methods: {
    ...mapActions('checklist', [
      CHANGE_CHECKLIST_VERSIONING_PANE_TAB,
      CLOSE_CHECKLIST_VERSIONING_PANE,
      GET_GLOBAL_TASK_FOLDER,
      GET_TASK_FOLDER,
      POST_ATTACHED_FILE_VERSION,
    ]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    async onAddVersion (data) {
      try {
        await this.POST_ATTACHED_FILE_VERSION({
          mnemo: this.mnemo,
          documentId: this.checklistVersioningPane.documents[0].id,
          data: data,
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.postVersionSuccess`))

        if (this.globalAttachedFilesDialogIsOpen) {
          this.GET_GLOBAL_TASK_FOLDER(this.mnemo)
        } else {
          this.GET_TASK_FOLDER({
            mnemo: this.mnemo,
            folderId: this.checklistVersioningPane.documents[0].parent.id,
          })
        }

        this.onClosePane()
      } catch (e) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.postVersionError`))
      }
    },
    onClosePane (value) {
      if (!value) {
        this.CLOSE_CHECKLIST_VERSIONING_PANE()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.ChecklistVersioningPane {
  z-index: 203 !important;
}
</style>
