import store from '@/store/'
/**
 * A function that wraps the pendo agent track method safely.
 * Can also be used to add more information to every tracked event.
 * @param {string} eventName Name of the event to track
 * @param {object} payload Object containing properties to send to Pendo
 */
function track (eventName, payload) {
  if (store.state.global.isPendoLoaded) {
    window.pendo.track(eventName, payload)
  }
}
export {
  track,
}
