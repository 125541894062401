<template>
    <AppCard>
      <!-- Sort is handled by ProjectsTableHeader -->
      <v-data-table disable-sort
                    class="projects-table"
                    :items="sortedProjects"
                    :headers="headers"
                    :hide-default-header="true"
                    :footer-props="{
                      'items-per-page-options': defaultPageSizeOptions,
                    }"
                    :options.sync="tableOptions"
      >
        <template #header="{props}">
          <thead>
            <ProjectsTableHeader v-for="header in props.headers"
                                 :key="header.value"
                                 :column-name="header.value"
                                 :fit-width="header.fitWidth"
                                 :header="header.text"
                                 :sorting="sorting"
                                 @sort="sortTable"
            />
          </thead>
        </template>

        <template #item="{item}">
          <ProjectsTableItem :room="item"
                             @click.native="$router.push({ name: ROOM_ROUTE_NAME, params: { mnemo: item.mnemo, roomType: getRoomRouteType(item)} })"
          />
        </template>

        <template #footer.prepend>
          <v-pagination
            v-model="tableOptions.page"
            :length="tableOptions.totalPages"
            :total-visible="5"
            :disabled="!tableOptions.paginationIsEnabled"
            @input="changePage"
          ></v-pagination>
        </template>
      </v-data-table>
    </AppCard>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { mapGetters } from 'vuex'

import { getRoomCount, getRoomRouteType } from '@/common/utils/rooms'
import { ROOM_ROUTE_NAME } from '@/router'

import ProjectsTableHeader from './ProjectsTableHeader'
import ProjectsTableItem from './ProjectsTableItem'
import AppCard from '../../common/AppCard'
import { dateSort, numericSort } from '../../common/utils/sorting'
import { getUserSetting, setUserSetting } from '../../common/utils/userSettings'

const DEFAULT_PAGE_SIZE = 25
const DEFAULT_PAGE_SIZE_OPTIONS = [25, 50, 75, 100]

export default {
  name: 'ProjectsTable',
  components: {
    AppCard,
    ProjectsTableHeader,
    ProjectsTableItem,
  },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    hideMembers: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      defaultPageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
      sorting: {
        name: 'name',
        order: 'asc',
      },
      tableOptions: {
        page: 1,
        totalPages: 1,
        paginationIsEnabled: false,
        itemsPerPage: DEFAULT_PAGE_SIZE,
      },
      headers: [
        {
          text: this.$t('projects.table.ProjectsTable.header.type'),
          value: 'type',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.name'),
          value: 'name',
        },
        {
          value: this.hideMembers ? 'hiddenNbParticipants' : 'nbParticipants',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.label'),
          value: 'label',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.cmRef'),
          value: 'cmRef',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.createdAt'),
          value: 'createdAt',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.expectedClosingDate'),
          value: 'expectedClosingDate',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.documents'),
          value: 'documentsCount',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.qa'),
          value: 'qaCount',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.signedDocuments'),
          value: 'originalsCount',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.tasks'),
          value: 'tasksCount',
        },
        {
          text: this.$t('projects.table.ProjectsTable.header.signaturesPending'),
          value: 'signaturesPendingCount',
        },
      ],
      ROOM_ROUTE_NAME,
    }
  },
  computed: {
    ...mapGetters('user', ['currentUserId']),
    sortedProjects () {
      const projects = cloneDeep(this.projects)

      projects.sort((a, b) => {
        switch (this.sorting.name) {
          case 'type': {
            if (a.isDataroom && !b.isDataroom) {
              return this.sorting.order === 'asc' ? -1 : 1
            } else if (!a.isDataroom && b.isDataroom) {
              return this.sorting.order === 'asc' ? 1 : -1
            }

            return 0
          }
          case 'name': {
            return numericSort(a[this.sorting.name], b[this.sorting.name], this.sorting.order === 'desc')
          }
          case 'label': {
            return numericSort(
              a.label?.label[this.$i18n.locale],
              b.label?.label[this.$i18n.locale],
              this.sorting.order === 'desc',
            )
          }
          case 'createdAt':
          case 'expectedClosingDate': {
            return dateSort(a[this.sorting.name], b[this.sorting.name], this.sorting.order === 'desc')
          }
          case 'documentsCount': {
            const aDocumentsCount = getRoomCount(a, 'files')
            const bDocumentsCount = getRoomCount(b, 'files')

            return this.sortCount(aDocumentsCount, bDocumentsCount)
          }
          case 'qaCount': {
            const aQuestionsCount = getRoomCount(a, 'questions')
            const bQuestionsCount = getRoomCount(b, 'questions')

            return this.sortCount(aQuestionsCount, bQuestionsCount)
          }
          case 'originalsCount': {
            const aOriginalsCount = getRoomCount(a, 'originals')
            const bOriginalsCount = getRoomCount(b, 'originals')

            return this.sortCount(aOriginalsCount, bOriginalsCount)
          }
          case 'tasksCount': {
            const aTasksCount = getRoomCount(a, 'tasksTagged')
            const bTasksCount = getRoomCount(b, 'tasksTagged')

            return this.sortCount(aTasksCount, bTasksCount)
          }
          case 'signaturesPendingCount': {
            const aSignaturesPendingCount = getRoomCount(a, 'signaturesPending')
            const bSignaturesPendingCount = getRoomCount(b, 'signaturesPending')

            return this.sortCount(aSignaturesPendingCount, bSignaturesPendingCount)
          }
        }
      })

      return projects
    },
  },
  watch: {
    'tableOptions.itemsPerPage': {
      handler (itemsPerPage) {
        this.changeItemsPerPage(itemsPerPage)
      },
    },
    projects: {
      handler (projects) {
        const totalPages = this.computeTotalPage(this.tableOptions.itemsPerPage)

        this.tableOptions = {
          ...this.tableOptions,
          page: 1,
          totalPages: totalPages,
          paginationIsEnabled: totalPages > 1,
        }
      },
    },
  },
  mounted () {
    const itemsPerPageStorage = getUserSetting(this.currentUserId, 'projects-items-per-page')
    const itemsPerPage = itemsPerPageStorage !== null ? parseInt(itemsPerPageStorage) : DEFAULT_PAGE_SIZE
    this.changeItemsPerPage(itemsPerPage)
  },
  methods: {
    getRoomRouteType,
    sortTable (columnName) {
      if (this.sorting.name === columnName) {
        this.sorting.order === 'asc' ? this.sorting.order = 'desc' : this.sorting.order = 'asc'
      } else {
        this.sorting.name = columnName
        this.sorting.order = 'asc'
      }
    },
    sortCount (countA, countB) {
      const a = isNaN(countA) ? -1 : countA
      const b = isNaN(countB) ? -1 : countB
      const compareResult = a > b ? 1 : -1

      return this.sorting.order === 'asc' ? compareResult : compareResult * -1
    },
    changePage (page) {
      this.tableOptions.page = page
    },
    changeItemsPerPage (itemsPerPage) {
      setUserSetting(this.currentUserId, 'projects-items-per-page', itemsPerPage)

      const totalPages = this.computeTotalPage(itemsPerPage)

      this.tableOptions = {
        ...this.tableOptions,
        page: 1,
        itemsPerPage: itemsPerPage,
        totalPages: totalPages,
        paginationIsEnabled: totalPages > 1,
      }
    },
    computeTotalPage (itemsPerPage) {
      return Math.max(Math.ceil(this.projects.length / itemsPerPage), 1)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .v-data-footer__icons-before,
::v-deep .v-data-footer__icons-after {
  display: none;
}
::v-deep .v-data-footer {
  flex-direction: row-reverse;
}
::v-deep .projects-table {
  tbody tr {
    cursor: pointer;
  }
}
</style>
