<template>
  <div>
    <AppDialog :is-open="isOpen"
               size="xxl"
               :cancel-only="!userCanAddVersion"
               :cancel-text="$t('common.close')"
               :ok-text="$t('project.documents.dialogs.DocumentsVersionHistoryDialog.addVersion')"
               @cancel="onCancel"
               @ok="onOk"
    >
      <template #title>{{$t('project.documents.dialogs.DocumentsVersionHistoryDialog.title')}}</template>
      <template #body>
        <template v-if="versionsLoading">
          <div class="text-center">
            <AppLoader></AppLoader>
          </div>
        </template>
        <template v-if="versions && !versionsLoading">
          <v-data-table :items="versions"
                        :headers="headers"
                        calculate-widths
                        disable-pagination
                        hide-default-footer
                        :items-per-page="-1"
                        disable-sort
          >
            <template #item.name="{item}">
              <div class="clickable">
                <AppDownloadFile :link="versionLink(item.id)">
                  <template #default="{ submitForm }">
                    <span class="versionName" @click="submitForm">{{ item.basename }}</span>
                  </template>

                </AppDownloadFile>
              </div>
              <div class="caption">
                {{ fileUploadDate(item.uploadTime) }}
              </div>
            </template>
            <template #item.share="{item}">
              <RightsDisplayButton v-if="isCurrentUserPm && item.rights"
                                   :rights="item.rights"
                                   :hoverOnly="noShare"
                                   @click="onShareClick(item)"
              />
            </template>
            <template #item.size="{item}">
              <app-filesize-display :value="item.size"/>
            </template>
            <template v-if="!noDelete" #item.delete="{item}">
              <AppButton v-if="item.id !== document.id && isCurrentUserPm"
                         icon
                         @click="displayDeleteVersionDialog(item)"
              >
                <font-awesome-icon :icon="['fal', 'trash-can']" ></font-awesome-icon>
              </AppButton>
            </template>
          </v-data-table>
        </template>
      </template>
    </AppDialog>
    <AppDialog :is-open="deleteVersionDialog"
               size="l"
               :ok-text="$t('common.yes')"
               :ok-loading="documentsVersioningPane.removeVersionPending"
               :ok-disabled="documentsVersioningPane.removeVersionPending"
               :cancel-disabled="documentsVersioningPane.removeVersionPending"
               :cancel-text="$t('common.close')"
               @ok="prepareDeleteVersion"
               @cancel="deleteVersionDialog = false"
    >
      <template #title>{{ $t('project.documents.dialogs.DocumentsVersionHistoryDialog.confirmDeleteDialog.title') }}</template>
      <template #body>
        <div>
          {{ $t('project.documents.dialogs.DocumentsVersionHistoryDialog.confirmDeleteDialog.body', { version: versionToDelete ? versionToDelete.version : '', name: versionToDelete ? versionToDelete.basename : '' }) }}
        </div>
        <div class="mt-4">
          {{ $t('project.documents.dialogs.DocumentsVersionHistoryDialog.confirmDeleteDialog.body2') }}
        </div>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppLoader from '@/common/AppLoader'
import AppButton from '@/common/buttons/AppButton'
import { ISOToShortenedDate } from '@/common/utils/dates'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import AppDialog from '../../../common/dialogs/AppDialog'
import RightsDisplayButton from '../../../common/users/RightsDisplayButton.vue'
import { DELETE_VERSION, GET_DOCUMENT_VERSIONS, SET_TABLE_DOCUMENTS_PANE } from '../../../store/modules/documents/action_types'

export default {
  name: 'DocumentsVersionHistoryDialog',
  components: {
    AppButton,
    AppDialog,
    AppDownloadFile,
    AppLoader,
    RightsDisplayButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    document: {
      type: Object,
      validator: (value) => {
        return value === null || typeof value === 'object'
      },
    },
    mnemo: {
      type: String,
      required: true,
    },
    noShare: {
      type: Boolean,
      default: false,
    },
    noDelete: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      versions: null,
      versionToDelete: null,
      deleteVersionDialog: false,
      hasDeletion: false,
    }
  },
  computed: {
    ...mapState('documents', ['versionsLoading', 'documentsTablePane', 'documentsVersioningPane']),
    ...mapGetters('room', ['currentUserRights', 'isCurrentUserPm']),
    headers () {
      const menu = [
        { text: '#', value: 'version' },
        { text: this.$t('project.documents.dialogs.DocumentsVersionHistoryDialog.name'), value: 'name' },
        { text: this.$t('project.documents.dialogs.DocumentsVersionHistoryDialog.share'), value: 'share' },
        { text: this.$t('project.documents.dialogs.DocumentsVersionHistoryDialog.size'), value: 'size' },
        { text: this.$t('project.documents.dialogs.DocumentsVersionHistoryDialog.uploadUser'), value: 'uploadUser' },
      ]
      if (!this.noDelete) {
        menu.push({ value: 'delete' })
      }
      return menu
    },
    userCanAddVersion () {
       return this.isCurrentUserPm || this.currentUserRights.canUploadVersions
    },
  },
  watch: {
    isOpen: {
      handler (val) {
        if (val) {
          this.prepareGetDocumentVersions()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('documents', [DELETE_VERSION, GET_DOCUMENT_VERSIONS, SET_TABLE_DOCUMENTS_PANE]),
    onCancel () {
      if (this.hasDeletion) {
        this.$emit('refreshDocuments')
      }
      this.versions = null
      this.versionToDelete = null
      this.deleteVersionDialog = false
      this.hasDeletion = false
      this.$emit('onCancel')
    },
    async prepareGetDocumentVersions () {
      try {
        const response = await this.GET_DOCUMENT_VERSIONS({
          mnemo: this.mnemo,
          documentId: this.document.id,
        })
        this.versions = response.data
      } catch (error) {
        console.error(error)
      }
    },
    fileUploadDate (date) {
      return ISOToShortenedDate(date)
    },
    versionLink (versionId) {
      return `${this.axios.defaults.baseURL}/room/${this.$route.params.mnemo}/downloads/file/${versionId}`
    },
    onOk () {
      this.$emit('addVersionClicked')
      this.onCancel()
    },
    onShareClick (item) {
      if (!this.noShare) {
        this.SET_TABLE_DOCUMENTS_PANE({ isOpen: true, documents: [item], tab: 'share', forcePmSelection: true, previousDocument: this.documentsTablePane.documents[0] })
        this.onCancel()
      }
    },
    displayDeleteVersionDialog (version) {
      this.versionToDelete = version
      this.deleteVersionDialog = true
    },
    async prepareDeleteVersion () {
      try {
        await this.DELETE_VERSION(this.versionToDelete)
        this.prepareGetDocumentVersions()
        this.hasDeletion = true
      } catch (e) {
        this.$store.commit(ENQUEUE_SNACKBAR, {
          color: 'error',
          message: this.$t('project.documents.dialogs.DocumentsVersionHistoryDialog.errorDelete'),
        })
      } finally {
        this.deleteVersionDialog = false
      }
    },
  },
}
</script>
