<template>
  <v-row class="landing-row">
    <v-col class="landing-col">
      <div class="logo">
        <img src="/img/logo.svg"/>
      </div>
      <div class="landing-section">
        <img v-if="illustrationName" alt="" :src="`/img/landing-page/${illustrationName}.svg`" />
        <div class="text-center">
          <h2 class="landing-title">{{ title }}</h2>
            <i18n v-if="isFullAccessLogin" :path="`sign.views.LandingPage.${textRedirect}`" tag="p">
              <template #textSigningChecklist>
                <router-link
                    class="link-text"
                    :to="{ name: SIGNING_CHECKLIST_ROUTE_NAME, params: { mnemo, roomType: ROOM_ROUTE_TYPES.PROJECT } }"
                >
                    {{ $t('sign.views.LandingPage.textSigningChecklist') }}
                </router-link>
              </template>
              <template #redirectTime>
                <span>{{ redirectTime }}</span>
              </template>
            </i18n>
        </div>
      </div>
    </v-col>
    <v-col v-if="isShowDiscover" class="landing-col">
      <div class="landing-section landing-discover">
        <LawyerCarousel></LawyerCarousel>
        <div class="text-center">
         <h2 class="landing-title discover-title">{{ $t('sign.views.LandingPage.discover.title') }}</h2>
         <p class="discover-text mb-5">
           {{ $t('sign.views.LandingPage.discover.content') }}
         </p>
         <div class="discover-button d-flex justify-center pt-5">
           <AppButton
           type="primary"
           size="medium"
           variant="project"
           @click="openDemoClosd"
           >
           {{ $t('sign.views.LandingPage.discover.button') }}
           </AppButton>
          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { DARK_BLUE_500 } from '@/common/utils/colors'
import AppButton from '@/design-system/buttons/AppButton.vue'

import LawyerCarousel from './LawyerCarousel.vue'
import { ROOM_ROUTE_TYPES, SIGNING_CHECKLIST_ROUTE_NAME } from '../../router'

const SUPPORTED_EVENTS = ['signing_complete', 'decline', 'cancel']

export default {
  name: 'LandingPage',
  components: { AppButton, LawyerCarousel },
  props: {
    event: {
      type: String,
      required: true,
      validator (value) {
        return SUPPORTED_EVENTS.includes(value)
      },
    },
    mnemo: {
      type: String,
      default: '',
    },
    isSubUser: {
      type: String,
      default: '',
    },
    usesSimpleSignature: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      redirectTime: 4,
      SIGNING_CHECKLIST_ROUTE_NAME,
      ROOM_ROUTE_TYPES,
      redirectId: 0,
      isShowDiscover: this.usesSimpleSignature === 'true' && this.isSubUser !== 'true',
      DARK_BLUE_500,
    }
  },
  metaInfo () {
    return {
      title: this.$t('sign.views.LandingPage.metaTitle'),
    }
  },
  mounted () {
    this.startCountdown()
  },
  computed: {
    ...mapGetters('login', ['isFullAccessLogin']),
    ...mapState('room', ['currentRoom']),
    illustrationName () {
      if (!SUPPORTED_EVENTS.includes(this.event)) return null
      return this.event === 'signing_complete' ? 'signed_illustration' : 'unsigned_illustration'
    },
    title () {
      if (!SUPPORTED_EVENTS.includes(this.event)) return ''
      return this.$t('sign.views.LandingPage.simple.title.' + this.event)
    },
    textRedirect () {
      return this.isShowDiscover ? 'textRedirect' : 'textRedirectAutomatic'
    },
  },
  methods: {
    openDemoClosd () {
      window.location.href = 'https://www.closd.com/fr/planifier-votre-demo-closd/?utm_source=signpageclosd&utm_medium=banner&utm_campaign=invites-page-signature'
    },
    startCountdown () {
      this.timer = setInterval(() => {
        if (this.redirectTime > 0) {
          this.redirectTime--
        } else {
          clearInterval(this.timer)
        }
      }, 1000)
    },
  },
  created () {
    if (this.isFullAccessLogin && !this.isShowDiscover) {
      this.redirectId = setTimeout(() => {
        this.$router.push({
          name: this.SIGNING_CHECKLIST_ROUTE_NAME,
          params: { mnemo: this.mnemo, roomType: ROOM_ROUTE_TYPES.PROJECT },
        })
      }, 6000)
    }
  },
  beforeDestroy () {
    clearTimeout(this.redirectId)
    clearInterval(this.timer)
  },
}
</script>
<style lang="scss" scoped>
.logo {
  position: absolute;
  @media #{map-get($display-breakpoints, 'xs-only')}{
    position: relative;
    margin: 20px auto;
    border-bottom: 1px solid #E3E5E7;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}
.link-text {
  text-decoration: none;
  color: v-bind(DARK_BLUE_500) !important;
}
.landing-row {
  min-height:100vh;
  background:white;
  padding: 30px 10px 10px 10px;
}
.landing-col {
  position: relative;
}
.landing-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  &.landing-discover {
      background: var(--v-originalGrey-lighten5);
      border-radius: 24px;
      width: 100%;
      @media #{map-get($display-breakpoints, 'xs-only')}{
       max-width: 100vw;
     }
  }
  @media #{map-get($display-breakpoints, 'xs-only')}{
    height: auto;
  }
}
.landing-title {
  font-size: 24px !important;
  margin-top: 32px;
  margin-bottom: 12px;
  color: var(--v-grey-darken4);
}
.discover-title {
  padding-left: 20px;
  padding-right: 20px;
  @media #{map-get($display-breakpoints, 'xs-only')}{
    margin-top: 0px;
    padding: 0px 20px;
  }
}
.discover-text {
  padding-left: 60px;
  padding-right: 60px;
  @media #{map-get($display-breakpoints, 'xs-only')}{
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.discover-button {
  margin-bottom: 40px;
}
</style>
