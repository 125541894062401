<template>
  <AppCard height="100%"
           :hover="true"
           class="ProjectsGridTemplate-card pa-5 h-100"
           @click.native="$router.push({ name: ROOM_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: computedRoomPathType } })"
  >
    <v-row no-gutters class="ProjectsGrid-header mb-4">
      <v-col class="d-flex align-center justify-space-between">
        <span v-if="project.nbParticipants">
          <AppTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
              >
                <font-awesome-icon
                  class="mr-2"
                  :icon="['fas', 'user-friends']"
                  style="color: #626262"
                />
                <span>{{ project.nbParticipants }}</span>
              </span>
            </template>
            {{ $tc('projects.ProjectsGrid.nbParticipants', project.nbParticipants, { nbParticipants: project.nbParticipants }) }}
          </AppTooltip>
        </span>
        <!-- Menu -->
        <div class="ml-auto" >
          <ProjectsFoldersGridMenu v-if="projectsFoldersTree.length > 0 && !hasRecentRooms" :item="project" isRoom ></ProjectsFoldersGridMenu>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col class="text-h3 overflow-hidden d-flex align-center justify-space-between"
             style="line-height: 20px; max-height: 40px;"
      >
        <div class="d-flex align-center" style="width: inherit">
          <RoomTypeIcon class="mr-2" :room="project"/>
          <AppTooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs"
                    class="text-truncated"
                    v-on="on"
              >{{ project.name }}</span>
            </template>
            {{ project.name }}
          </AppTooltip>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="project.label">
      <v-col class="pt-0">
        <!-- Label -->
        <AppProjectLabel
          :label="project.label"
        >
        </AppProjectLabel>
      </v-col>
    </v-row>
    <v-row v-if="project.cmRef" class="mt-2">
      <v-col class="py-0">
        <AppTooltip top>
          <template v-slot:activator="{ attrs, on }">
            <div
              class="d-flex align-center"
              v-bind="attrs"
              v-on="on"
            >
              <app-icon icon-name="hashtag" icon-weight="fal" class="mr-1" size="small"></app-icon>
              <span class="text-truncated">{{ project.cmRef }}</span>
            </div>
          </template>
          <span class="text-caption">{{ project.cmRef }}</span>
        </AppTooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="project.createdAt" class="py-0">
        <div class="d-flex align-center">
          <AppTooltip top>
            <template v-slot:activator="{attrs, on}">
              <div
                class="d-flex"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="14"
                  class="mr-2 grey--text"
                >
                  fal fa-clock
                </v-icon>
                <span >{{displayDate(project.createdAt)}}</span>
              </div>
            </template>
            <span class="text-caption">{{$t('projects.ProjectsGrid.creationDate')}}</span>
          </AppTooltip>
        </div>
      </v-col>
      <v-col v-if="project.expectedClosingDate" class="py-0">
        <div class="d-flex align-center">
          <AppTooltip top>
            <template v-slot:activator="{attrs, on}">
              <div
                class="d-flex"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="14"
                  class="mr-2 grey--text"
                >
                  fal fa-calendar-alt
                </v-icon>
                <span >{{displayDate(project.expectedClosingDate)}}</span>
              </div>
            </template>
            <span class="text-caption">{{$t('projects.ProjectsGrid.dueDate')}}</span>
          </AppTooltip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <template v-if="documentsCount === null">
            <v-skeleton-loader type="text"/>
          </template>
          <template v-else>
            <router-link :to="{ name: ROOM_DOCUMENTS_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: computedRoomPathType } }"
                         class="ProjectsGrid-link"
                         @click.native.stop
            >
              <span :class="[computedTextColorTheme, 'count-value']">{{ documentsCount }}</span>
              <span class="accent--text text--lighten-1">{{ $t('projects.ProjectsGrid.documents') }}</span>
            </router-link>
          </template>
        </div>
        <div v-if="project.isDataroom">
          <template v-if="questionsCount === null">
            <v-skeleton-loader type="text"/>
          </template>
          <template v-else>
            <router-link class="ProjectsGrid-link"
                         :to="{ name: QUESTION_ANSWER_LIST_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: computedRoomPathType } }"
                         @click.native.stop
            >
              <span :class="[computedTextColorTheme, 'count-value']">{{ questionsCount }}</span>
              <span class="accent--text text--lighten-1">{{ $t('projects.ProjectsGrid.questions') }}</span>
            </router-link>
          </template>
        </div>
        <template v-if="!project.isDataroom">
          <div>
            <template v-if="originalsCount === null">
              <v-skeleton-loader type="text"/>
            </template>
            <template v-else>
              <router-link class="ProjectsGrid-link"
                          :to="{ name: ROOM_SIGNED_DOCUMENTS_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: computedRoomPathType } }"
                          @click.native.stop
              >
                <span :class="[computedTextColorTheme, 'count-value']">{{ originalsCount }}</span>
                <span class="accent--text text--lighten-1">{{ $t('projects.ProjectsGrid.originals') }}</span>
              </router-link>
            </template>
          </div>
          <div>
            <template v-if="tasksCount === null">
              <v-skeleton-loader type="text"/>
            </template>
            <template v-else>
              <router-link class="ProjectsGrid-link"
                          :to="{ name: ROOM_CHECKLIST_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: computedRoomPathType } }"
                          @click.native.stop
              >
                <span :class="[computedTextColorTheme, 'count-value']">{{ tasksCount }}</span>
                <span class="accent--text text--lighten-1">{{ $t('projects.ProjectsGrid.tasks') }}</span>
              </router-link>
            </template>
          </div>
          <div>
            <template v-if="documentsToSignCount === null">
              <v-skeleton-loader type="text"/>
            </template>
            <template v-else>
              <router-link class="ProjectsGrid-link"
                          :to="{ name: SIGNING_CHECKLIST_ROUTE_NAME, params: { mnemo: project.mnemo, roomType: computedRoomPathType } }"
                          @click.native.stop
              >
                <span :class="[computedTextColorTheme, 'count-value']">{{ documentsToSignCount }} </span>
                <span v-if="project.isPM" class="accent--text text--lighten-1">{{ $t('projects.ProjectsGrid.pendingSignaturesPM') }}</span>
                <span v-else class="accent--text text--lighten-1">{{ $t('projects.ProjectsGrid.pendingSignatures') }}</span>
              </router-link>
            </template>
          </div>
        </template>
      </v-col>
    </v-row>
  </AppCard>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import { getRoomColorTheme, getRoomIcon, getRoomRouteType } from '@/common/utils/rooms'
import ProjectsFoldersGridMenu from '@/projects/ProjectsFoldersGridMenu.vue'
import {
  QUESTION_ANSWER_LIST_ROUTE_NAME,
  ROOM_CHECKLIST_ROUTE_NAME,
  ROOM_DOCUMENTS_ROUTE_NAME,
  ROOM_ROUTE_NAME,
  ROOM_SIGNED_DOCUMENTS_ROUTE_NAME,
  SIGNING_CHECKLIST_ROUTE_NAME,
} from '@/router/index'

import AppCard from '../common/AppCard'
import AppProjectLabel from '../common/AppProjectLabel'
import AppTooltip from '../common/AppTooltip.vue'
import RoomTypeIcon from '../common/RoomTypeIcon'
import { ISOToShortenedDate } from '../common/utils/dates'

// Current width, rounded to the nearest 10, of the content width for projectGrid cards
// Used especially to get a truncate that truncate pretty much at the same point on tables
export const PROJECT_CARD_CONTENT_WIDTH = 280

export default {
  name: 'ProjectsGrid',
  components: { RoomTypeIcon, AppProjectLabel, AppCard, AppTooltip, ProjectsFoldersGridMenu },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      QUESTION_ANSWER_LIST_ROUTE_NAME,
      ROOM_CHECKLIST_ROUTE_NAME,
      ROOM_DOCUMENTS_ROUTE_NAME,
      ROOM_ROUTE_NAME,
      ROOM_SIGNED_DOCUMENTS_ROUTE_NAME,
      SIGNING_CHECKLIST_ROUTE_NAME,
    }
  },
  computed: {
    ...mapGetters('rooms', ['hasRecentRooms']),
    ...mapState('projectsFolders', ['projectsFoldersTree']),
    projectCategoryIcon () {
      return getRoomIcon(this.project)
    },
    documentsCount () {
      if (this.project.countsForRoom) {
        if (this.project.countsForRoom.files) {
          return this.project.countsForRoom.files
        } else if (this.project.countsForUser.files) {
          return this.project.countsForUser.files
        } else return 0
      } else if (this.project.countsForUser) {
        return this.project.countsForUser.files ? this.project.countsForUser.files : 0
      }
      return null
    },
    questionsCount () {
      if (this.project.countsForRoom) {
        if (this.project.countsForRoom.questions) {
          return this.project.countsForRoom.questions
        } else if (this.project.countsForUser.questions) {
          return this.project.countsForUser.questions
        } else return 0
      } else if (this.project.countsForUser) {
        return this.project.countsForUser.questions ? this.project.countsForUser.questions : 0
      }
      return null
    },
    originalsCount () {
      if (this.project.countsForUser) {
        if (this.project.countsForUser.originals) {
          return this.project.countsForUser.originals
        } else return 0
      }
      return null
    },
    tasksCount () {
      if (this.project.countsForRoom) {
        if (this.project.countsForRoom.tasksTagged) {
          return this.project.countsForRoom.tasksTagged
        } else if (this.project.countsForUser.tasksTagged) {
          return this.project.countsForUser.tasksTagged
        } else return 0
      } else if (this.project.countsForUser) {
        return this.project.countsForUser.tasksTagged ? this.project.countsForUser.tasksTagged : 0
      }
      return null
    },
    documentsToSignCount () {
      if (this.project.countsForRoom) {
        if (this.project.countsForRoom.signaturesPending) {
          return this.project.countsForRoom.signaturesPending
        } else if (this.project.countsForUser.signaturesPending) {
          return this.project.countsForUser.signaturesPending
        } else return 0
      } else if (this.project.countsForUser) {
        return this.project.countsForUser.signaturesPending ? this.project.countsForUser.signaturesPending : 0
      }
      return null
    },
    computedRoomPathType () {
      return getRoomRouteType(this.project)
    },
    computedTextColorTheme () {
      return getRoomColorTheme(this.project) + '--text'
    },
  },
  methods: {
    displayDate (date) {
      return ISOToShortenedDate(date)
    },
  },
}
</script>

<style scoped lang="scss">
.ProjectsGrid-link {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
  .count-value {
    display: inline-block;
    width: 35px;
    margin-right: 8px;
    text-align: right;
    font-weight: 700;
  }
}
.ProjectsGrid-header {
  // We set the height of this row manually to reserve space
  // and avoid uneven margins when we have a label in it,
  // which increases its natural height
  height: 26px;
}
</style>
