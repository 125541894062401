<template>
  <v-card>
    <v-card-title>
      {{ $t('project.members.MemberProjectUpgrade.title') }}
      <AppButton
        class="ml-auto mr-n5 mt-n6"
        icon
        @click="cancel"
      >
        <font-awesome-icon :icon="['fal', 'xmark']" size="lg" />
      </AppButton>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <p v-html="$t('project.members.MemberProjectUpgrade.currentTypeInfo', {projType: currentProjectType, nbMaxUsers: currentMaxMembers })"></p>
          <p v-if="errorData.nextRoomType.limUsers === 0 && errorData.nextRoomType.limEnvelopes === 0"
             v-html="$t('project.members.MemberProjectUpgrade.upgradeOfferUnlimited', { newProjType: newProjectType, newPrice: newPrice })"
          ></p>
          <p v-else-if="errorData.nextRoomType.limUsers === 0"
             v-html="$t('project.members.MemberProjectUpgrade.upgradeOfferUnlimitedMembers', { newProjType: newProjectType, newPrice: newPrice, nbEnv: envelopeNumber })"
          ></p>
          <p v-else-if="errorData.nextRoomType.limEnvelopes === 0"
             v-html="$t('project.members.MemberProjectUpgrade.upgradeOfferUnlimitedDocuments', { newProjType: newProjectType, newPrice: newPrice, newMaxMembers: newMaxMembers })"
          ></p>
          <p v-else
             v-html="$t('project.members.MemberProjectUpgrade.upgradeOffer', { newProjType: newProjectType, newPrice: newPrice, nbEnv: envelopeNumber, newMaxMembers: newMaxMembers })"
          ></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <AppCard hover
                   class="card-option"
                   :class="{'card-option--selected': selectedOption === options.upgrade}"
                   @click.native="selectedOption = options.upgrade"
          >
            <div class="text-center">
              {{ $t('project.members.MemberProjectUpgrade.options.upgrade', { newProjType: newProjectType }) }}
            </div>
          </AppCard>
        </v-col>
        <v-col>
          <AppCard hover
                   class="card-option"
                   :class="{'card-option--selected': selectedOption === options.noUpgrade}"
                   @click.native="selectedOption = options.noUpgrade"
          >
            <div class="text-center">
              {{ $t('project.members.MemberProjectUpgrade.options.cancel') }}
            </div>
          </AppCard>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <AppButton @click="cancel">
        {{$t('common.cancel')}}
      </AppButton>
      <AppButton color="primary"
                 :disabled="okDisabled"
                 :loading="okLoading"
                 @click="validate"
      >
        {{$t('common.ok')}}
      </AppButton>
    </v-card-actions>
  </v-card>
</template>

<script>
import AppCard from '@/common/AppCard'
import AppButton from '@/common/buttons/AppButton'

export default {
  name: 'MemberProjectUpgrade',
  components: {
    AppButton,
    AppCard,
  },
  props: {
    errorData: {
      type: Object,
      required: true,
    },
    okLoading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      options: {
        upgrade: 1,
        noUpgrade: 2,
      },
      selectedOption: null,
    }
  },
  computed: {
    currentProjectType () {
      return this.$t(`common.roomTypes.name-${this.errorData.currentRoomType.label}`)
    },
    newProjectType () {
      return this.$t(`common.roomTypes.name-${this.errorData.nextRoomType.label}`)
    },
    currentMaxMembers () {
      return this.errorData.currentRoomType.limUsers === 0
        ? this.$t('project.members.MemberProjectUpgrade.unlimited')
        : this.errorData.currentRoomType.limUsers
    },
    newPrice () {
      return this.errorData.nextRoomType.pricePerMonth
    },
    envelopeNumber () {
      return this.errorData.nextRoomType.limEnvelopes === 0
        ? this.$t('project.members.MemberProjectUpgrade.unlimited')
        : this.errorData.nextRoomType.limEnvelopes
    },
    newMaxMembers () {
      return this.errorData.nextRoomType.limUsers === 0
        ? this.$t('project.members.MemberProjectUpgrade.unlimited')
        : this.errorData.nextRoomType.limUsers
    },
    okDisabled () {
      return !this.selectedOption
    },
  },
  methods: {
    validate () {
      this.selectedOption === this.options.noUpgrade
        ? this.$emit('onUpgradeDecline')
        : this.$emit('onUpgradeValidate', { confirmProjectUpgrade: true })
    },
    cancel () {
      this.$emit('onCancel')
    },
  },
}
</script>

<style scoped lang="scss">
.card-option {
  border: 3px solid transparent;
}

.card-option--selected {
  border-color: var(--v-primary-base);
}
</style>
