<template>
  <div>
    <app-text variant="large-bold" class="grey--text text--darken-3">
      {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddDocumentsSelection.title') }}
    </app-text>
    <app-text variant="small-regular" class="grey--text text--darken-2 mb-3">
      {{ $t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddDocumentsSelection.subTitle') }}
    </app-text>

    <AppAlertInPage v-if="!roomProvidersPending" class="mb-6">
      {{ documentsMaxSizeWarning }}
    </AppAlertInPage>

    <SigningChecklistDocumentsSelector class="mb-8" @select="selectDocuments" />

    <div v-if="envelopeTemplateDrafts.length > 0" class="mt-8 mb-4">
      <app-text variant="medium-bold" class="mb-4">
        {{
          $tc(
            "project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddDocumentsSelection.selectedDocuments",
            envelopeTemplateDrafts.length,
            { count: envelopeTemplateDrafts.length },
          )
        }}
      </app-text>

      <SigningChecklistSelectedDocumentsListItem v-for="(templateDraft, index) in envelopeTemplateDrafts"
                                                 :key="`file-${index}`"
                                                 :file="templateDraft.document"
                                                 can-delete
                                                 @deleteFile="removeFileFromSelection(templateDraft.document)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import { typedMapMutations } from '@/common/utils/store'
import AppAlertInPage from '@/design-system/alert/AppAlertInPage.vue'
import SigningChecklistDocumentsSelector from '@/project/signing-checklist/SigningChecklistDocumentsSelector.vue'
import SigningChecklistSelectedDocumentsListItem from '@/project/signing-checklist/SigningChecklistSelectedDocumentsListItem.vue'
import type { EnvelopeTemplatesMutationType } from '@/store/modules/envelope-templates/mutation_types'
import { GET_ROOM_PROVIDERS } from '@/store/modules/room/action_types'
import { PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE } from '@/store/modules/signing-checklist/action_types'
import { ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE } from '@/store/modules/signing-checklist/mutation_types'
import { ENQUEUE_WARNING_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'EnvelopeTemplateAddDocumentsSelection',
  components: {
    AppAlertInPage,
    SigningChecklistDocumentsSelector,
    SigningChecklistSelectedDocumentsListItem,
  },
  computed: {
    ...mapState('envelopeTemplates', ['envelopeTemplateDrafts']),
    ...mapState('room', ['currentRoom', 'roomProvidersPending']),
    ...mapGetters('room', ['isCurrentUserPm', 'maxSignableSize', 'roomMnemo']),
    documentsMaxSizeWarning (): string {
      const allowWordSigning = process.env.VUE_APP_ENABLE_WORD_SIGNING
      const maxSignablePages = process.env.VUE_APP_MAX_SIGNABLE_PAGES

      return this.$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddDocumentsSelection.maxFileSizeWarning.warning', {
        fileExtensions: allowWordSigning
          ? this.$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddDocumentsSelection.maxFileSizeWarning.wordOrPDF')
          : this.$t('project.signing-checklist.envelope-templates.add.EnvelopeTemplateAddDocumentsSelection.maxFileSizeWarning.pdf'),
        maxSignableSize: this.maxSignableSize,
        maxPages: maxSignablePages,
      }).toString()
    },
  },
  methods: {
    ...mapActions('room', [GET_ROOM_PROVIDERS]),
    ...mapActions('signingChecklist', [PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE]),
    ...mapMutations([ENQUEUE_WARNING_SNACKBAR]),
    ...mapMutations('signingChecklist', [ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE]),
    ...typedMapMutations<EnvelopeTemplatesMutationType>(
      'envelopeTemplates',
      [
        'ADD_DOCUMENTS_TO_TEMPLATE_DRAFT',
        'REMOVE_DOCUMENT_FROM_TEMPLATE_DRAFT',
        'SET_TEMPLATE_DOCUMENT_DATA_AFTER_UPLOAD_ERROR',
        'SET_TEMPLATE_DOCUMENT_DATA_AFTER_UPLOAD_SUCCESS',
        'SET_TEMPLATE_DOCUMENT_UPLOAD_PROGRESS',
      ],
    ),
    selectDocuments ({ files, fromClosd }) {
      this.ADD_DOCUMENTS_TO_TEMPLATE_DRAFT(files)

      if (!fromClosd) {
        this.uploadExternalFiles(files)
      }
    },
    removeFileFromSelection (fileToRemove) {
      if (fileToRemove.cancelToken) {
        fileToRemove.cancelToken.cancel()
      }

      this.REMOVE_DOCUMENT_FROM_TEMPLATE_DRAFT(fileToRemove)
    },
    uploadExternalFiles (externalFiles) {
      this.ADD_ENVELOPE_DOCUMENTS_TO_UPLOAD_QUEUE(externalFiles)

      const documentUploadCallbacks = {
        onEachSuccessCallback: (uploadedDocument, queueId) => {
          this.SET_TEMPLATE_DOCUMENT_DATA_AFTER_UPLOAD_SUCCESS({ uploadedDocument, queueId })
        },
        onEachUploadProgressCallback: (queueId, uploadProgression) => {
          this.SET_TEMPLATE_DOCUMENT_UPLOAD_PROGRESS({ queueId, uploadProgression })
        },
        onEachErrorCallback: (queueId) => {
          this.SET_TEMPLATE_DOCUMENT_DATA_AFTER_UPLOAD_ERROR(queueId)
        },
      }
      this.PROCESS_ENVELOPE_DOCUMENTS_UPLOAD_QUEUE(documentUploadCallbacks)
    },
  },
  async created () {
    if (this.isCurrentUserPm && !this.roomProvidersPending && !this.currentRoom.providers) {
      await this.GET_ROOM_PROVIDERS(this.roomMnemo)
    }
  },
})
</script>
