<template>
  <div>
    <!--  -->
    <v-card
      flat
      :color="isGroupPm ? '#e8ebf0' : ''"
      class="pa-4 rounded-lg list-groups-item"
      width="100%"
    >
      <v-row no-gutters class="align-start">
        <!-- NAME OF GROUP -->
        <v-col class="text-h3"
        >
          <template v-if="editGroupName">
            <GroupNameEdit v-if="editGroupName"
                          :group="group"
                          @cancel="editGroupName = false"
            />
          </template>
          <template v-else-if="!isGroupPm && isCurrentUserPm">
            <span class="group-name" @click="editGroupName = true">{{ `${group.name} (${group.members.length})` }}</span>
          </template>
          <template v-else>
            <span class="group-name-without-hover">{{ `${group.name} (${group.members.length})` }}</span>
          </template>
        </v-col>
        <v-col v-if="!isGroupPm && isCurrentUserPm" cols="auto" class="d-flex align-center" style="margin-top: -10px;">
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <AppButton icon
                         v-bind="attrs"
                         v-on="on"
                         @click="editGroupName = true"
              >
                <font-awesome-icon :icon="['fas', 'edit']" class="text--primary"></font-awesome-icon>
              </AppButton>
            </template>
            <span>{{$t('project.members.Group.tooltipRename')}}</span>
          </AppTooltip>
          <DeleteGroup :group-to-delete="group"/>
        </v-col>
      </v-row>
      <v-row v-if="hideMembers"
            no-gutters
            class="mt-0"
      >
        <v-col>
          <AppTooltip top>
            <template #activator="{attrs, on}">
              <v-chip text-color="error"
                      label
                      small
                      class="text-h5 text-uppercase confidentialGroupChip"
                      v-bind="attrs"
                      v-on="on"
              >
                {{$t('project.members.Group.confidentialGroup')}}
                <font-awesome-icon :icon="['far','lock-alt']" class="ml-2"></font-awesome-icon>
              </v-chip>
            </template>
            <span v-html="$t('project.members.Group.hiddenParticipantsInfo')"/>
          </AppTooltip>
        </v-col>
      </v-row>
      <v-row no-gutters style="margin-top: 10px;">
        <v-col>
          <draggable :list="group.members"
                    group="members"
                    :disabled="!isCurrentUserPm"
                    :forceFallback="true"
                    :emptyInsertThreshold="100"
                    :options="{delay: 500, delayOnTouchOnly: true}"
                    handle=".Member"
                    filter=".ignore-draggable-elements"
                    style="max-height: 350px; overflow-y: auto;"
                    @change="moveUserGroup(group.id, $event)"
          >
            <transition-group>
              <Member v-for="member in group.members"
                      :key="member.id"
                      :group="group"
                      :member="member"
                      :mnemo="mnemo"
              />
            </transition-group>
          </draggable>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <SeeAllMembers :group="group" />
        </v-col>
      </v-row>
      <v-row v-if="!isGroupPm && isCurrentUserPm" no-gutters style="margin-top: 10px;">
        <v-col>
          <v-switch :input-value="hideMembers"
                    class="mt-0 pt-0"
                    hide-details
                    :loading="patchGroupLoading"
                    :disabled="patchGroupLoading"
                    readonly
                    @click="hideMembersHandler"
          >
            <template #label>
              <span class="subtitle-1">
                {{ $t('project.members.Group.hideGroupMembers') }}
                <AppHelpTooltip :text="$t('project.members.Group.hiddenParticipantsInfo')"/>
              </span>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <v-row v-if="isCurrentUserPm" no-gutters class="mt-3">
        <v-col>
          <AppButton block
                     :color="isGroupPm ? '#d3dbe8' : 'white'"
                     :class="isGroupPm ? 'tertiary--text' : ''"
                     @click="addMemberDialogIsOpen = true"
          >
            <font-awesome-icon :icon="['fas', 'plus-circle']" class="mr-2"></font-awesome-icon>
            {{ $t('project.members.Group.addMember') }}
          </AppButton>
        </v-col>
      </v-row>
    </v-card>
    <AppDialog :is-open="confirmDialog"
               size="l"
               @ok="prepareConfirmUnmaskGroup"
               @cancel="confirmDialog = !confirmDialog"
    >
      <template #title>{{ $t('project.members.Group.confirmDialog.title') }}</template>
      <template #body>{{ $t('project.members.Group.confirmDialog.body') }}</template>
    </AppDialog>
    <GroupAddMemberDialog v-if="addMemberDialogIsOpen"
                          :group="group"
                          @onClose="addMemberDialogIsOpen = false"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'

import AppHelpTooltip from '@/common/AppHelpTooltip'
import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'

import GroupAddMemberDialog from './dialogs/GroupAddMemberDialog'
import DeleteGroup from './dialogs/GroupDeleteDialog'
import SeeAllMembers from './dialogs/MembersSeeAllDialog'
import GroupNameEdit from './GroupNameEdit'
import Member from './Member'
import AppDialog from '../../common/dialogs/AppDialog'

export default {
  name: 'Group',
  components: {
    AppDialog,
    GroupNameEdit,
    AppButton,
    GroupAddMemberDialog,
    SeeAllMembers,
    DeleteGroup,
    AppHelpTooltip,
    Member,
    draggable,
    AppTooltip,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      addMemberDialogIsOpen: false,
      confirmDialog: false,
      dragging: false,
      dragUser: {
        toGroup: null,
        userId: null,
      },
      editGroupName: false,
      editingName: false,
      hideMembers: false,
      patchGroupLoading: false,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm']),
    isGroupPm () {
      return this.group.isPm
    },
    users: {
      get: function () {
        return this.$store.state.users
      },
      set: function (newValue) {
        this.$store.commit('setUsers', newValue)
      },
    },
  },
  created () {
    this.hideMembers = this.group.hideMembers
  },
  methods: {
    ...mapActions('groups', ['PATCH_GROUP', 'PATCH_USER_ACCESS', 'GET_GROUPS']),
    deleteGroup (group) {
      this.$store.commit('setDialogDelete', {
        active: true,
        action: () => {
          this.$store.commit(
            'setUsers',
            this.users.filter((item) => item.id !== group.id),
          )
        },
      })
    },
    editName () {
      this.$store.commit('enqueueSnackbar', {
        color: 'success',
        timeout: 3000,
        message: this.$t('project.members.Group.savedModification'),
      })
    },
    prepareConfirmUnmaskGroup () {
      this.patchGroupLoading = true
      this.PATCH_GROUP({ groupId: this.group.id, data: { hideMembers: false } })
        .then(() => {
          this.$store.commit('enqueueSnackbar', {
            color: 'success',
            message: this.$t('project.members.Group.messages.notHideMembers'),
          })
          this.hideMembers = false
          this.patchGroupLoading = false
        })
        .catch(() => {
          this.patchGroupLoading = false
        })
        .finally(() => {
          this.confirmDialog = false
        })
    },
    hideMembersHandler () {
      if (!this.patchGroupLoading) {
        if (this.hideMembers) {
          this.confirmDialog = true
        } else {
          this.patchGroupLoading = true
          this.PATCH_GROUP({ groupId: this.group.id, data: { hideMembers: true } })
            .then(() => {
              this.$store.commit('enqueueSnackbar', {
                color: 'success',
                message: this.$t('project.members.Group.messages.hideMembers'),
              })
              this.hideMembers = true
              this.patchGroupLoading = false
            })
            .catch(() => {
              this.patchGroupLoading = false
            })
        }
      }
    },
    moveUserGroup (group, e) {
      if (Object.prototype.hasOwnProperty.call(e, 'added')) {
        this.dragUser.toGroup = group
        if (!this.dragUser.userId) {
          this.dragUser.userId = e.added.element.id
        }
      }
      if (this.dragUser.toGroup && this.dragUser.userId) {
        this.PATCH_USER_ACCESS({ memberId: this.dragUser.userId, data: { newGroupId: this.dragUser.toGroup } })
          .then(() => {
            this.$store.commit('enqueueSnackbar', {
              color: 'success',
              message: this.$t('project.members.Group.messages.successChangeGroup'),
            })
            this.dragUser.toGroup = null
            this.dragUser.userId = null
          })
          .catch(() => {
            this.$store.commit('enqueueSnackbar', {
              color: 'error',
              message: this.$t('project.members.Group.messages.errorChangeGroup'),
            })
          })
          .finally(() => {
            this.GET_GROUPS(this.mnemo)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped >
/* .members * {
  border: solid 1px red;
} */

.group-name {
  cursor: pointer !important;
  font-weight: 600 !important;
  border: 1px dashed transparent;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 24px;
  min-width: 100px;
  &:hover {
    border-color: #bdbddb;
    background-color: #eee;
  }
}
.group-name-without-hover{
  font-weight: 600 !important;
}

/* transition list */
.list-groups-item {
  transition: all 0.5s;
}
.list-groups-enter,
.list-groups-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}
.list-groups-leave-active {
  position: absolute;
  z-index: -1;
}

/* transition list */
.list-members-item {
  transition: all 0.5s;
}
.list-members-enter,
.list-members-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.list-members-leave-active {
  position: absolute;
  z-index: -1;
}

.confidentialGroupChip::v-deep.v-chip {
  background-color: var(--v-brand-lighten4);
  &:hover::before {
    opacity: 0;
  }
}
</style>
