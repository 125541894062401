import {
  SET_SIGNING_VIEW_PENDING,
  SET_SIGNING_VIEW,
  SET_SIGNING_VIEW_ERROR,
} from './mutation_types'

export const mutations = {
  [SET_SIGNING_VIEW_PENDING] (state, value) {
    state.signingViewPending = value
  },

  [SET_SIGNING_VIEW] (state, value) {
    state.signingView = value
  },
  [SET_SIGNING_VIEW_ERROR] (state, value) {
    state.signingViewError = value
  },
}
