<template>
  <v-row v-show="isDisplayed" class="SigningChecklistTableItem my-2 text--primary position-relative">
    <!-- DESKTOP -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <template v-if="isRefreshing">
        <v-col cols="5">
          <div>
            <v-skeleton-loader type="text"/>
          </div>
          <div>
            <v-skeleton-loader type="actions"/>
          </div>
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="text@5"/>
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="text@5"/>
        </v-col>
        <v-col cols="2">
          <v-skeleton-loader type="button"/>
        </v-col>
        <v-col class="text-right" cols="1">
          <v-skeleton-loader type="avatar"/>
        </v-col>
      </template>
      <template v-else>
        <v-col :cols="hasSigningOrder ? 4 : 5">
          <v-row align="center"
                 no-gutters
          >
            <v-col v-if="isPm" cols="auto">
              <v-checkbox :input-value="isStepSelected"
                          hide-details
                          readonly
                          class="mt-0 pt-0 mr-2"
                          :ripple="false"
                          @click.stop="onSelectedStepClick"
              />
            </v-col>
            <v-col v-if="isPm" cols="auto">
              <font-awesome-icon :icon="['fas', 'grip-vertical']"
                                 class="mr-5"
                                 style="cursor: grab; color: var(--v-greyDark-lighten1)"
                                 :class="`SigningChecklistTable-dragIcon`"
              ></font-awesome-icon>
            </v-col>
            <v-col class="item-name">
              <div class="d-flex align-center column-gap-2 mb-3">
                <img
                  class="ClosdFildersIcon"
                  style="width: 20px"
                  :src="`/img/icons/pdf.svg`"
                  alt="File icon"
                />
                <span>
                  {{ computedTitle }}
                </span>
                <template v-if="item.canWrite && (item.envelope.status === 'draft' || item.envelope.status === 'temp')">
                  <SigningChecklistTableItemStatusTooltip :item="item" />
                </template>
              </div>
              <div class="d-flex align-center justify-space-between">
                <SigningChecklistTableItemSignatureChips :item="item" />
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <template v-if="headerSelectedColumn.value === 'signatories'">
            <SigningChecklistMultiEnvelopeSignatoriesList v-if="isMultiEnvelope" :signatories="item.envelope.signatories" />
            <div v-else>
              <div v-for="(signersGroup, groupIndex) in item.envelope.signatories"
                  :key="`envelope-${item.envelope.id}-signer-group-${groupIndex}`"
              >
                <div v-for="signer in signersGroup" :key="`envelope-${item.envelope.id}-signer-${signer.id}`">
                    <div class="d-flex align-center">
                    <template v-if="signer.status === 'sent'">
                      <app-icon icon-name="clock" icon-weight="far" size="small" class="d-inline-flex mr-1 tertiary--text"/>
                    </template>
                    <template v-else-if="signer.status === 'signed'">
                      <app-icon icon-name="circle-check" icon-weight="fas" size="small" class="d-inline-flex mr-1 success--text"/>
                    </template>
                    <template v-else>
                      <app-icon icon-name="clock" icon-weight="far" size="small" class="d-inline-flex mr-1 grey--text text--lighten-1"/>
                    </template>
                    <span v-if="item.envelope.signatories.length > 1" class="mr-1">
                      {{ `${groupIndex + 1} -`  }}
                    </span>
                    {{ signer.fullName }}
                    <template v-if="signer.id === profile.id && item.envelope.status === 'sent' && !signer.signed">
                      <PdfSigningDialogButton v-if="item.envelope.provider === 'pdf'"
                                              :step="item"
                                              class="d-inline-block ml-1"
                                              :signer-id="signer.id"
                      />
                      <DsAppButton
                        v-else-if="signer.status === 'sent'"
                        size="small"
                        type="tonal"
                        variant="brand"
                        class="ml-1"
                        @click="$router.push({
                          name: SIGNING_VIEW_ROUTE_NAME,
                          params: {
                            envelopeId: item.envelope.id,
                          },
                        })"
                      >
                        {{ $t('project.signing-checklist.list.SigningChecklistTableItem.signButtonLabel') }}
                      </DsAppButton>
                    </template>
                  </div>
                  <template v-if="hasSignerWitness(signer.id)">
                    <div v-for="witness in getSignerWitness(signer.id)" :key="witness.id" class="ml-6 d-flex">
                      <AppTooltip top>
                        <template #activator="{on, attrs}">
                          <div v-bind="attrs" v-on="on">
                              <app-icon v-if="witness.isSigned" icon-name="circle-check" icon-weight="fas" size="small" class="d-inline-flex mr-1 success--text"/>
                              <app-icon v-else-if="signer.status === 'sent' || signer.status === 'signed'" icon-name="clock" icon-weight="far" size="small" class="d-inline-flex mr-1 tertiary--text"/>
                              <app-icon v-else icon-name="clock" icon-weight="far" size="small" class="d-inline-flex mr-1 grey--text text--lighten-1"/>
                              {{ witness.signer.status }}{{ `${witness.signer.fullName}` }}
                          </div>
                        </template>
                        <span>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.witnessOf', {name: signer.fullName}) }}</span>
                      </AppTooltip>
                      <AppTooltip v-if="signer.status === 'signed' && !witness.isSigned && witness.signer.id === profile.id"  top>
                        <template #activator="{on, attrs}">
                            <DsAppButton
                              v-bind="attrs"
                              size="small"
                              type="tonal"
                              variant="brand"
                              class="ml-1"
                              @click="handleSignAsWitness(item.envelope.id)"
                              v-on="on"
                            >
                              {{ $t('project.signing-checklist.list.SigningChecklistTableItem.signButtonLabel') }}
                            </DsAppButton>
                        </template>
                        <span>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.signAsWitness')}}</span>
                      </AppTooltip>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-if="headerSelectedColumn.value === 'recipients'">
            <div v-for="recipient in item.envelope.recipients"
                 :key="`envelope-${item.envelope.id}-recipient-${recipient.id}`"
            >
              - {{ recipient.fullName }}
            </div>
          </template>
          <template v-if="headerSelectedColumn.value === 'observers'">
            <div v-for="observer in item.envelope.observers"
                 :key="`envelope-${item.envelope.id}-observer-${observer.id}`"
            >
              - {{ observer.fullName }}
            </div>
          </template>
        </v-col>
        <v-col cols="2">
          <div v-for="validation in item.envelope.validations"
               :key="`envelope-${item.envelope.id}-validator-${validation.id}`"
          >
            <template v-if="!validation.validated">
              <font-awesome-icon :icon="['far', 'clock']"
                                 class="tertiary--text"
              ></font-awesome-icon>
              {{ validation.validator.fullName }}
            </template>
            <template v-if="validation.validated">
              <div class="d-flex align-center flex-wrap">
                <AppTooltip top>
                  <template #activator="{attrs, on}">
                    <div v-bind="attrs" class="mr-4" v-on="on">
                      <font-awesome-icon :icon="['fas', 'circle-check']"
                                         class="success--text"
                      ></font-awesome-icon>
                      {{ validation.validator.fullName }}
                    </div>
                  </template>
                  <span>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.validatedTooltip', { date: getValidationDate(validation.validatedAt) }) }}</span>
                </AppTooltip>

                <div v-if="validation.attachment" class="attachment-file-actions">
                  <AppDownloadFile :link="validation.attachment.url"
                  >
                    <template #default="{ submitForm }">
                      <AppTooltip top>
                        <template #activator="{attrs, on}">
                          <div v-bind="attrs" v-on="on">
                            <AppButton small
                                              icon
                                              @click="submitForm"
                            >
                              <font-awesome-icon :icon="['fal', 'file-download']"
                                                 class="text--primary"
                                                 style="font-size: 16px;"
                              />
                            </AppButton>
                          </div>
                        </template>
                        <span>{{ $t('common.download') }}</span>
                      </AppTooltip>
                    </template>
                  </AppDownloadFile>

                  <AppTooltip top>
                    <template #activator="{attrs, on}">
                      <div v-bind="attrs" v-on="on">
                        <AppButton v-if="isPm"
                                          small
                                          icon
                                          @click="() => openShareAttachmentFilePane(validation.attachment)"
                        >
                          <font-awesome-icon :icon="['fal', 'share']" style="font-size: 14px;"/>
                        </AppButton>
                      </div>
                    </template>
                    <span>{{ $t('common.share') }}</span>
                  </AppTooltip>
                </div>
              </div>
            </template>
            <div v-if="validation.canValidate && !validation.validated && item.envelope.status !== 'voided'">
              <SigningChecklistTableItemValidateDialog :mnemo="mnemo"
                                                       :envelopeId="item.envelope.id"
                                                       :validationId="validation.id"
                                                       :stepId="item.id"
                                                       :parent="parent"
              />
            </div>
            <div
              v-if="validation.canValidate && validation.validated && item.envelope.status !== 'voided' && item.envelope.status !== 'signed'">
              <SigningChecklistTableItemInvalidateDialog :mnemo="mnemo"
                                                         :envelopeId="item.envelope.id"
                                                         :validationId="validation.id"
                                                         :stepId="item.id"
                                                         :parent="parent"
              />
            </div>
          </div>
        </v-col>
        <v-col class="SigningChecklistTableItem-status d-flex align-center"
               :class="`SigningChecklistTableItem-status--${item.envelope.status}`"
               cols="2"
        >
          <div>
            <v-chip :color="envelopeStatusColor"
                    label
                    class="mb-2"
            >
              {{ $t(`common.envelopeStatus.${item.envelope.status}`) }}
            </v-chip>
            <div v-if="envelopeStatusDate">
              {{ envelopeStatusDate }}
            </div>
          </div>
        </v-col>
        <v-col v-if="hasSigningOrder" cols="1">
          <b v-if="item.signingGroup">#{{ item.signingGroup }}</b>
        </v-col>
        <v-col class="text-right d-flex justify-end align-center" cols="1">
          <AppDownloadFile :link="envelopeResourceLink">
            <template #default="{submitForm}">
              <AppTooltip top>
                <template #activator="{on, attrs}">
                  <DsAppButton type="icon"
                               class="ml-2"
                               v-bind="attrs"
                               v-on="on"
                               @click="submitForm"
                  >
                    <app-icon icon-name="download" icon-weight="fas" size="medium" class="accent--text text--lighten-2"/>
                  </DsAppButton>
              </template>
              <span>{{$t('common.download')}}</span>
            </AppTooltip>
          </template>
          </AppDownloadFile>
          <AppDownloadFile :link="envelopeResourceLink" forceReader>
            <template #default="{submitForm}">
              <AppTooltip top>
                <template #activator="{on, attrs}">
                  <DsAppButton type="icon"
                               v-bind="attrs"
                               v-on="on"
                               @click="submitForm"
                  >
                    <app-icon icon-name="eye" icon-weight="fas" class="accent--text text--lighten-2"/>
                  </DsAppButton>
                </template>
                <span>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.previewTooltip') }}</span>
              </AppTooltip>
            </template>
          </AppDownloadFile>
          <div v-if="item.canWrite && envelopeMenu.length !== 0 && isPm">
             <AppTooltip top>
              <template #activator="{on, attrs}">
                <div v-bind="attrs" v-on="on">
                  <AppMenu :menu="envelopeMenu" v-bind="attrs" v-on="on">
                    <template #activator="{on, attrs}">
                      <DsAppButton type="icon"
                                   v-bind="attrs"
                                   v-on="on"
                      >
                        <app-icon icon-name="ellipsis-vertical" class="body-1 text--primary"/>
                      </DsAppButton>
                    </template>
                  </AppMenu>
                </div>
              </template>
              <span>{{ $t('common.additionalActions') }}</span>
            </AppTooltip>
          </div>
        </v-col>
      </template>
    </template>
    <!-- MOBILE -->
    <template v-if="$vuetify.breakpoint.smAndDown">
      <template v-if="isRefreshing">
        <v-skeleton-loader type="card" width="100%"/>
      </template>
      <template v-else>
        <div class="SigningChecklistTableItem-mobileRow d-flex justify-space-between align-center w-100 pt-1">
          <SigningChecklistTableItemSignatureChips :item="item" />
          <div class="d-flex flex-nowrap">
            <AppDownloadFile :link="envelopeResourceLink">
              <template #default="{submitForm}">
                <AppButton icon
                           class="ml-2"
                           @click="submitForm"
                >
                  <font-awesome-icon :icon="['fas', 'download']"
                                     class="accent--text text--lighten-2"
                  ></font-awesome-icon>
                </AppButton>
              </template>
            </AppDownloadFile>
            <AppMenu v-if="item.canWrite && envelopeMenu.length !== 0 && isPm" :menu="envelopeMenu">
              <template #activator="{on, attrs}">
                <AppButton icon
                           v-bind="attrs"
                           v-on="on"
                >
                  <font-awesome-icon :icon="['far', 'ellipsis-vertical']"
                                     class="body-1 text--primary"
                  ></font-awesome-icon>
                </AppButton>
              </template>
            </AppMenu>
          </div>
        </div>
        <div class="SigningChecklistTableItem-mobileRow-separator"></div>
        <div class="SigningChecklistTableItem-mobileRowGrid">
          <div>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.file') }}</div>
          <div class="font-weight-medium">
            {{ computedTitle }}
            <template v-if="item.canWrite && (item.envelope.status === 'draft' || item.envelope.status === 'temp')">
              <SigningChecklistTableItemStatusTooltip :item="item" />
            </template>
          </div>
        </div>
        <div class="SigningChecklistTableItem-mobileRow-separator"></div>
        <div class="SigningChecklistTableItem-mobileRowGrid">
          <div>
            <template v-if="isPm">
              <AppSelect v-model="headerSelectedColumnModel"
                         class="SigningChecklistTableItem-select"
                         no-label
                         return-object
                         style="width: 100px;"
                         :items="headerSelectItems"
              />
            </template>
            <template v-else>
              {{ headerSelectedColumnModel.text }}
            </template>
          </div>
          <div>
            <template v-if="headerSelectedColumn.value === 'signatories'">
            <SigningChecklistMultiEnvelopeSignatoriesList v-if="isMultiEnvelope" :signatories="item.envelope.signatories" />
            <div v-else>
              <div v-for="(signersGroup, groupIndex) in item.envelope.signatories"
                   :key="`envelope-mobile-${item.envelope.id}-signer-group-${groupIndex}`"
              >
                <div v-for="signer in signersGroup"
                     :key="`envelope-mobile-${item.envelope.id}-signer-${signer.id}`"
                >
                  <template v-if="signer.status === 'sent'">
                    <font-awesome-icon :icon="['far', 'clock']"
                                       class="tertiary--text"
                    ></font-awesome-icon>
                  </template>
                  <template v-if="signer.status === 'signed'">
                    <font-awesome-icon :icon="['fas', 'circle-check']"
                                       class="success--text"
                    ></font-awesome-icon>
                  </template>
                  <span v-if="item.envelope.signInOrder">
                  {{ signer.signingOrder }}
                </span>
                  {{ `- ${signer.fullName}` }}
                  <template v-if="signer.id === profile.id && item.envelope.status === 'sent' && !signer.signed">
                    <PdfSigningDialogButton v-if="item.envelope.provider === 'pdf'"
                                            :signer-id="signer.id"
                                            :step="item"
                                            class="d-inline-block"
                    />
                    <DsAppButton v-else-if="signer.status === 'sent'"
                                 size="small"
                                 type="tonal"
                                 variant="brand"
                                 @click="$router.push({
                                 name: SIGNING_VIEW_ROUTE_NAME,
                                 params: {
                                   envelopeId: item.envelope.id,
                                 },
                               })"
                    >
                      {{ $t('project.signing-checklist.list.SigningChecklistTableItem.signButtonLabel') }}
                    </DsAppButton>
                  </template>
                </div>
              </div>
            </div>
            </template>
            <template v-if="headerSelectedColumn.value === 'recipients'">
              <div v-for="recipient in item.envelope.recipients"
                   :key="`envelope-mobile-${item.envelope.id}-recipient-${recipient.id}`"
              >
                - {{ recipient.fullName }}
              </div>
            </template>
            <template v-if="headerSelectedColumn.value === 'observers'">
              <div v-for="observer in item.envelope.observers"
                   :key="`envelope-mobile-${item.envelope.id}-observer-${observer.id}`"
              >
                - {{ observer.fullName }}
              </div>
            </template>
          </div>
        </div>
        <div class="SigningChecklistTableItem-mobileRow-separator"></div>
        <div class="SigningChecklistTableItem-mobileRowGrid">
          <div>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.validators') }}</div>
          <div>
            <div v-for="validation in item.envelope.validations"
                 :key="`envelope-${item.envelope.id}-validator-${validation.id}`"
            >
              <template v-if="!validation.validated">
                <font-awesome-icon :icon="['far', 'clock']"
                                   class="tertiary--text"
                ></font-awesome-icon>
                {{ validation.validator.fullName }}
              </template>

              <template v-if="validation.validated">
                <font-awesome-icon :icon="['fas', 'circle-check']"
                                   class="success--text"
                ></font-awesome-icon>

                {{ validation.validator.fullName }}

                <template v-if="validation.attachment">
                  <AppDownloadFile :link="validation.attachment.url">
                    <template #default="{ submitForm }">
                      <AppButton :x-padding="0"
                                 class="ml-2"
                                 small
                                 icon
                                 @click="submitForm"
                      >
                        <font-awesome-icon :icon="['fal', 'file-download']"
                                           class="text--primary"
                                           style="font-size: 16px;"
                        />
                      </AppButton>
                    </template>
                  </AppDownloadFile>
                  <AppButton v-if="isPm"
                             :x-padding="0"
                             small
                             icon
                             @click="() => openShareAttachmentFilePane(validation.attachment)"
                  >
                    <font-awesome-icon :icon="['fal', 'share']" style="font-size: 14px;"/>
                  </AppButton>
                </template>
              </template>

              <div v-if="validation.canValidate && !validation.validated && item.envelope.status !== 'voided'">
                <SigningChecklistTableItemValidateDialog :mnemo="mnemo"
                                                         :envelopeId="item.envelope.id"
                                                         :validationId="validation.id"
                                                         :stepId="item.id"
                                                         :parent="parent"
                />
              </div>
              <div
                v-if="validation.canValidate && validation.validated && item.envelope.status !== 'voided' && item.envelope.status !== 'signed'">
                <SigningChecklistTableItemInvalidateDialog :mnemo="mnemo"
                                                           :envelopeId="item.envelope.id"
                                                           :validationId="validation.id"
                                                           :stepId="item.id"
                                                           :parent="parent"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="SigningChecklistTableItem-mobileRow-separator"></div>
        <div class="SigningChecklistTableItem-mobileRowGrid">
          <div>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.status') }}</div>
          <div>
            <v-chip :color="envelopeStatusColor"
                    label
                    class="mb-2"
            >
              {{ $t(`common.envelopeStatus.${item.envelope.status}`) }}
            </v-chip>
            <div v-if="envelopeStatusDate">
              {{ envelopeStatusDate }}
            </div>
          </div>
        </div>
        <div v-if="hasSigningOrder" class="SigningChecklistTableItem-mobileRow-separator"></div>
        <div v-if="hasSigningOrder" class="SigningChecklistTableItem-mobileRowGrid pb-2">
          <div>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.signingOrder') }}</div>
          <div><b v-if="item.signingGroup">#{{ item.signingGroup }}</b></div>
        </div>
      </template>
    </template>
    <div v-show="floatingAdd && isPm">
      <AppMenu :menu="floatingMenu">
        <template #activator="{ on, attrs }">
          <!-- We use a bare v-tooltip here because AppTooltip has trouble when combined with another activator -->
          <v-tooltip top transition="fade-transition">
            <template #activator="{on: onTooltip}">
              <font-awesome-icon
                v-bind="{...attrs}"
                :icon="['fas', 'map-marker-plus']"
                class="fa-rotate-270 marker"
                v-on="{...onTooltip, ...on}"
              ></font-awesome-icon>
            </template>
            <span>{{ $t('project.signing-checklist.list.SigningChecklistTableItem.floatingAddTooltip') }}</span>
          </v-tooltip>
        </template>
      </AppMenu>
    </div>

    <QualifiedDSSignatureWarningDialog v-if="qualifiedDSWarningDialog"
                                       @close="qualifiedDSWarningDialog = false"
                                       @confirm="confirmQualifiedSignatureWarning"
    />
  </v-row>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile'
import AppMenu from '@/common/AppMenu.vue'
import AppSelect from '@/common/AppSelect'
import AppTooltip from '@/common/AppTooltip'
import AppButton from '@/common/buttons/AppButton'
import { ISOToShortDate, ISOToShortenedDate } from '@/common/utils/dates'
import {
  canShowSendStep,
  canShowCancelStep,
  canShowDeleteStep,
  canShowResetToDraftStep,
  canShowValidateStep,
  canShowSignTagsStep,
  canShowReadOnlyStep,
  canSwapFile,
} from '@/common/utils/signingChecklist'
import DsAppButton from '@/design-system/buttons/AppButton.vue'
import { SIGNING_CHECKLIST_ADD_ROUTE_NAME, SIGNING_VIEW_ROUTE_NAME } from '@/router'
import {
  REFRESH_STEP,
  VALIDATE_THEN_SEND_NOW,
  CREATE_TITLE,
  REFRESH_SIGNING_CHECKLIST,
  OPEN_SHARE_ATTACHMENT_FILE_PANE,
} from '@/store/modules/signing-checklist/action_types'
import {
  SET_CURRENT_TITLE_EDIT,
  REMOVE_REFRESH_STEP_PENDING,
  SET_CANCEL_STEP_MODAL,
  SET_DELETE_STEP_MODAL,
  SET_SWAP_FILES,
  ADD_REFRESH_STEP_PENDING,
  TOGGLE_SELECTED_STEP,
  SET_SEND_SIGN_STEP_MODAL,
  SET_VOIDED_TO_DRAFT_STEP_MODAL,
} from '@/store/modules/signing-checklist/mutation_types'

import SigningChecklistMultiEnvelopeSignatoriesList from './SigningChecklistMultiEnvelopeSignatoriesList'
import SigningChecklistTableItemInvalidateDialog from './SigningChecklistTableItemInvalidateDialog'
import SigningChecklistTableItemSignatureChips from './SigningChecklistTableItemSignatureChips'
import SigningChecklistTableItemStatusTooltip from './SigningChecklistTableItemStatusTooltip'
import SigningChecklistTableItemValidateDialog from './SigningChecklistTableItemValidateDialog'
import QualifiedDSSignatureWarningDialog from '../dialogs/QualifiedDSSignatureWarningDialog'
import PdfSigningDialogButton from '../pdf-signing/PdfSigningDialogButton'

const {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} = createNamespacedHelpers('signingChecklist')
const { mapState: mapUserState, mapGetters: mapUserGetters } = createNamespacedHelpers('user')
export default {
  name: 'SigningChecklistTableItem',
  components: {
    AppButton,
    DsAppButton,
    AppDownloadFile,
    AppMenu,
    AppSelect,
    AppTooltip,
    PdfSigningDialogButton,
    QualifiedDSSignatureWarningDialog,
    SigningChecklistMultiEnvelopeSignatoriesList,
    SigningChecklistTableItemInvalidateDialog,
    SigningChecklistTableItemSignatureChips,
    SigningChecklistTableItemStatusTooltip,
    SigningChecklistTableItemValidateDialog,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    mnemo: {
      type: String,
      required: true,
    },
    headerSelectedColumn: {
      type: Object,
      required: true,
    },
    headerSelectItems: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    floatingAdd: {
      type: Boolean,
      required: true,
    },
    hasSigningOrder: {
      type: Boolean,
      default: false,
    },
    isMatchingFilters: {
      type: Boolean,
      required: true,
    },
  },
  data () {
    return {
      pdfFile: null,
      SIGNING_VIEW_ROUTE_NAME,
      qualifiedDSWarningDialog: false,
    }
  },
  computed: {
    ...mapUserState(['profile']),
    ...mapUserGetters(['isSubPayingUser']),
    ...mapState(['refreshStepPendingIds', 'selectedSteps', 'createTitlePending', 'qualifiedDSWarningIsMuted']),
    ...mapGetters(['isSigningChecklistFiltered']),
    isPm () {
      return this.$store.getters['room/isCurrentUserPm']
    },
    headerSelectedColumnModel: {
      get () {
        return this.headerSelectedColumn
      },
      set (value) {
        this.$emit('update:headerSelectedColumn', value)
      },
    },
    envelopeResourceLink () {
      return `${process.env.VUE_APP_API_URL}/room/${this.mnemo}/downloads/envelope/${this.item.envelope.id}`
    },
    envelopeStatusColor () {
      switch (this.item.envelope.status) {
        case 'draft':
        case 'temp':
          return '#e0e0e0'
        case 'queued':
        case 'sent':
        case 'unvalidated':
          return 'warning'
        case 'signed':
          return 'success'
        case 'voided':
          return 'error'
      }
      return null
    },
    envelopeStatusDate () {
      if (this.item.envelope.timestamp) {
        return `${this.$t('project.signing-checklist.list.SigningChecklistTableItem.envelopeStatusDate')} ${ISOToShortDate(this.item.envelope.timestamp)}`
      } else if (this.item.date) {
        return `${this.$t('project.signing-checklist.list.SigningChecklistTableItem.envelopeDueDate')} ${ISOToShortenedDate(this.item.date)}`
      }
      return null
    },
    envelopeMenu () {
      const menu = []
      if (canShowDeleteStep(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.delete'),
          action: () => this.SET_DELETE_STEP_MODAL([this.item.id]),
        })
      }

      if (this.isSubPayingUser) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.saveAsTemplate'),
          action: () => { this.$emit('save-as-template', this.item.envelope) },
        })
      }

      if (canSwapFile(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.swapFile'),
          hasNewLabel: true,
          action: () => {
            this.SET_SWAP_FILES({
              ids: [this.item.id],
              steps: [this.item],
            })
          },
        })
      }

      if (this.item.status !== 'sending') {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.settings'),
          action: () => {
            this.$router.push({
              name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
              params: {
                steps: [this.item],
              },
              query: {
                step: 2,
                stepIds: this.item.id,
              },
            })
          },
        })
      }
      if (canShowSignTagsStep(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.signatureTags'),
          action: () => {
            this.$router.push({
              name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
              params: {
                steps: [this.item],
              },
              query: {
                step: 3,
                stepIds: this.item.id,
              },
            })
          },
        })
      }
      if (canShowReadOnlyStep(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.readOnly'),
          action: () => {
            this.$router.push({
              name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
              params: {
                steps: [this.item],
              },
              query: {
                step: 3,
                stepIds: this.item.id,
              },
            })
          },
        })
      }
      if (canShowValidateStep(this.item) && !this.item.envelope.missingToSend) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.validateThenSend'),
          action: async () => {
            const shouldWarnAboutQualifiedSignature = this.item.envelope.signatureType === 'qualified' && !this.qualifiedDSWarningIsMuted
            if (shouldWarnAboutQualifiedSignature) {
              this.qualifiedDSWarningDialog = true
            } else {
              this.openSendSignDialog()
            }
          },
        })
      }
      if (canShowSendStep(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.sendToSign'),
          action: () => {
            const shouldWarnAboutQualifiedSignature = this.item.envelope.signatureType === 'qualified' && !this.qualifiedDSWarningIsMuted
            if (shouldWarnAboutQualifiedSignature) {
              this.qualifiedDSWarningDialog = true
            } else {
              this.openSendSignDialog()
            }
          },
        })
      }

      if (canShowCancelStep(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.cancel'),
          action: () => {
            this.SET_CANCEL_STEP_MODAL({ ids: [this.item.id] })
          },
        })
      }

      if (canShowResetToDraftStep(this.item)) {
        menu.push({
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.menu.voidedToDraft'),
          action: () => {
            this.SET_VOIDED_TO_DRAFT_STEP_MODAL({
              steps: [this.item],
              parent: this.parent,
              ids: [this.item.envelope.id],
            })
          },
        })
      }

      return menu
    },
    isMultiEnvelope () {
      return this.item.envelope.envelopeType === 'multiple'
    },
    isRefreshing () {
      return this.refreshStepPendingIds.includes(this.item.id)
    },
    isStepSelected () {
      return this.selectedSteps.some(item => item.id === this.item.id && item.type === this.item.type)
    },
    computedTitle () {
      return `${this.item.display} ${this.item.title || ''}`
    },
    floatingMenu () {
      return [
        {
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.addStep'),
          action: () => {
            this.$router.push({
              name: SIGNING_CHECKLIST_ADD_ROUTE_NAME,
              params: {
                prevId: this.item.id,
                prevType: this.item.type,
              },
            })
          },
        },
        {
          title: this.$t('project.signing-checklist.list.SigningChecklistTableItem.addTitle'),
          action: async () => {
            const payload = {
              prevId: this.item.id,
              prevType: 'step',
              depth: this.item.depth,
              title: '',
            }
            const newTitle = await this.CREATE_TITLE({
              mnemo: this.mnemo,
              data: payload,
              parent: this.parent,
            })
            this.SET_CURRENT_TITLE_EDIT(newTitle)
            this.REFRESH_SIGNING_CHECKLIST({
              mnemo: this.mnemo,
            })
          },
          loading: this.createTitlePending,
        },
      ]
    },
    isDisplayed () {
      return !this.isSigningChecklistFiltered || (this.isSigningChecklistFiltered && this.isMatchingFilters)
    },
    allWitnessFlattened () {
      return this.item.envelope.witnesses.flat()
    },
  },
  methods: {
    ...mapMutations([SET_CURRENT_TITLE_EDIT, SET_DELETE_STEP_MODAL, SET_SWAP_FILES, ADD_REFRESH_STEP_PENDING, REMOVE_REFRESH_STEP_PENDING, SET_CANCEL_STEP_MODAL, SET_SEND_SIGN_STEP_MODAL, SET_VOIDED_TO_DRAFT_STEP_MODAL]),
    ...mapActions([REFRESH_STEP, VALIDATE_THEN_SEND_NOW, CREATE_TITLE, REFRESH_SIGNING_CHECKLIST, OPEN_SHARE_ATTACHMENT_FILE_PANE]),
    onSelectedStepClick () {
      this.$store.commit(`signingChecklist/${TOGGLE_SELECTED_STEP}`, this.item)
    },
    getValidationDate (date) {
      return ISOToShortDate(date)
    },
    openShareAttachmentFilePane (attachmentFile) {
      this.OPEN_SHARE_ATTACHMENT_FILE_PANE(attachmentFile)
    },
    openSendSignDialog () {
      this.SET_SEND_SIGN_STEP_MODAL({
        steps: [this.item],
        parent: this.parent,
        ids: [this.item.id],
      })
    },
    confirmQualifiedSignatureWarning () {
      this.qualifiedDSWarningDialog = false
      this.openSendSignDialog()
    },
    getSignerWitness (signerId) {
      return this.allWitnessFlattened.filter(w => w.witnessOfSignerId === signerId)
    },
    hasSignerWitness (signerId) {
      return this.getSignerWitness(signerId).length > 0
    },
    handleSignAsWitness (envelopeId) {
      // TODO : This is a temporary workaround until we decide how to handle router links on the new design systems buttons
      this.$router.push({
        name: SIGNING_VIEW_ROUTE_NAME,
          params: {
            envelopeId: envelopeId,
          },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.SigningChecklistTableItem {
  background-color: #f5f5f5;
  border: 1px solid #eee;
  border-radius: 4px;
}

.SigningChecklistTableItem-status {
  border-left: 8px solid transparent;

  &--draft, &--temp {
    border-left-color: #e0e0e0;
  }

  &--sent {
    border-left-color: var(--v-warning-base);
  }

  &--signed {
    border-left-color: var(--v-success-base);
  }

  &--queued {
    border-left-color: var(--v-warning-base);
  }

  &--voided {
    border-left-color: var(--v-error-base);
  }

  &--unvalidated {
    border-left-color: var(--v-warning-base);
  }
}

.SigningChecklistTableItem.sortable-ghost,
.SigningChecklistTableItem.sortable-chosen,
.SigningChecklistTableItem.sortable-drag {
  background-color: var(--v-primary-lighten4) !important;
  border: 2px dashed var(--v-primary-base) !important;
}

.marker {
  position: absolute;
  bottom: -17px;
  left: -20px;
  cursor: pointer;
  opacity: 0.5;
  font-size: 26px;
  color: var(--v-primary-base);
  z-index: 1;
}

.marker:hover {
  opacity: 1;
}

.SigningChecklistTableItem-mobileRow {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
}

.SigningChecklistTableItem-mobileRowGrid {
  padding-left: 15px;
  padding-right: 15px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
}

.SigningChecklistTableItem-mobileRow-separator {
  width: 100%;
  height: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: #eee;
}

::v-deep .SigningChecklistTableItem-select {
  .v-input__slot {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .v-input__append-inner {
    padding-left: 0;
  }

  .v-btn {
    width: 16px;
    height: 20px;
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
}

.item-name {
  overflow-wrap: anywhere;
}

.attachment-file-actions {
  display: flex;
  position: relative;
  left: -7px;
}
</style>
