<template>
  <div>
    <AppButton
      v-if="!noActivator"
      color="primary"
      @click.stop="isOpen = true"
    >
      <template v-if="headerDesign">
        <font-awesome-icon v-if="!$vuetify.breakpoint.xs"
                           class="mr-2"
                           :icon="['far', 'plus']"
        ></font-awesome-icon>
        <span v-if="!$vuetify.breakpoint.xs">{{ $t('projects.dialogs.ProjectsNew.newProject') }}</span>
      </template>
      <template v-else>
        <font-awesome-icon :icon="['far', 'plus']"
                           style="font-size: 24px;"
        ></font-awesome-icon>
      </template>
    </AppButton>
    <template v-if="roomTypesAllowed">
      <template v-if="roomTypesAllowed === 'SUBSCRIPTION'">
        <ProjectsNewSubscriptionDialog v-if="computedIsOpen"
                                       :is-open="computedIsOpen"
                                       @close="closeDialog"
        />
      </template>
      <template v-if="roomTypesAllowed === 'PROJECT'">
        <ProjectsNewProjectDialog v-if="computedIsOpen"
                                  :is-open="computedIsOpen"
                                  @close="closeDialog"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import ProjectsNewProjectDialog from '@/projects/dialogs/ProjectsNewProjectDialog'
import ProjectsNewSubscriptionDialog from '@/projects/dialogs/ProjectsNewSubscriptionDialog'

import { ROOM_TYPES } from '../../common/utils/roomTypes'
export default {
  name: 'ProjectsNew',
  components: { ProjectsNewProjectDialog, ProjectsNewSubscriptionDialog, AppButton },
  props: {
    headerDesign: {
      type: Boolean,
      default: true,
    },
    dialogIsOpen: {
      type: Boolean,
      default: false,
    },
    noActivator: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
    ...mapState('rooms', ['roomTypes']),
    roomTypesAllowed () {
      if (this.roomTypes) {
        if (this.roomTypes.find(o => o.type === ROOM_TYPES.PROJECT_UNLIMITED)) {
          return 'SUBSCRIPTION'
        } else if (this.roomTypes.find(o => o.type === ROOM_TYPES.PROJECT)) {
          return 'PROJECT'
        }
      }
      return null
    },
    computedIsOpen () {
      return this.isOpen || this.dialogIsOpen
    },
  },
  methods: {
    closeDialog () {
      this.isOpen = false
      this.$emit('update:dialogIsOpen', false)
    },
  },
}
</script>
