export type GroupsState = {
  deleteGroupLoading: boolean,
  error: string | null,
  groups: Group[],
  loading: boolean,
  patchHideGroupsLoading: boolean,
  patchIsHiddenPmError: string | null,
  patchIsHiddenPmPending: boolean,
  postGroupLoading: boolean,
  postMassLoading: boolean,
  postMemberFromTeamLoading: boolean,
}
export const state: GroupsState = {
  deleteGroupLoading: false,
  error: null,
  groups: [],
  loading: false,
  patchHideGroupsLoading: false,
  patchIsHiddenPmError: null,
  patchIsHiddenPmPending: false,
  postGroupLoading: false,
  postMassLoading: false,
  postMemberFromTeamLoading: false,
}
