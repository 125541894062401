<template>
  <div class="local-filders-selector">
    <AppDroppableArea class="local-filders-selector__droppable-area pa-6"
                      :disabled="disabled"
                      @drop="dropFilders"
    >
      <div class="d-flex flex-column align-center justify-center row-gap-6 h-100">
        <img src="/img/icons/2fichiers.svg"
             alt=""
             width="105"
        />
        <div class="text-center">
          <app-text as="span" variant="small-bold">
            {{ $tc("common.filders.ExternalFildersSelector.dragAndDropFildersHint", multiple) }}
          </app-text>
          <app-text variant="small-regular">
            {{ $t("common.filders.ExternalFildersSelector.importAlternativeInstruction") }}
          </app-text>
        </div>

        <div class="d-flex flex-column align-center row-gap-2">
          <AppButton type="primary"
                      size="small"
                      :disabled="disabled"
                      @click="$refs.filesLoaderInput.click()"
          >
            <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
            {{ $tc("common.filders.ExternalFildersSelector.importFiles", multiple) }}
          </AppButton>

          <AppButton v-if="!filesOnly"
                      type="primary"
                      size="small"
                      :disabled="disabled"
                      @click="$refs.foldersLoaderInput.click()"
          >
            <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
            {{ $t("common.filders.ExternalFildersSelector.importFolders") }}
          </AppButton>

          <AppButton v-if="imanageEnabledAndLogged"
                     type="primary"
                     size="small"
                     :disabled="disabled"
                     @click="imanageDialogIsOpen = true"
          >
            <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
            {{ $t('common.filders.ExternalFildersSelector.importFromImanage') }}
          </AppButton>

          <AppButton v-if="polyOfficeEnabled"
                     type="primary"
                     size="small"
                     :disabled="disabled"
                     @click="openPolyOfficeDialog"
          >
            <template #left-icon>
              <PolyOfficeIcon color="white"/>
            </template>
            {{ $t('common.filders.ExternalFildersSelector.importFromPoly') }}
          </AppButton>
        </div>
      </div>

      <input
        ref="filesLoaderInput"
        type="file"
        accept=".pdf, .doc, .docx"
        multiple
        class="d-none"
        @change="importFiles($event)"
      />
      <input
        ref="foldersLoaderInput"
        type="file"
        accept=".pdf, .doc, .docx"
        webkitdirectory
        multiple
        class="d-none"
        @change="importFiles($event)"
      />
    </AppDroppableArea>

    <IManageFilePickerDialog v-if="imanageEnabledAndLogged"
                             :is-open.sync="imanageDialogIsOpen"
                             @files="importFromImanage"
    />

    <PolyOfficeDialog v-if="polyOfficeEnabled && polyOfficeDialogIsOpen"
                      @callback="polyOfficeDialogCallback"
    />
  </div>
</template>

<script lang="ts">
import { getFilesFromDataTransferItems } from 'datatransfer-files-promise'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AppDroppableArea from '@/common/AppDroppableArea.vue'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog.vue'
import { SigningChecklistImport } from '@/common/polyOffice/polyOffice.model'
import PolyOfficeDialog, { POLY_OFFICE_DIALOG_STATUS_SUCCESS } from '@/common/polyOffice/PolyOfficeDialog.vue'
import PolyOfficeIcon from '@/common/polyOffice/PolyOfficeIcon.vue'
import AppButton from '@/design-system/buttons/AppButton.vue'
import { IMANAGE_HIDDEN_FILE_IMPORT } from '@/store/modules/documents/action_types'
import { GET_ACTION_INFO, GET_IMPORT_IFRAME } from '@/store/modules/poly-office/action_types'
import { ENQUEUE_ERROR_SNACKBAR } from '@/store/mutation_types'

export default defineComponent({
  name: 'ExternalFildersSelector',
  components: {
    PolyOfficeIcon,
    AppButton,
    AppDroppableArea,
    IManageFilePickerDialog,
    PolyOfficeDialog,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    filesOnly: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      imanageDialogIsOpen: false,
    }
  },
  computed: {
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapGetters('room', ['roomMnemo']),
    ...mapGetters('user', ['polyOfficeEnabled']),
    ...mapState('polyOffice', {
      polyOfficeDialogIsOpen: 'dialogIsOpen',
    }),
  },
  methods: {
    ...mapActions('documents', [IMANAGE_HIDDEN_FILE_IMPORT]),
    ...mapActions('polyOffice', [GET_IMPORT_IFRAME, GET_ACTION_INFO]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR]),
    importFiles ($event) {
      const { files } = $event.target
      this.$emit('select', files)
      ;(this.$refs.filesLoaderInput as HTMLInputElement).value = ''
      ;(this.$refs.foldersLoaderInput as HTMLInputElement).value = ''
    },
    async dropFilders ($event) {
      const dataTransferItems = $event.dataTransfer?.items
      if (dataTransferItems) {
        const droppedFiles = await getFilesFromDataTransferItems(dataTransferItems)
        this.$emit('select', droppedFiles)
      }
    },
    async importFromImanage (files) {
      const imanageFileImportPromises = files.output_data.selected.map(
        file => this.IMANAGE_HIDDEN_FILE_IMPORT({
          mnemo: this.roomMnemo,
          data: {
            file: file.id,
          },
        }),
      )

      const responses = await Promise.all(imanageFileImportPromises)
      const iManageFiles = responses.map(response => response.data)
      this.$emit('select', iManageFiles)
    },
    async openPolyOfficeDialog () {
      const payload = new SigningChecklistImport(this.roomMnemo)
      await this.GET_IMPORT_IFRAME({
        payload,
        successMessage: this.$t('common.filders.ExternalFildersSelector.polyOffice.importSuccessful'),
      })
    },
    async polyOfficeDialogCallback (data) {
      if (data.status === POLY_OFFICE_DIALOG_STATUS_SUCCESS) {
        try {
          const response = await this.GET_ACTION_INFO()

          if (response.receiveStatus === 'DONE') {
            this.$emit('select', response.receivedFilesData)
          } else {
            this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.filders.ExternalFildersSelector.polyOffice.importFailure'))
          }
        } catch (e) {
          this.ENQUEUE_ERROR_SNACKBAR(this.$t('common.filders.ExternalFildersSelector.polyOffice.importFailure'))
        }
      }
    },
  },
})
</script>

<style scoped lang="scss">
.local-filders-selector {
  border: 2px dashed #E0E0E0;
  border-radius: 7px;
  height: 100%;
  width: 100%;

  &__droppable-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
