<template>
  <AppChatMessage :message-date="messageDate">
    <template #message-author>
      <v-img
        src="/img/ai_assistant_logo.svg"
        :width="28"
        :height="28"
      />
    </template>

    <slot />

    <div v-if="enableActions" class="summary-message-actions">
      <app-button
        v-if="canProgrammaticallyCopySummary"
        class="copy-button"
        type="ghost"
        @click="copySummary"
      >
        <template #left-icon>
          <app-icon icon-name="copy" icon-weight="far" />
        </template>
        {{ copyButtonText }}
      </app-button>
    </div>
  </AppChatMessage>
</template>

<script>
import { defineComponent } from 'vue'

import AppChatMessage from '@/common/app-chat/AppChatMessage.vue'

export default defineComponent({
  name: 'DocumentsAISummaryChatMessage',
  components: { AppChatMessage },
  props: {
    messageDate: {
      type: String,
      required: true,
    },
    enableActions: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      copyButtonText: this.$t('common.copy'),
    }
  },
  computed: {
    canProgrammaticallyCopySummary () {
      return navigator.clipboard
    },
  },
  methods: {
    async copySummary () {
      if (this.$slots.default?.[0]?.text) {
        await navigator.clipboard.writeText(this.$slots.default?.[0]?.text)

        this.copyButtonText = `${this.$t('common.copied')} !`
        setTimeout(() => {
          this.copyButtonText = this.$t('common.copy')
        }, 3000)
      }
    },
  },
})
</script>

<style scoped lang="scss">
.summary-message-actions {
  .copy-button {
    margin-top: 16px;
    margin-left: auto;

    & ::v-deep span:first-of-type {
      column-gap: 4px;
    }
  }
}
</style>
