import { cloneDeep } from 'lodash-es'

import { surveyCanBeTriggered, SurveyId, triggerSurvey } from '@/plugins/satismeter'
import { SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN } from '@/store/modules/room/mutation_types'

import {
  ADD_VERSION,
  CHANGE_TABLE_DOCUMENTS_PANE_TAB,
  CLOSE_DOCUMENTS_PANE,
  CLOSE_TABLE_DOCUMENTS_PANE,
  DELETE_SEARCH_RESULTS,
  DELETE_VERSION,
  DESELECT_ALL_DOCUMENTS,
  DUPLICATE_FILDERS,
  EDIT_DOCUMENT_NUMBERING,
  GET_DOCUMENTS_BY_ID,
  GET_DOCUMENT_VERSIONS,
  GET_FOLDER_PATH,
  GET_NUMBERING,
  GET_SEARCH_RESULTS,
  GO_TO_FOLDER,
  IMANAGE_HIDDEN_FILE_IMPORT,
  LOAD_FOLDERS_TREE,
  MOVE_FILDERS,
  OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE,
  PATCH_NUMBERING,
  POST_FILDERS_AS_VERSION_TO_IMANAGE,
  POST_FILDERS_FROM_IMANAGE,
  POST_FILDERS_TO_IMANAGE,
  POST_MASS_FOLDERS,
  POST_REQUEST_AUTHORIZATION_BIBLE,
  POST_VERSION_FROM_IMANAGE,
  RESET_DOCUMENTS,
  RESET_FULL_TREE,
  SELECT_ALL_DOCUMENTS,
  SELECT_DOCUMENTS,
  SET_TABLE_DOCUMENTS_PANE,
  SHARE_DOCUMENTS_INDEX_BY_EMAIL,
  SHARE_FILDERS,
  UNSELECT_DOCUMENT,
  UPDATE_ACCESS_TIME,
  UPLOAD_HIDDEN_FILE,
  CHANGE_DOCUMENTS_VERSIONING_PANE_TAB,
  CLOSE_DOCUMENTS_VERSIONING_PANE,
  SET_DOCUMENTS_VERSIONING_PANE,
  CHECK_WOPI_LOCKED_FILES,
} from './action_types'
import {
  ADD_UPLOAD_HIDDEN_FILE_PENDING,
  REMOVE_SELECTED_DOCUMENT,
  REMOVE_UPLOAD_HIDDEN_FILE_PENDING,
  REMOVE_VERSION,
  SET_ALL_DOCUMENTS_AS_SELECTED,
  SET_DOCUMENTS,
  SET_DOCUMENTS_PANE,
  SET_DUPLICATE_LOADING,
  SET_EDIT_DOCUMENT_NUMBERING_PENDING,
  SET_ERROR,
  SET_FOLDERS_TREE,
  SET_FULL_TREE,
  SET_GET_FOLDER_PATH_PENDING,
  SET_GET_FOLDERS_TREE_PENDING,
  SET_IMANAGE_HIDDEN_FILE_IMPORT_ERROR,
  SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING,
  SET_LAST_ACCESS_TIMESTAMP,
  SET_LAST_MNEMO_ACCESSED,
  SET_LOADING,
  SET_MOVE_LOADING,
  SET_NUMBERING,
  SET_NUMBERING_ERROR,
  SET_PATCH_NUMBERING_PENDING,
  SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_ERROR,
  SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING,
  SET_POST_FILDERS_FROM_IMANAGE_ERROR,
  SET_POST_FILDERS_FROM_IMANAGE_PENDING,
  SET_POST_FILDERS_TO_IMANAGE_ERROR,
  SET_POST_FILDERS_TO_IMANAGE_PENDING,
  SET_POST_MASS_FOLDERS_ERROR,
  SET_POST_MASS_FOLDERS_PENDING,
  SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING,
  SET_POST_VERSION_FROM_IMANAGE_ERROR,
  SET_POST_VERSION_FROM_IMANAGE_PENDING,
  SET_REMOVE_VERSION_PENDING,
  SET_SEARCH_LOADING,
  SET_SEARCH_MODE_ENABLED,
  SET_SEARCH_RESULTS,
  SET_SELECTED_DOCUMENTS,
  SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING,
  SET_SHARE_LOADING,
  SET_TABLE_DOCUMENTS_PANE_TAB,
  SET_UPDATE_ACCESS_TIME_ERROR,
  SET_UPDATE_ACCESS_TIME_PENDING,
  SET_VERSIONS_LOADING,
  TOGGLE_TABLE_DOCUMENTS_PANE,
  SET_DOCUMENTS_VERSIONING_PANE_TAB,
  TOGGLE_DOCUMENTS_VERSIONING_PANE,
  SET_CHECK_WOPI_LOCKED_FILES_PENDING,
  SET_WOPI_LOCKED_FILES,
} from './mutation_types'
import i18n from '../../../i18n'
import documentsService from '../../../services/documents.service'
import {
  INIT_SELECTED_GROUPS,
  INIT_SELECTED_MEMBERS,
  RESET_SELECTED_GROUPS_AND_MEMBERS,
} from '../document-share-picker/action_types'

const DOCUMENTS_ACCESS_TIME_THROTTLE_MS = 60000

export const actions = {
  async [POST_VERSION_FROM_IMANAGE] ({ commit, rootState }, { mnemo, data }) {
    commit(SET_POST_VERSION_FROM_IMANAGE_PENDING, true)
    try {
      return await documentsService.postVersionFromImanage(mnemo, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_POST_VERSION_FROM_IMANAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_VERSION_FROM_IMANAGE_PENDING, false)
    }
  },
  async [POST_MASS_FOLDERS] ({ commit }, { mnemo, data }) {
    commit(SET_POST_MASS_FOLDERS_PENDING, true)
    try {
      return await documentsService.postMassFolders(mnemo, data)
    } catch (error) {
      commit(SET_POST_MASS_FOLDERS_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_MASS_FOLDERS_PENDING, false)
    }
  },
  async [UPDATE_ACCESS_TIME] ({ commit, state }, mnemo) {
    const lastAccessTimePlusThrottleTime = state.lastAccessTimestamp + DOCUMENTS_ACCESS_TIME_THROTTLE_MS
    if (mnemo === state.lastMnemoAccessed && (lastAccessTimePlusThrottleTime > Date.now())) {
      return
    }
    commit(SET_LAST_MNEMO_ACCESSED, mnemo)
    commit(SET_LAST_ACCESS_TIMESTAMP, Date.now())
    commit(SET_UPDATE_ACCESS_TIME_PENDING, true)
    try {
      await documentsService.updateAccessTime(mnemo)
    } catch (error) {
      commit(SET_UPDATE_ACCESS_TIME_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_ACCESS_TIME_PENDING, false)
    }
  },
  async [IMANAGE_HIDDEN_FILE_IMPORT] ({ commit, rootState }, { mnemo, data }) {
    commit(SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING, true)
    try {
      return await documentsService.imanageHiddenFileImport(mnemo, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_IMANAGE_HIDDEN_FILE_IMPORT_ERROR, error)
      throw error
    } finally {
      commit(SET_IMANAGE_HIDDEN_FILE_IMPORT_PENDING, false)
    }
  },
  async [POST_FILDERS_AS_VERSION_TO_IMANAGE] ({ commit, rootState }, { mnemo, data }) {
    commit(SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING, true)
    try {
      await documentsService.postFildersAsVersionToIManage(mnemo, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_FILDERS_AS_VERSION_TO_IMANAGE_PENDING, false)
    }
  },
  async [POST_FILDERS_TO_IMANAGE] ({ commit, rootState }, { mnemo, data }) {
    commit(SET_POST_FILDERS_TO_IMANAGE_PENDING, true)
    try {
      await documentsService.postFildersToIManage(mnemo, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_POST_FILDERS_TO_IMANAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_FILDERS_TO_IMANAGE_PENDING, false)
    }
  },
  async [POST_FILDERS_FROM_IMANAGE] ({ commit, rootState }, { mnemo, data }) {
    commit(SET_POST_FILDERS_FROM_IMANAGE_PENDING, true)
    try {
      await documentsService.postFildersFromIManage(mnemo, data, rootState.imanage.authCode?.imConfig)
    } catch (error) {
      commit(SET_POST_FILDERS_FROM_IMANAGE_ERROR, error)
      throw error
    } finally {
      commit(SET_POST_FILDERS_FROM_IMANAGE_PENDING, false)
    }
  },
  async [UPLOAD_HIDDEN_FILE] ({ commit, state }, { file, mnemo }) {
    // We don't actually have any id for files not yet uploaded
    // so we generate a unique one from the currently pending array
    const id = state.uploadHiddenFilePendingIds.length + 1
    commit(ADD_UPLOAD_HIDDEN_FILE_PENDING, id)
    try {
      const { data } = await documentsService.postHiddenFile(file, mnemo)

      // TODO : /!\ REMOVE ARG MUTATION /!\
      file.status = 'success'
      // File name may be truncated
      file.name = data.name
      file.basename = data.basename
      file.ext = data.ext
      file.id = data.id
    } catch (error) {
      file.status = 'error'
      console.error(error)
      throw error
    } finally {
      commit(REMOVE_UPLOAD_HIDDEN_FILE_PENDING,
        state.uploadHiddenFilePendingIds.indexOf(id),
      )
    }
  },
  async [GET_DOCUMENTS_BY_ID] ({ commit, rootGetters, state }, { mnemo = rootGetters['room/roomMnemo'], id = state.documents.id, queryObject }) {
    commit(SET_LOADING, true)
    try {
      const response = await documentsService.getDocumentsById(mnemo, id || 0, queryObject)
      if (!id || id === 0) {
        commit(SET_FULL_TREE, response.data)
      }
      commit(SET_DOCUMENTS, response.data)
    } catch (e) {
      commit(SET_ERROR, e)
      throw e
    } finally {
      commit(SET_LOADING, false)
    }
  },
  [RESET_DOCUMENTS] ({ commit }) {
    commit(SET_DOCUMENTS, {})
    commit(SET_SEARCH_MODE_ENABLED, false)
  },
  [RESET_FULL_TREE] ({ commit }) {
    commit(SET_FULL_TREE, {})
  },
  async [GO_TO_FOLDER] ({ commit, rootGetters, dispatch }, id) {
    commit(SET_LOADING, true)
    if (id === 'recentFolder') {
      try {
        const response = await documentsService.getNewDocuments(rootGetters['room/roomMnemo'])
        const documents = {
          basename: i18n.t('common.recentFolderName'),
          children: response.data,
          id: 'recentFolder',
          name: i18n.t('common.recentFolderName'),
          type: 'folder',
          specialFolder: 'new',
        }
        dispatch('documentsBreadcrumb/addBreadcrumbItem', documents, { root: true })
        commit(SET_DOCUMENTS, documents)
      } catch (e) {
        // TODO: really handle this error.
        console.error(e)
      } finally {
        commit(SET_LOADING, false)
      }
    } else {
      try {
        const response = await documentsService.getDocumentsById(rootGetters['room/roomMnemo'], id, { markRecent: true })
        dispatch(DESELECT_ALL_DOCUMENTS)
        dispatch('documentsBreadcrumb/addBreadcrumbItem', response.data, { root: true })
        commit(SET_DOCUMENTS, response.data)
      } catch (e) {
        // TODO: really handle this error.
        console.error(e)
      } finally {
        commit(SET_LOADING, false)
      }
    }
  },
  [SELECT_DOCUMENTS] ({ commit }, selectedDocuments) {
    commit(SET_SELECTED_DOCUMENTS, selectedDocuments)
  },
  [SELECT_ALL_DOCUMENTS] ({ commit }) {
    commit(SET_ALL_DOCUMENTS_AS_SELECTED)
  },
  [DESELECT_ALL_DOCUMENTS] ({ commit }) {
    commit(SET_SELECTED_DOCUMENTS, [])
  },
  [SET_TABLE_DOCUMENTS_PANE] ({ commit, dispatch, rootState }, payload) {
    commit(TOGGLE_TABLE_DOCUMENTS_PANE, payload)
    if (payload.documents.length === 1 && payload.documents[0].type === 'file') {
      if (payload.documents[0].rights) {
        dispatch(`documentSharePicker/${INIT_SELECTED_GROUPS}`, cloneDeep(payload.documents[0].rights.read.groups), { root: true })
        dispatch(`documentSharePicker/${INIT_SELECTED_MEMBERS}`, cloneDeep(payload.documents[0].rights.read.users), { root: true })
      }
    } else if (payload.forcePmSelection && payload.documents[0].rights) {
      const testGroups = payload.documents.map(x => JSON.stringify(x.rights.read.groups) === JSON.stringify(payload.documents[0].rights.read.groups)).includes(false)
      const testUsers = payload.documents.map(x => JSON.stringify(x.rights.read.users) === JSON.stringify(payload.documents[0].rights.read.users)).includes(false)
      if (!testGroups && !testUsers && payload.documents[0].rights) {
        dispatch(`documentSharePicker/${INIT_SELECTED_GROUPS}`, cloneDeep(payload.documents[0].rights.read.groups), { root: true })
        dispatch(`documentSharePicker/${INIT_SELECTED_MEMBERS}`, cloneDeep(payload.documents[0].rights.read.users), { root: true })
      } else if (payload.documents[0].rights) {
        dispatch(`documentSharePicker/${INIT_SELECTED_GROUPS}`, [rootState.groups.groups.find(g => g.isPm)], { root: true })
        dispatch(`documentSharePicker/${INIT_SELECTED_MEMBERS}`, [], { root: true })
      }
    } else {
      dispatch(`documentSharePicker/${INIT_SELECTED_GROUPS}`, [], { root: true })
      dispatch(`documentSharePicker/${INIT_SELECTED_MEMBERS}`, [], { root: true })
    }
  },
  [CHANGE_TABLE_DOCUMENTS_PANE_TAB] ({ commit }, tab) {
    commit(SET_TABLE_DOCUMENTS_PANE_TAB, tab)
  },
  [CLOSE_TABLE_DOCUMENTS_PANE] ({ commit, dispatch }) {
    dispatch(GET_DOCUMENTS_BY_ID, {
      mnemo: undefined,
      id: undefined,
      queryObject: {
        markRecent: true,
      },
    })
    commit(TOGGLE_TABLE_DOCUMENTS_PANE, {
      documents: [],
      forcePmSelection: false,
      isOpen: false,
      tab: null,
    })
  },
  async [SHARE_FILDERS] ({ commit, state, rootState, rootGetters, dispatch }, payload) {
    commit(SET_SHARE_LOADING, true)
    const data = {
      folderIds: [],
      fileIds: [],
      shareType: payload.shareType,
      withUsers: rootState.documentSharePicker.selectedMembers.map(i => { return i.id }),
      withGroups: rootState.documentSharePicker.selectedGroups.map(i => { return i.id }),
      sendNotif: payload.sendNotif,
      notifyMessage: payload.notifyMessage,
      applyToOldVersions: payload.applyToOldVersions,
    }
    state.documentsTablePane.documents.forEach(document => {
      if (document.type === 'file') {
        data.fileIds.push(document.id)
      } else if (document.type === 'folder') {
        data.folderIds.push(document.id)
      }
    })

    try {
      await documentsService.shareFilders(rootGetters['room/roomMnemo'], data)
    } catch (error) {
      console.error(error)
      commit(SET_ERROR, error)
    }

    dispatch(CLOSE_TABLE_DOCUMENTS_PANE)
    dispatch(`documentSharePicker/${RESET_SELECTED_GROUPS_AND_MEMBERS}`, null, { root: true })
    commit(SET_SHARE_LOADING, false)

    const { id: userId, fullName, email, isSubUser } = rootState.user.profile
    if (surveyCanBeTriggered(SurveyId.CSAT, userId)) {
      triggerSurvey(SurveyId.CSAT, userId, fullName, email, isSubUser)
    }
    if (surveyCanBeTriggered(SurveyId.NPS, userId)) {
      triggerSurvey(SurveyId.NPS, userId, fullName, email, isSubUser)
    }
  },
  async [DUPLICATE_FILDERS] ({ commit, rootGetters, dispatch }, { documents, targetFolderId }) {
    commit(SET_DUPLICATE_LOADING, true)
    const duplicateFildersData = documents.reduce(
      (result, document) => {
        if (document.type === 'file') {
          result.fileIds.push(document.id)
        }
        return result
      },
      {
        fileIds: [],
        parentId: targetFolderId,
      },
    )

    try {
      const { data } = await documentsService.duplicateFilders(rootGetters['room/roomMnemo'], duplicateFildersData)
      if (data.errors?.length !== 0) {
        throw new Error('Some documents could not be duplicated properly')
      }

      dispatch(GET_DOCUMENTS_BY_ID, {
        mnemo: undefined,
        id: undefined,
        queryObject: {
          markRecent: true,
        },
      })
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_DUPLICATE_LOADING, false)
    }
  },
  async [MOVE_FILDERS] ({ commit, rootGetters, dispatch }, { documents, targetFolderId }) {
    commit(SET_MOVE_LOADING, true)

    const moveFildersData = documents.reduce(
      (result, document) => {
        if (document.type === 'file') {
          result.fileIds.push(document.id)
        } else if (document.type === 'folder') {
          result.folderIds.push(document.id)
        }

        return result
      },
      {
        folderIds: [],
        fileIds: [],
        parentId: targetFolderId,
      },
    )

    try {
      const { data } = await documentsService.moveFilders(rootGetters['room/roomMnemo'], moveFildersData)

      if (data.error.fileIds?.length !== 0 || data.error.folderIds.length !== 0) {
        throw new Error('Some documents could not be moved properly')
      }

      dispatch(GET_DOCUMENTS_BY_ID, {
        mnemo: undefined,
        id: undefined,
        queryObject: {
          markRecent: true,
        },
      })
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_MOVE_LOADING, false)
    }
  },
  async [GET_DOCUMENT_VERSIONS] ({ commit }, { mnemo, documentId }) {
    commit(SET_VERSIONS_LOADING, true)
    try {
      return await documentsService.getDocumentVersions(mnemo, documentId)
    } catch (error) {
      commit(SET_ERROR, error)
      throw error
    } finally {
      commit(SET_VERSIONS_LOADING, false)
    }
  },
  [ADD_VERSION] ({ commit, rootGetters, state, dispatch }, payload) {
    commit(SET_VERSIONS_LOADING, true)
    documentsService.postNewVersion(rootGetters['room/roomMnemo'], state.documentsVersioningPane.documents[0].id, payload)
      .then(() => {
        dispatch(GET_DOCUMENTS_BY_ID, {
          mnemo: undefined,
          id: undefined,
          queryObject: {
            markRecent: true,
          },
        })
        dispatch(CLOSE_DOCUMENTS_VERSIONING_PANE)
      })
      .catch(error => {
        commit(SET_ERROR, error)
        if (error?.response?.data?.errorCode === 'ERR_STORAGE_EXCEEDED') {
          commit(`room/${SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN}`, true, { root: true })
        }
      })
      .then(() => {
        commit(SET_VERSIONS_LOADING, false)
      })
  },
  [GET_SEARCH_RESULTS] ({ commit, rootGetters, state, dispatch }, searchQuery) {
    commit(SET_SEARCH_LOADING, true)
    if (searchQuery) {
      commit(SET_SEARCH_MODE_ENABLED, true)
      documentsService.getSearchResults(rootGetters['room/roomMnemo'], searchQuery)
        .then(response => {
          commit(SET_SEARCH_RESULTS, response.data)
        })
        .catch(error => {
          commit(SET_ERROR, error)
        })
        .then(() => {
          commit(SET_SEARCH_LOADING, false)
        })
    } else {
      dispatch(DELETE_SEARCH_RESULTS)
    }
  },
  [DELETE_SEARCH_RESULTS] ({ commit }) {
    commit(SET_SEARCH_MODE_ENABLED, false)
    commit(SET_SEARCH_RESULTS, [])
  },
  async [DELETE_VERSION] ({ commit, rootGetters }, version) {
    commit(SET_REMOVE_VERSION_PENDING, true)
    try {
      const data = {
        folderIds: [],
        fileIds: [version.id],
      }
      await documentsService.deleteFilders(rootGetters['room/roomMnemo'], data)
      commit(REMOVE_VERSION, version)
    } catch (error) {
      commit(SET_ERROR, error)
      throw error
    } finally {
      commit(SET_REMOVE_VERSION_PENDING, false)
    }
  },
  async [PATCH_NUMBERING] ({ commit }, { mnemo, value }) {
    commit(SET_PATCH_NUMBERING_PENDING, true)
    try {
      await documentsService.patchNumbering(mnemo, value)
    } finally {
      commit(SET_PATCH_NUMBERING_PENDING, false)
    }
  },
  [UNSELECT_DOCUMENT] ({ commit }, documentId) {
    commit(REMOVE_SELECTED_DOCUMENT, documentId)
  },
  async [GET_NUMBERING] ({ commit }, mnemo) {
    try {
      const response = await documentsService.getNumbering(mnemo)
      commit(SET_NUMBERING, !!response.data.documentsNumbering)
    } catch (error) {
      commit(SET_NUMBERING_ERROR, error)
    }
  },
  async [EDIT_DOCUMENT_NUMBERING] ({ commit, rootGetters }, { documentType, documentId, numbering }) {
    commit(SET_EDIT_DOCUMENT_NUMBERING_PENDING, true)

    try {
      const response = await documentsService.patchDocumentNumbering(rootGetters['room/roomMnemo'], documentType, documentId, numbering)
      return response.data
    } finally {
      commit(SET_EDIT_DOCUMENT_NUMBERING_PENDING, false)
    }
  },
  async [GET_FOLDER_PATH] ({ commit, rootGetters }, folderId) {
    commit(SET_GET_FOLDER_PATH_PENDING, true)

    try {
      const response = await documentsService.getFolderPath(rootGetters['room/roomMnemo'], folderId)
      return response?.data?.path
    } finally {
      commit(SET_GET_FOLDER_PATH_PENDING, false)
    }
  },
  [CLOSE_DOCUMENTS_PANE] ({ commit }) {
    commit(SET_DOCUMENTS_PANE, {
      isOpen: false,
      tab: '',
    })
  },
  [OPEN_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PANE] ({ commit }) {
    commit(SET_DOCUMENTS_PANE, {
      isOpen: true,
      tab: 'share-email',
    })
  },
  async [SHARE_DOCUMENTS_INDEX_BY_EMAIL] ({ commit }, { mnemo, data }) {
    commit(SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING, true)

    try {
      await documentsService.shareDocumentsIndexByEmail(mnemo, data)
    } catch (error) {
      commit(SET_ERROR, error)
      throw error
    } finally {
      commit(SET_SHARE_DOCUMENTS_INDEX_BY_EMAIL_PENDING, false)
    }
  },
  async [POST_REQUEST_AUTHORIZATION_BIBLE] ({ commit }, { mnemo }) {
    commit(SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING, true)

    try {
      await documentsService.requestAuthorizationBible(mnemo)
    } finally {
      commit(SET_POST_REQUEST_AUTHORIZATION_BIBLE_PENDING, false)
    }
  },
  async [LOAD_FOLDERS_TREE] ({ commit, rootGetters }) {
    commit(SET_GET_FOLDERS_TREE_PENDING, true)
    try {
      const { data } = await documentsService.getFoldersTree(rootGetters['room/roomMnemo'])
      commit(SET_FOLDERS_TREE, data)
    } finally {
      commit(SET_GET_FOLDERS_TREE_PENDING, false)
    }
  },
  [CHANGE_DOCUMENTS_VERSIONING_PANE_TAB] ({ commit }, tab) {
    commit(SET_DOCUMENTS_VERSIONING_PANE_TAB, tab)
  },
  [CLOSE_DOCUMENTS_VERSIONING_PANE] ({ commit, dispatch }) {
    dispatch(GET_DOCUMENTS_BY_ID, {
      mnemo: undefined,
      id: undefined,
      queryObject: {
        markRecent: true,
      },
    })
    commit(TOGGLE_DOCUMENTS_VERSIONING_PANE, {
      documentVersions: [],
      documents: [],
      isOpen: false,
      tab: null,
    })
  },
  [SET_DOCUMENTS_VERSIONING_PANE] ({ commit }, payload) {
    commit(TOGGLE_DOCUMENTS_VERSIONING_PANE, payload)
  },
  async [CHECK_WOPI_LOCKED_FILES] ({ commit }, { mnemo, filesIds }) {
    commit(SET_CHECK_WOPI_LOCKED_FILES_PENDING, true)
    try {
      const { data } = await documentsService.checkWopiLockedFiles(mnemo, filesIds)
      commit(SET_WOPI_LOCKED_FILES, data.lockedFiles)
      return data
    } finally {
      commit(SET_CHECK_WOPI_LOCKED_FILES_PENDING, false)
    }
  },
}
