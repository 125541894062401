export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_USER_CAN_CREATE_ROOMS = 'SET_USER_CAN_CREATE_ROOMS'
export const SET_UPDATE_PROFILE_PENDING = 'SET_UPDATE_PROFILE_PENDING'
export const SET_UPDATE_PROFILE_ERROR = 'SET_UPDATE_PROFILE_ERROR'
export const SET_UPDATE_LANGUAGE_PENDING = 'SET_UPDATE_LANGUAGE_PENDING'
export const SET_UPDATE_LANGUAGE_ERROR = 'SET_UPDATE_LANGUAGE_ERROR'
export const SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING = 'SET_UPDATE_NOTIFICATION_PREFERENCES_PENDING'
export const SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR = 'SET_UPDATE_NOTIFICATION_PREFERENCES_ERROR'
export const SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING = 'SET_CURRENT_NOTIFICATION_PREFERENCES_PENDING'
export const SET_CURRENT_NOTIFICATION_PREFERENCES = 'SET_CURRENT_NOTIFICATION_PREFERENCES'
export const SET_CURRENT_NOTIFICATION_PREFERENCES_ERROR = 'SET_CURRENT_NOTIFICATION_PREFERENCES_ERROR'
export const SET_CREATE_NOTIFICATION_PREFERENCES_PENDING = 'SET_CREATE_NOTIFICATION_PREFERENCES_PENDING'
export const SET_CREATE_NOTIFICATION_PREFERENCES = 'SET_CREATE_NOTIFICATION_PREFERENCES'
export const SET_CREATE_NOTIFICATION_PREFERENCES_ERROR = 'SET_CREATE_NOTIFICATION_PREFERENCES_ERROR'
export const SET_UPDATE_PASSWORD_PENDING = 'SET_UPDATE_PASSWORD_PENDING'
export const SET_UPDATE_PASSWORD_ERROR = 'SET_UPDATE_PASSWORD_ERROR'
export const SET_DELETE_NOTIFICATION_PREFERENCES_PENDING = 'SET_DELETE_NOTIFICATION_PREFERENCES_PENDING'
export const SET_DELETE_NOTIFICATION_PREFERENCES_ERROR = 'SET_DELETE_NOTIFICATION_PREFERENCES_ERROR'
export const SET_GET_SELF_USER_PENDING = 'SET_GET_SELF_USER_PENDING'
export const SET_SELF_CONTACT_LIST_PENDING = 'SET_SELF_CONTACT_LIST_PENDING'
export const SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION = 'SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION'
export const SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION_PAYING_USERS = 'SET_SELF_CONTACT_LIST_FROM_SUBSCRIPTION_PAYING_USERS'
export const SET_SELF_CONTACT_LIST = 'SET_SELF_CONTACT_LIST'
export const SET_ID_CHECK_PENDING = 'SET_ID_CHECK_PENDING'
export const SET_ID_CHECK = 'SET_ID_CHECK'
export const SET_ID_CHECK_ERROR = 'SET_ID_CHECK_ERROR'
export const SET_SHOW_IDENTITY_CHECK_MODAL = 'SET_SHOW_IDENTITY_CHECK_MODAL'
export const SET_ID_CHECK_STATE_PENDING = 'SET_ID_CHECK_STATE_PENDING'
export const SET_ID_CHECK_STATE = 'SET_ID_CHECK_STATE'
export const SET_ID_CHECK_STATE_ERROR = 'SET_ID_CHECK_STATE_ERROR'
export const SET_SELECTED_ID_DOCUMENT_TYPE = 'SET_SELECTED_ID_DOCUMENT_TYPE'
export const SET_SHOW_IDENTITY_CHECK_LANDING_MODAL = 'SET_SHOW_IDENTITY_CHECK_LANDING_MODAL'
export const SET_ACTIVITY_POLLING_PENDING = 'SET_ACTIVITY_POLLING_PENDING'
export const SET_ACTIVITY_POLLING_ERROR = 'SET_ACTIVITY_POLLING_ERROR'
export const SET_GET_ZENDESK_AUTH_PENDING = 'SET_GET_ZENDESK_AUTH_PENDING'
export const SET_ALIASES_PENDING = 'SET_ALIASES_PENDING'
export const SET_ALIASES = 'SET_ALIASES'
export const SET_ALIASES_ERROR = 'SET_ALIASES_ERROR'
export const ADD_ALIAS = 'ADD_ALIAS'
export const SET_ADD_NEW_ALIAS_PENDING = 'SET_ADD_NEW_ALIAS_PENDING'
export const SET_VALIDATE_ALIAS_PENDING = 'SET_VALIDATE_ALIAS_PENDING'
export const SET_RESEND_ALIAS_CHECK_LINK_PENDING = 'SET_RESEND_ALIAS_CHECK_LINK_PENDING'
export const SET_DELETE_ALIAS_PENDING = 'SET_DELETE_ALIAS_PENDING'
export const SET_DELETE_ALIAS_DIALOG = 'SET_DELETE_ALIAS_DIALOG'
