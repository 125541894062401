import dayjs from 'dayjs'
import Vue from 'vue'

import { dateToStringDateTime } from '@/common/utils/dates'

import {
  ADD_CHASE_TIMER_ENVELOPE,
  ADD_CHASE_TIMER_GLOBAL,
  ADD_CHASE_TIMER_SIGNATURE,
  ADD_CHASE_TIMER_VALIDATION,
  ADD_TO_SIGNATURES,
  REMOVE_CHASE_VALIDATOR_PENDING,
  RESET_CLOSING_DATA,
  RESET_ROOM,
  RESET_ROOM_RIGHTS,
  SET_CHASE_USERS_ERROR,
  SET_CHASE_USERS_PENDING,
  SET_CHASE_VALIDATOR_ERROR,
  SET_CHASE_VALIDATOR_PENDING,
  SET_CLOSE_ROOM_ERROR,
  SET_CLOSE_ROOM_PENDING,
  SET_CLOSING_SCHEDULED_AT,
  SET_CLOSING_SCHEDULED_END_AT,
  SET_CLOSING_STARTED_AT,
  SET_CURRENT_ROOM,
  SET_CURRENT_ROOM_PROVIDERS,
  SET_ENABLE_DOCUMENTS_LOGS,
  SET_IN_CLOSING,
  SET_LAST_ACCESS_TIMESTAMP,
  SET_LAST_MNEMO_ACCESSED,
  SET_MORE_SIGNATURES_ERROR,
  SET_MORE_SIGNATURES_PENDING,
  SET_NOTIFY_PM_NO_MORE_SPACE_PENDING,
  SET_NOTIFY_PM_PLAN_DATAROOM_FULL_PENDING,
  SET_PATCH_ROOM_ERROR,
  SET_PATCH_ROOM_GLOBAL_RIGHTS_ERROR,
  SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING,
  SET_PATCH_ROOM_GROUP_RIGHTS_ERROR,
  SET_PATCH_ROOM_GROUP_RIGHTS_PENDING,
  SET_PATCH_ROOM_PENDING,
  SET_PATCH_ROOM_USER_RIGHTS_ERROR,
  SET_PATCH_ROOM_USER_RIGHTS_PENDING,
  SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT,
  SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING,
  SET_ROOM_PROVIDERS_ERROR,
  SET_ROOM_PROVIDERS_PENDING,
  SET_ROOM_NOTIFICATIONS_PENDING,
  SET_ROOM_NOTIFICATIONS,
  SET_ROOM_NOTIFICATIONS_SEARCH_QUERY,
  SET_ROOM_NOTIFICATIONS_SORT_BY,
  SET_ROOM_NOTIFICATIONS_SORT_DESC,
  RESET_ROOM_NOTIFICATIONS_SORT,
  SET_ROOM_NOTIFICATIONS_ERROR,
  SET_ROOM_RIGHTS,
  SET_ROOM_RIGHTS_ERROR,
  SET_ROOM_RIGHTS_PENDING,
  SET_ROOM_STATS,
  SET_ROOM_STATS_ERROR,
  SET_ROOM_STATS_PENDING,
  SET_ROOM_STORAGE_ADDON_PENDING,
  SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN,
  SET_SIGNATURES,
  SET_SIGNATURES_ERROR,
  SET_SIGNATURES_PENDING,
  SET_UPDATE_ACCESS_TIME_ERROR,
  SET_UPDATE_ACCESS_TIME_PENDING,
  SET_MOVE_ROOM_DIALOG_IS_OPEN,
  SET_MOVE_ROOM_PENDING,
  SET_ROOM_TO_MOVE,
} from './mutation_types'

/**
 * Mutates the signatures to flatten their signatories
 * @param {*} signatures
 */
function flattenSignatureSignatories (signatures) {
  signatures.forEach(signature => {
    signature.signatories = signature.signatories.flat()
  })
}

export const mutations = {
  [SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT] (state, value) {
    state.roomEnvelopesDownloadPreflight = value
  },
  [SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING] (state, value) {
    state.roomEnvelopesDownloadPreflightPending = value
  },
  [SET_LAST_ACCESS_TIMESTAMP] (state, value) {
    state.lastAccessTimestamp = value
  },
  [SET_LAST_MNEMO_ACCESSED] (state, value) {
    state.lastMnemoAccessed = value
  },
  [SET_CLOSING_STARTED_AT] (state, value) {
    state.currentRoom.closingStartedAt = value
  },
  [SET_CLOSING_SCHEDULED_AT] (state, value) {
    state.currentRoom.closingScheduledAt = value
  },
  [SET_CLOSING_SCHEDULED_END_AT] (state, value) {
    state.currentRoom.closingScheduledEndAt = value
  },
  [RESET_CLOSING_DATA] (state) {
    state.currentRoom.inClosing = false
    state.currentRoom.closingStartedAt = null
    state.currentRoom.closingScheduledAt = null
    state.currentRoom.closingScheduledEndAt = null
  },
  [SET_IN_CLOSING] (state, value) {
    state.currentRoom.inClosing = value
  },
  SET_PROCESSING: function (state, value) {
    state.processing = value
  },
  [SET_CURRENT_ROOM]: function (state, room) {
    state.currentRoom = room
  },
  SET_ERROR: function (state, error) {
    state.error = error
  },
  SET_POST_ROOM_PROCESSING: function (state, postRoomProcessing) {
    state.postRoomProcessing = postRoomProcessing
  },
  SET_NEW_ROOM_MODAL_IS_OPEN: function (state, newRoomModalIsOpen) {
    state.newRoomModalIsOpen = newRoomModalIsOpen
  },
  [SET_ROOM_NOTIFICATIONS_PENDING] (state, value) {
    state.roomNotificationsPending = value
  },
  [SET_ROOM_NOTIFICATIONS] (state, value) {
    state.roomNotifications = value
  },
  [SET_ROOM_NOTIFICATIONS_SEARCH_QUERY] (state, value) {
    state.roomNotificationsSearchQuery = value
  },
  [SET_ROOM_NOTIFICATIONS_SORT_BY] (state, value) {
    state.roomNotificationsSort.sortBy = value
  },
  [SET_ROOM_NOTIFICATIONS_SORT_DESC] (state, value) {
    state.roomNotificationsSort.sortDir = value
  },
  [RESET_ROOM_NOTIFICATIONS_SORT] (state) {
    state.roomNotificationsSort = {
      sortBy: '',
      sortDir: '',
    }
  },
  [SET_ROOM_NOTIFICATIONS_ERROR] (state, value) {
    state.roomNotificationsError = value
  },
  [SET_ROOM_PROVIDERS_PENDING] (state, value) {
    state.roomProvidersPending = value
  },
  [SET_ROOM_PROVIDERS_ERROR] (state, value) {
    state.roomProvidersError = value
  },
  [SET_ROOM_STATS] (state, value) {
    state.roomStats = value
  },
  [SET_ROOM_STATS_ERROR] (state, value) {
    state.roomStatsError = value
  },
  [SET_ROOM_STATS_PENDING] (state, value) {
    state.roomStatsPending = value
  },
  [SET_ROOM_STORAGE_ADDON_PENDING] (state, value) {
    state.roomStorageAddonPending = value
  },
  [SET_ROOM_STORAGE_EXCEEDED_DIALOG_IS_OPEN] (state, value) {
    state.roomStorageExceededDialogIsOpen = value
  },
  [RESET_ROOM] (state) {
    state.roomStats = null
  },
  [SET_PATCH_ROOM_PENDING] (state, value) {
    state.patchRoomPending = value
  },
  [SET_PATCH_ROOM_ERROR] (state, value) {
    state.patchRoomError = value
  },
  [SET_CLOSE_ROOM_PENDING] (state, value) {
    state.closeRoomPending = value
  },
  [SET_CLOSE_ROOM_ERROR] (state, value) {
    state.closeRoomError = value
  },
  [SET_SIGNATURES_PENDING] (state, value) {
    state.signaturesPending = value
  },
  [SET_SIGNATURES] (state, value) {
    flattenSignatureSignatories(value.data)
    state.signatures = value
  },
  [SET_SIGNATURES_ERROR] (state, value) {
    state.signaturesError = value
  },
  [SET_MORE_SIGNATURES_PENDING] (state, value) {
    state.moreSignaturesPending = value
  },
  [SET_MORE_SIGNATURES_ERROR] (state, value) {
    state.moreSignaturesError = value
  },
  [SET_MORE_SIGNATURES_PENDING] (state, value) {
    state.moreSignaturesPending = value
  },
  [SET_MORE_SIGNATURES_ERROR] (state, value) {
    state.moreSignaturesError = value
  },
  [ADD_TO_SIGNATURES] (state, value) {
    flattenSignatureSignatories(value)
    state.signatures.data.push(...value)
  },
  [SET_CHASE_USERS_PENDING] (state, value) {
    state.chaseUsersPending = value
  },
  [SET_CHASE_USERS_ERROR] (state, value) {
    state.chaseUsersError = value
  },
  [ADD_CHASE_TIMER_GLOBAL] (state, roomId) {
    const globalChaseTimer = dateToStringDateTime(dayjs().add(5, 'm'))

    if (!state.chaseTimers.global) {
      Vue.set(state.chaseTimers, 'global', {
        [roomId]: globalChaseTimer,
      })
    } else {
      Vue.set(state.chaseTimers.global, roomId, globalChaseTimer)
    }
  },
  [ADD_CHASE_TIMER_ENVELOPE] (state, envelopeId) {
    const envelopeChaseTimer = dateToStringDateTime(dayjs().add(5, 'm'))

    if (!state.chaseTimers.envelope) {
      Vue.set(state.chaseTimers, 'envelope', {
        [envelopeId]: envelopeChaseTimer,
      })
    } else {
      Vue.set(state.chaseTimers.envelope, envelopeId, envelopeChaseTimer)
    }
  },
  [SET_UPDATE_ACCESS_TIME_PENDING] (state, value) {
    state.updateAccessTimePending = value
  },

  [SET_UPDATE_ACCESS_TIME_ERROR] (state, value) {
    state.updateAccessTimeError = value
  },
  [ADD_CHASE_TIMER_SIGNATURE] (state, signatureId) {
    const signatureChaseTimer = dateToStringDateTime(dayjs().add(5, 'm'))

    if (!state.chaseTimers.signature) {
      Vue.set(state.chaseTimers, 'signature', {
        [signatureId]: signatureChaseTimer,
      })
    } else {
      Vue.set(state.chaseTimers.signature, signatureId, signatureChaseTimer)
    }
  },
  [ADD_CHASE_TIMER_VALIDATION] (state, validationId) {
    const validationChaseTimer = dateToStringDateTime(dayjs().add(5, 'm'))

    if (!state.chaseTimers.validation) {
      Vue.set(state.chaseTimers, 'validation', {
        [validationId]: validationChaseTimer,
      })
    } else {
      Vue.set(state.chaseTimers.validation, validationId, validationChaseTimer)
    }
  },
  [SET_ENABLE_DOCUMENTS_LOGS] (state, value) {
    state.currentRoom.enableDocumentsLogs = value
  },
  [SET_ROOM_RIGHTS_PENDING] (state, value) {
    state.roomRightsPending = value
  },
  [SET_ROOM_RIGHTS] (state, value) {
    state.roomRights = value
  },
  [SET_ROOM_RIGHTS_ERROR] (state, value) {
    state.roomRightsError = value
  },
  [SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING] (state, value) {
    state.patchRoomGlobalRightsPending = value
  },
  [SET_PATCH_ROOM_GLOBAL_RIGHTS_ERROR] (state, value) {
    state.patchRoomGlobalRightsError = value
  },
  [SET_PATCH_ROOM_GROUP_RIGHTS_PENDING] (state, value) {
    state.patchRoomGroupRightsPending = value
  },
  [SET_PATCH_ROOM_GROUP_RIGHTS_ERROR] (state, value) {
    state.patchRoomGroupRightsError = value
  },
  [SET_PATCH_ROOM_USER_RIGHTS_PENDING] (state, value) {
    state.patchRoomUserRightsPending = value
  },
  [SET_PATCH_ROOM_USER_RIGHTS_ERROR] (state, value) {
    state.patchRoomUserRightsError = value
  },
  [RESET_ROOM_RIGHTS] (state) {
    state.roomRights = null
  },
  [SET_CHASE_VALIDATOR_PENDING] (state, value) {
    state.chaseValidatorPendingIds.push(value)
  },
  [REMOVE_CHASE_VALIDATOR_PENDING] (state, index) {
    state.chaseValidatorPendingIds.splice(index, 1)
  },
  [SET_CHASE_VALIDATOR_ERROR] (state, value) {
    state.chaseValidatorError = value
  },
  [SET_CURRENT_ROOM_PROVIDERS] (state, providers) {
    Vue.set(state.currentRoom, 'providers', providers)
  },
  [SET_NOTIFY_PM_NO_MORE_SPACE_PENDING] (state, value) {
    state.notifyPmNoMoreSpacePending = value
  },
  [SET_NOTIFY_PM_PLAN_DATAROOM_FULL_PENDING] (state, value) {
    state.notifyPmPlanDRFullPending = value
  },
  [SET_MOVE_ROOM_DIALOG_IS_OPEN] (state, value) {
    state.moveRoomDialogIsOpen = value
  },
  [SET_MOVE_ROOM_PENDING] (state, value) {
    state.moveRoomPending = value
  },
  [SET_ROOM_TO_MOVE] (state, roomToMove) {
    state.roomToMove = roomToMove
  },
}
