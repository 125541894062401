import axios from 'axios'
export default {
  getGroups (mnemo) {
    return axios.get(`room/${mnemo}/groups`)
  },
  postGroup (mnemo, groupName) {
    return axios.post(`room/${mnemo}/group`, { name: groupName })
  },
  deleteGroup (mnemo, groupId) {
    return axios.delete(`room/${mnemo}/group/${groupId}`)
  },
  patchHideGroups (mnemo, data) {
    return axios.patch(`room/${mnemo}/hideGroups`, { option: data })
  },
  patchGroup (mnemo, groupId, data) {
    return axios.patch(`room/${mnemo}/group/${groupId}`, data)
  },
  postMember (mnemo, data, params) {
    return axios.post(`room/${mnemo}/userAccess`, data, { params })
  },
  deleteMember (mnemo, memberId, params) {
    return axios.delete(`room/${mnemo}/userAccess/${memberId}`, { params })
  },
  chaseMember (mnemo, memberId) {
    return axios.post(`room/${mnemo}/userAccess/${memberId}/chase`)
  },
  postMemberFromTeam (mnemo, data) {
    return axios.post(`/room/${mnemo}/subscription-team/access`, data)
  },
  patchUserAccess (mnemo, memberId, data) {
    return axios.patch(`room/${mnemo}/userAccess/${memberId}`, data)
  },
  postMassImport (mnemo, data) {
    return axios.post(`room/${mnemo}/massInvite`, data)
  },
  postUserMissedAccess (mnemo, data) {
    return axios.post(`room/${mnemo}/userMissedAccess`, data)
  },
  getUserProfile (mnemo, userId) {
    return axios.get(`room/${mnemo}/user/${userId}`)
  },
  patchIsHiddenPm (mnemo, userId, data) {
    return axios.patch(`room/${mnemo}/userAccess/${userId}/hidePm`, data)
  },
  getPreviewInvitation (mnemo, invitationData) {
    return axios.post(`/room/${mnemo}/invitationPreview`, invitationData)
  },
}
