<template>
  <v-navigation-drawer
    v-model="isPaneOpened"
    app
    temporary
    right
    :width="560"
  >
    <div v-if="documentToSummarize" class="pane-wrapper">
      <div class="pane-header">
        <v-img
          src="/img/ai_assistant_ico.svg"
          :width="24"
          :height="24"
        />

        <div>
          <app-text variant="large-bold">
            {{ t('paneTitle') }}
          </app-text>
          <app-text as="span"  variant="small-regular">
            {{ t('subTitle') }}

            <AppInfoTooltip bottom>
              {{ t('subTitleTooltip') }}
            </AppInfoTooltip>
          </app-text>
        </div>

        <app-button type="icon" @click="closePane">
          <app-icon icon-name="xmark" icon-weight="far" />
        </app-button>
      </div>

      <v-divider />

      <div class="selected-document">
        <DocumentSelection :selected-documents="[documentToSummarize]" />
      </div>

      <template v-if="shouldDisplayLoadingState">
        <DocumentsAISummaryLoadingState class="loading-state" />
      </template>
      <template v-else-if="shouldDisplayLoadingError">
        <div class="loading-error">
          <app-text variant="small-regular">
            {{ t('loadingErrorText') }}
          </app-text>
          <app-button variant="project" @click="loadSummariesForCurrentDocument">
            <template #left-icon>
              <app-icon icon-name="rotate-right" icon-weight="fas" />
            </template>
            {{ $t('common.retry') }}
          </app-button>
        </div>
      </template>
      <template v-else>
        <div class="summaries">
          <template v-if="shouldDisplayEmptyState">
            <AppChatMessageSeparator class="mb-8" />

            <div class="empty-state">
              <v-img
                src="/img/ai_assistant_logo.svg"
                :width="94"
                :height="94"
              />

              <app-text variant="large-bold">
                {{ t('emptyStateText') }}
              </app-text>
            </div>
          </template>
          <template v-else>
            <template v-for="(groupedSummaries, date) in groupedByDateSummaries">
              <AppChatMessageSeparator :key="date" :date="date" class="mb-8" />

              <DocumentsAISummaryChatMessage
                v-for="summary in groupedSummaries"
                :key="summary.id"
                class="summary"
                :message-date="summary.createdAt"
              >
                {{ summary.summary }}
              </DocumentsAISummaryChatMessage>
            </template>

            <DocumentsAISummaryRequestChatMessage
              v-for="requestDate in summaryRequestsDates"
              :key="requestDate"
              :request-date="requestDate"
              :document-id="documentToSummarize.id"
              class="summary"
              @error="onSummaryRequestError"
              @success="onSummaryRequestSuccess"
            />
          </template>
        </div>

        <div v-if="shouldDisplayActionButton" class="action-buttons">
          <DocumentsAISummaryRequestButton
            v-if="summaryRequest === 'error'"
            :disabled="summaryRequest === 'pending'"
            @click="retryDocumentSummaryRequest"
          >
            <template #left-icon>
              <app-icon icon-name="rotate-right" icon-weight="fas" />
            </template>
            {{ t('retryResumingDocument') }}
          </DocumentsAISummaryRequestButton>
          <DocumentsAISummaryRequestButton
            v-else
            :disabled="summaryRequest === 'pending'"
            @click="requestDocumentSummary"
          >
            <template #left-icon>
              <app-icon icon-name="book" icon-weight="far" />
            </template>
            {{ isThereAtLeastOneSummary ? t('resumeDocumentAgain') : t('resumeDocument') }}
          </DocumentsAISummaryRequestButton>
        </div>
      </template>

      <v-divider />

      <div class="pane-footer">
        <app-button
          type="outlined"
          variant="neutral"
          @click="closePane"
        >
          {{ $t('common.finish') }}
        </app-button>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import dayjs from 'dayjs'
import { groupBy, sortBy } from 'lodash-es'
import { defineComponent } from 'vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

import AppChatMessageSeparator from '@/common/app-chat/AppChatMessageSeparator.vue'
import AppInfoTooltip from '@/common/AppInfoTooltip.vue'
import DocumentSelection from '@/common/document-selection/DocumentSelection.vue'
import { extractOnlyDateFromDateTime } from '@/common/utils/dates'
import DocumentsAISummaryChatMessage from '@/project/documents/ai-summary/DocumentsAISummaryChatMessage.vue'
import DocumentsAISummaryLoadingState from '@/project/documents/ai-summary/DocumentsAISummaryLoadingState.vue'
import DocumentsAISummaryRequestButton from '@/project/documents/ai-summary/DocumentsAISummaryRequestButton.vue'
import DocumentsAISummaryRequestChatMessage from '@/project/documents/ai-summary/DocumentsAISummaryRequestChatMessage.vue'
import documentsService from '@/services/documents.service'

export default defineComponent({
  name: 'DocumentsAISummaryPane.vue',
  emits: ['close'],
  components: {
    DocumentsAISummaryLoadingState,
    AppChatMessageSeparator,
    AppInfoTooltip,
    DocumentSelection,
    DocumentsAISummaryChatMessage,
    DocumentsAISummaryRequestButton,
    DocumentsAISummaryRequestChatMessage,
  },
  data () {
    return {
      // TODO : introduce type for query state ('idle' | 'pending' | 'error') on Vue 3 migration
      summariesLoading: 'idle',
      summaryRequest: 'idle',
      summaryRequestsDates: [],
      summaries: [],
    }
  },
  watch: {
    async documentToSummarize (value) {
      if (value) {
        await this.loadSummariesForCurrentDocument()
      }
    },
    async summaryRequest () {
      if (this.isPaneOpened) {
        await this.scrollToLastSummary({ behavior: 'smooth' })
      }
    },
  },
  computed: {
    ...mapState('documentsAISummary', ['documentToSummarize']),
    ...mapGetters('room', ['roomMnemo']),
    groupedByDateSummaries () {
      return groupBy(
        sortBy(this.summaries, ['createdAt']),
        ({ createdAt }) => extractOnlyDateFromDateTime(createdAt),
      )
    },
    isThereAtLeastOneSummary () {
      return this.summaries.length > 0 ||
        this.summaryRequestsDates.length > 1 ||
        (this.summaryRequestsDates.length === 1 && this.summaryRequest === 'idle')
    },
    isPaneOpened: {
      get () {
        return !!this.documentToSummarize
      },
      set (isOpened) {
        if (!isOpened) {
          this.closePane()
        }
      },
    },
    shouldDisplayActionButton () {
      return this.summariesLoading === 'idle'
    },
    shouldDisplayEmptyState () {
      return this.summaries.length === 0 && this.summaryRequestsDates.length === 0
    },
    shouldDisplayLoadingError () {
      return this.summariesLoading === 'error'
    },
    shouldDisplayLoadingState () {
      return this.summariesLoading === 'pending'
    },
  },
  methods: {
    ...mapMutations('documentsAISummary', ['SET_DOCUMENT_TO_SUMMARIZE_WITH_AI']),
    closePane () {
      this.SET_DOCUMENT_TO_SUMMARIZE_WITH_AI(null)
      this.setInitialState()
    },
    async loadSummariesForCurrentDocument () {
      this.summariesLoading = 'pending'

      try {
        const { data } = await documentsService.getDocumentAISummaries(this.roomMnemo, this.documentToSummarize.id)
        this.summaries.push(...data)
        this.summariesLoading = 'idle'
        await this.scrollToLastSummary({ behavior: 'instant', block: 'end' })
      } catch (error) {
        console.error(error)
        this.summariesLoading = 'error'
      }
    },
    onSummaryRequestError () {
      this.summaryRequest = 'error'
    },
    onSummaryRequestSuccess () {
      this.summaryRequest = 'idle'
    },
    requestDocumentSummary () {
      this.summaryRequestsDates.push(dayjs().toISOString())
      this.summaryRequest = 'pending'
    },
    retryDocumentSummaryRequest () {
      this.summaryRequestsDates.pop()
      this.requestDocumentSummary()
    },
    async scrollToLastSummary (scrollOptions) {
      await this.$nextTick()
      const summariesHtmlElements = document.getElementsByClassName('summary')

      if (summariesHtmlElements.length > 0) {
        const lastSummary = summariesHtmlElements[summariesHtmlElements.length - 1]
        lastSummary.scrollIntoView(scrollOptions)
      }
    },
    setInitialState () {
      this.summariesLoading = 'idle'
      this.summaryRequest = 'idle'
      this.summaryRequestsDates = []
      this.summaries = []
    },
    t (key) {
      return this.$t(`project.documents.pane.DocumentsAISummaryPane.${key}`)
    },
  },
})
</script>

<style scoped>
.pane-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.pane-header,
.selected-document,
.loading-state,
.summaries,
.action-buttons,
.pane-footer {
  padding: 24px;
}

.pane-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 16px;
  align-items: center;
  padding-block: 12px;
  padding-inline: 24px;
}

.loading-state,
.loading-error,
.summaries {
  flex: 1;
}

.summaries {
  padding-block: 4px;
  overflow-y: auto;
}

.loading-error {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.loading-state {
  padding-top: 0;
}

.summary:not(:last-child) {
  margin-bottom: 24px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.pane-footer {
  text-align: right;

  button {
    margin-left: auto;
  }
}
</style>
