<template>
  <AppDialog :is-open="isDialogOpened"
             size="xxxl"
             :retain-focus="false"
             :ok-text="okTitle"
             :ok-loading="postRoomProcessing"
             :ok-disabled="okDisabled"
             :cancel-disabled="postRoomProcessing"
             @ok="onOk"
             @cancel="closeDialog"
  >
    <template #title>{{ $t('projects.dialogs.ProjectsNewProjectDialog.projectCreation') }}</template>
    <template #body>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            {{ $t('projects.dialogs.ProjectsNewProjectDialog.projectType') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            step="2"
          >
            {{ $t('projects.dialogs.ProjectsNewProjectDialog.projectSettings') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content class="pa-0" step="1">
            <v-row class="my-4">
              <v-col v-for="roomType in projectOnlyRoomTypes"
                     :key="`roomType-${roomType.id}`"
                     cols="12"
                     md="6"
                     lg="4"
              >
                <AppCard class="RoomType-card"
                         :class="{'RoomType-card--active': selectedRoomType && selectedRoomType.id === roomType.id}"
                         hover
                         @click.native="selectedRoomType = roomType"
                >
                  <div class="text-h3">
                    <span>
                      {{ $t(`projects.dialogs.ProjectsNewProjectDialog.roomTypeName.${roomType.label}`) }}
                    </span>
                  </div>
                  <div class="my-3">
                    <font-awesome-icon :icon="['fal', 'credit-card']"
                                       class="mr-2"
                    ></font-awesome-icon>
                    <span>
                        {{ $t('projects.dialogs.ProjectsNewProjectDialog.pricePerMonth', { price: roomType.pricePerMonth }) }}
                      </span>
                  </div>
                  <div class="my-3">
                    <font-awesome-icon :icon="['fal', 'users']"
                                       class="mr-2"
                    ></font-awesome-icon>
                    <span>
                        {{ $tc('projects.dialogs.ProjectsNewProjectDialog.limUsers', roomType.limUsers) }}
                      </span>
                  </div>
                  <div v-if="roomType.limEnvelopes > 0" class="my-3">
                    <font-awesome-icon :icon="['fal', 'file-signature']"
                                       class="mr-2"
                    ></font-awesome-icon>
                    <span>
                        {{ $t('projects.dialogs.ProjectsNewProjectDialog.limEnvelopes', { count: roomType.limEnvelopes}) }}
                      </span>
                  </div>
                  <div v-if="roomType.limEnvelopes > 0"
                       class="my-3"
                  >
                    {{ $t('projects.dialogs.ProjectsNewProjectDialog.aboveAllowance') }}
                  </div>
                  <div v-if="roomType.priceDocSimple > 0" class="my-3">
                    <font-awesome-icon :icon="['fal', roomType.limEnvelopes > 0 ? 'file-plus' : 'file-signature']"
                                       class="mr-2"
                    ></font-awesome-icon>
                    <span>
                        {{ $t('projects.dialogs.ProjectsNewProjectDialog.priceAdditionalDocSimple', { count: roomType.priceDocSimple}) }}
                      </span>
                  </div>
                  <div v-if="roomType.priceDocAdvanced > 0" class="my-3">
                    <font-awesome-icon :icon="['fal', roomType.limEnvelopes > 0 ? 'file-plus' : 'file-signature']"
                                       class="mr-2"
                    ></font-awesome-icon>
                    <span>
                        {{ $t('projects.dialogs.ProjectsNewProjectDialog.priceAdditionalDocAdvanced', { count: roomType.priceDocAdvanced}) }}
                      </span>
                  </div>
                </AppCard>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-row>
              <v-col>
                <Alert v-model="alert"
                >
                  {{ $t('projects.dialogs.ProjectsNewProjectDialog.optionsEditableLater') }}
                </Alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <AppTextField v-model.trim="$v.room.name.$model"
                              class="required"
                              :label="$t('projects.dialogs.ProjectsNewProjectDialog.projectName')"
                              :error-messages="roomNameError"
                              maxLength="100"
                />
              </v-col>
              <v-col cols="12" md="6">
                <AppTextField v-model.trim="room.cmRef"
                              :label="$t('projects.dialogs.ProjectsNewProjectDialog.customerRef')"
                              maxLength="100"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <AppDatePicker ref="picker"
                               v-model="room.expClosingDate"
                               :label="$t('projects.dialogs.ProjectsNewProjectDialog.plannedCompletionDate')"
                               class="w-100"
                               min="2000-01-01"
                />
              </v-col>
              <v-col cols="12" md="6">
                <template v-if="subscriptionLabelsPending">
                  <v-skeleton-loader type="text"/>
                </template>
                <template v-else>
                  <AppSelect v-if="computedRoomsLabels"
                             v-model="room.labelId"
                             :label="$t('projects.dialogs.ProjectsNewProjectDialog.status')"
                             :items="computedRoomsLabels"
                             item-value="id"
                  >
                    <template #item="{item}">
                      <div v-if="item.id === null">
                        {{ item.text }}
                      </div>
                      <AppProjectLabel v-else :label="item"/>
                    </template>
                    <template #selection="{ item }">
                      <div v-if="item.id === null">
                        {{ item.text }}
                      </div>
                      <AppProjectLabel v-else :label="item"/>
                    </template>
                  </AppSelect>
                </template>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  v-model="room.optHideGroups"
                  hide-details
                  class="ProjectsDialogNew-checkbox mt-0 mb-2 pt-0"
                  :label="$t('projects.dialogs.ProjectsNewProjectDialog.hideGroups')"
                  color="success"
                />
                <v-switch
                  v-model="room.optAllowGuestBibles"
                  hide-details
                  class="ProjectsDialogNew-checkbox mt-0 pt-0"
                  :label="$t('projects.dialogs.ProjectsNewProjectDialog.optAllowGuestBibles')"
                  color="success"
                />
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <template #footer-infos>
      <AppButton v-if="step === 2"
                 color="white"
                 class="mr-auto"
                 @click="onPrevious"
      >
        {{$t('common.previous')}}
      </AppButton>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'vuex'

import Alert from '@/common/alerts/Alert'
import AppCard from '@/common/AppCard'
import AppDatePicker from '@/common/AppDatePicker'
import AppProjectLabel from '@/common/AppProjectLabel'
import AppSelect from '@/common/AppSelect'
import AppTextField from '@/common/AppTextField'
import AppButton from '@/common/buttons/AppButton'
import AppDialog from '@/common/dialogs/AppDialog'
import { RoomToPost } from '@/models/room.model'
import { ENQUEUE_SNACKBAR } from '@/store/mutation_types'

import { ROOM_TYPES } from '../../common/utils/roomTypes'
import { dialogMixin } from '../../mixins/dialogMixin'
import { GET_SUBSCRIPTION_LABELS } from '../../store/modules/rooms/action_types'
export default {
  name: 'ProjectsNewProjectDialog',
  components: {
    AppCard,
    AppDialog,
    AppButton,
    Alert,
    AppTextField,
    AppDatePicker,
    AppSelect,
    AppProjectLabel,
  },
  mixins: [validationMixin, dialogMixin],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  validations: {
    room: {
      name: {
        required,
        maxLength: maxLength(100),
      },
    },
  },
  data () {
    return {
      room: new RoomToPost(),
      step: 1,
      selectedRoomType: null,
      alert: true,
    }
  },
  computed: {
    ...mapState('room', ['postRoomProcessing']),
    ...mapState('rooms', ['roomTypes', 'subscriptionLabels', 'subscriptionLabelsPending']),
    okTitle () {
      return this.step === 1 ? this.$t('common.next') : this.$t('common.confirm')
    },
    okDisabled () {
      if (this.step === 1) {
        return this.postRoomProcessing || !this.selectedRoomType
      }
      return false
    },
    roomNameError () {
      const errors = []
      if (!this.$v.room.name.$dirty) return errors
      !this.$v.room.name.required && errors.push(this.$t('common.validations.fieldRequired', { fieldName: this.$t('projects.dialogs.ProjectsNewProjectDialog.projectName') }))
      !this.$v.room.name.maxLength && errors.push(this.$t('common.validations.maxLength', { fieldName: this.$t('projects.dialogs.ProjectsNewProjectDialog.projectName') }))
      return errors
    },
    computedRoomsLabels () {
      const labels = []
      labels.push({
        id: null,
        text: this.$t('projects.dialogs.ProjectsNewProjectDialog.noLabel'),
      })
      if (this.subscriptionLabels) {
        labels.push(...this.subscriptionLabels)
      }
      return labels
    },
    projectOnlyRoomTypes () {
      return this.roomTypes.filter(
        (roomType) =>
          roomType.type !== ROOM_TYPES.DATAROOM &&
          roomType.type !== ROOM_TYPES.DATAROOM_UNLIMITED,
      )
    },
  },
  async mounted () {
    try {
      await this.GET_SUBSCRIPTION_LABELS(
        {
          params: {
            isDataroom: false,
          },
        },
      )
    } catch (e) {
      this.$store.commit(ENQUEUE_SNACKBAR, {
        color: 'error',
        message: this.$t('projects.dialogs.ProjectsNewProjectDialog.getRoomsLabelsError'),
      })
    }
  },
  methods: {
    ...mapActions('rooms', [GET_SUBSCRIPTION_LABELS]),
    ...mapActions('room', ['postRoom']),
    onOk () {
      if (this.step === 1 && this.selectedRoomType) {
        this.step++
      } else if (this.step === 2) {
        this.preparePostRoom()
      }
    },
    onPrevious () {
      if (this.step === 2) {
        this.step--
      }
    },
    async preparePostRoom () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.room.type = this.selectedRoomType.id
        try {
          await this.postRoom(this.room)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'success',
            message: this.$t('projects.dialogs.ProjectsNewProjectDialog.postRoomSuccess'),
          })
        } catch (error) {
          console.error(error)
          this.$store.commit(ENQUEUE_SNACKBAR, {
            color: 'error',
            message: this.$t('projects.dialogs.ProjectsNewProjectDialog.postRoomError'),
          })
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .RoomType-card {
  background-color: #f5f5f5;
  border-radius: 6px !important;
  height: 100%;
  border: 1px solid transparent;
  cursor: pointer;
  &--active {
    border-color: var(--v-primary-base);
  }
}
</style>
