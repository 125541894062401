import axios from 'axios'

export default {
  getDocumentsById: function (mnemo, id, queryObject = {}) {
    return axios.get(`/room/${mnemo}/folder/${id}`, { params: queryObject })
  },
  shareFilders: function (mnemo, data) {
    return axios.patch(`/room/${mnemo}/filders/rights`, data)
  },
  duplicateFilders: function (mnemo, data) {
    return axios.post(`/room/${mnemo}/duplicateFiles`, data)
  },
  moveFilders: function (mnemo, data) {
    return axios.patch(`/room/${mnemo}/filders`, data)
  },
  deleteFilders: function (mnemo, data) {
    return axios.delete(`/room/${mnemo}/filders`, { data: data })
  },
  createNewFolder: function (mnemo, data) {
    return axios.post(`/room/${mnemo}/folder`, data)
  },
  getDocumentVersions: function (mnemo, documentId) {
    return axios.get(`/room/${mnemo}/file/${documentId}/version`)
  },
  postNewVersion: function (mnemo, documentId, data) {
    return axios.post(
      `/room/${mnemo}/file/${documentId}/version`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  },
  getSearchResults: function (mnemo, searchQuery) {
    return axios.get(`/room/${mnemo}/filders?keywords=${searchQuery}&markRecent=true`)
  },
  getNewDocuments: function (mnemo) {
    return axios.get(`/room/${mnemo}/files/new`)
  },
  postFile: function (file) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelToken = source
    const data = new FormData()
    data.append('file', file.fileObject, file.fileObject.name)
    data.append('parentId', file.parentId)
    const config = {
      onUploadProgress: function (progressEvent) {
        file.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      },
      cancelToken: source.token,
    }
    return axios.post(`/room/${file.roomMnemo}/file`, data, config)
  },
  postHiddenFile: function (file, mnemo, onUploadProgress) {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    file.cancelToken = source
    const data = new FormData()
    data.append('fileSelect', file.objectToPost.fileRaw, file.name)
    const config = {
      onUploadProgress: function (progressEvent) {
        const uploadProgression = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        file.progress = uploadProgression
        onUploadProgress?.(uploadProgression)
      },
      cancelToken: source.token,
    }
    return axios.post(`/room/${mnemo}/file/hidden`, data, config)
  },
  postDownload: function (mnemo, data) {
    return axios.post(`/room/${mnemo}/downloads/files`, data, { responseType: 'blob' })
  },
  renameDocument: function (mnemo, documentToRename, newDocumentName) {
    return axios.patch(`/room/${mnemo}/${documentToRename.type}/${documentToRename.id}`, documentToRename.type === 'file' ? { basename: newDocumentName } : { name: newDocumentName })
  },
  patchNumbering: function (mnemo, value) {
    return axios.patch(`/room/${mnemo}/documentsNumbering`, { documentsNumbering: value })
  },
  getNumbering (mnemo) {
    return axios.get(`/room/${mnemo}/documentsNumbering`)
  },
  postFildersFromIManage (mnemo, data, token) {
    return axios.post(`/room/${mnemo}/imanageImport`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  postFildersToIManage (mnemo, data, token) {
    return axios.post(`/room/${mnemo}/imanageExport`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  postFildersAsVersionToIManage (mnemo, data, token) {
    return axios.post(`/room/${mnemo}/imanageExport/version`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  imanageHiddenFileImport (mnemo, data, token) {
    return axios.post(`/room/${mnemo}/imanageHiddenFileImport`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  updateAccessTime (mnemo) {
    return axios.post(`/room/${mnemo}/updateAccessDate/documents`)
  },
  postVersionFromImanage (mnemo, data, token) {
    return axios.post(`/room/${mnemo}/imanageImport/version`, data, { headers: { 'X-CLOSD-IMCONFIG': token } })
  },
  patchDocumentNumbering (mnemo, documentType, documentId, numbering) {
    return axios.patch(`/room/${mnemo}/${documentType}/${documentId}`, { numbering })
  },
  postMassFolders (mnemo, data) {
    return axios.post(`room/${mnemo}/folders/import`, data)
  },
  getFolderPath (mnemo, folderId) {
    return axios.get(`room/${mnemo}/folder/${folderId}/path`)
  },
  shareDocumentsIndexByEmail (mnemo, data) {
    return axios.post(`/room/${mnemo}/share-export`, data)
  },
  requestAuthorizationBible (mnemo) {
    return axios.post(`/room/${mnemo}/request/right/bibles/generate`)
  },
  getFoldersTree (mnemo) {
    return axios.get(`/room/${mnemo}/documents/tree`)
  },
  checkWopiLockedFiles (mnemo, filesId) {
    return axios.post(`/room/${mnemo}/file/checkWopiLock`, { filesId })
  },
  requestDocumentAISummary (mnemo, documentId) {
    return axios.get(`/room/${mnemo}/file/${documentId}/summary`)
  },
  getDocumentAISummaries (mnemo, documentId) {
    return axios.get(`/room/${mnemo}/file/${documentId}/summaries`)
  },
}
