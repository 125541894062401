<template>
  <div class="d-flex justify-end text-no-wrap">
    <app-button v-if="withQuestionsLink"
                icon
                @click="navigateToDocumentQuestions"
    >
      <font-awesome-icon :icon="['far', 'comments-alt']"></font-awesome-icon>
      <span v-if="document.questionsIds.length > 1"
            class="ml-1 font-weight-bold"
      >
        {{ document.questionsIds.length }}
      </span>
    </app-button>

    <template v-if="document.displayWatermarked">
      <AppTooltip top>
        <template #activator="{attrs, on}">
          <app-button type="icon"
                     v-bind="attrs"
                     v-on="on"
                     @click="openWatermarkedDialog"
          >
            <font-awesome-icon :icon="['fas', 'eye']"></font-awesome-icon>
          </app-button>
        </template>
        <span>{{ $t('project.documents.table.DocumentsTableItemOptions.seeWatermarkedDocument') }}</span>
      </AppTooltip>
    </template>
    <template v-else>
      <OfficeOnlineEditionButton v-if="isOfficeEditionButtonShowed" :document="document"/>
      <AppTooltip top>
        <template #activator="{attrs, on}">
          <AppDownloadFile v-if="document.type === 'file'" :link="fileLink">
            <template #default="{ submitForm }">
              <app-button type="icon"
                         v-bind="attrs"
                         v-on="on"
                         @click="submitForm"
              >
                <app-icon icon-name="download" icon-weight="fas" size="small"/>
              </app-button>
            </template>
          </AppDownloadFile>
        </template>
        <span>{{ $t('common.download') }}</span>
      </AppTooltip>
    </template>

    <DocumentsTableItemMenu v-if="!document.specialFolder"
                            :document="document"
                            @openInFolder="openInFolder"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import AppDownloadFile from '@/common/AppDownloadFile.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import OfficeOnlineEditionButton from '@/common/office/OfficeOnlineEditionButton.vue'
import { roomFileDownloadUrl } from '@/common/utils/files'
import { Filter } from '@/models/filter.model'
import DocumentsTableItemMenu from '@/project/documents/table/DocumentsTableItemMenu.vue'
import router, { QUESTION_ANSWER_ITEM_ROUTE_NAME, QUESTION_ANSWER_LIST_ROUTE_NAME } from '@/router'
import { SET_WATERMARKED_DIALOG_DATA } from '@/store/modules/files/mutation_types'
import { QuestionAnswerMutationTypes } from '@/store/modules/question-answer/mutation_types'

export default {
  name: 'DocumentsTableItemOptions',
  components: {
    AppDownloadFile,
    AppTooltip,
    DocumentsTableItemMenu,
    OfficeOnlineEditionButton,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('room', ['roomMnemo', 'isWopiEnabled', 'isCurrentUserPm']),
    fileLink () {
      return roomFileDownloadUrl(this.document.id, this.roomMnemo)
    },
    withQuestionsLink () {
      return this.document.type === 'file' && this.document.questionsIds?.length > 0
    },
    isOfficeEditionButtonShowed () {
      return this.isWopiEnabled && this.document.canEdit && this.isCurrentUserPm
    },
  },
  methods: {
    ...mapMutations('questionAnswer', [QuestionAnswerMutationTypes.SET_FILTERS]),
    ...mapMutations('files', [SET_WATERMARKED_DIALOG_DATA]),
    navigateToDocumentQuestions () {
      if (this.document.questionsIds?.length === 1) {
        router.push({
          name: QUESTION_ANSWER_ITEM_ROUTE_NAME,
          params: { questionId: this.document.questionsIds[0] },
        })
      } else {
        const referenceFilter = new Filter('references', this.$t('project.documents.table.DocumentsTableItemOptions.referenceFilter'), this.document.id, this.document.basename)
        this.SET_FILTERS([referenceFilter])

        router.push({ name: QUESTION_ANSWER_LIST_ROUTE_NAME })
      }
    },
    openInFolder () {
      this.$emit('openInFolder')
    },
    openWatermarkedDialog () {
      this.SET_WATERMARKED_DIALOG_DATA({
        file: this.document,
        isOpen: true,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.DocumentTableItemOptions-iconContainer {
  width: 36px
}
</style>
