<template>
  <div class="add-member-type">
    <v-radio-group
      v-model="memberType"
      hide-details
      active-class="member-type-card--active"
      class="member-type-container pa-1"
      required
      row
    >
      <div v-for="option in memberTypeOptionsValues"
           :key="`option-${option}`"
           :class="`memberType__card-${option}`"
           class="d-flex flex-column"
      >
        <div :value="option"
             :class="{'member-type-card--active': option === memberType}"
             class="member-type-card clickable"
             @click="memberType = option"
        >
          <h4 class="text-center">
            {{ $t(`project.members.memberTypesOptions.${option}`) }}
          </h4>
        </div>
      </div>
    </v-radio-group>
  </div>
</template>

<script>
  import { MEMBER_TYPE_OPTIONS } from './types.ts'

  export default {
    name: 'AddMemberType',
    props: {
      defaultMemberType: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        memberType: this.defaultMemberType,
      }
    },
    computed: {
      memberTypeOptionsValues () {
        return Object.values(MEMBER_TYPE_OPTIONS)
      },
    },
    watch: {
      memberType (value) {
        this.$emit('change-add-member-type', value)
      },
    },
  }
</script>

<style lang="scss">
  .add-member-type {
    border-radius: 8px;
    background-color: var(--v-grey-lighten5);

    .member-type-card {
      padding: 10px;
    }

    .member-type-container {
      margin: 0;

      .v-input--radio-group__input {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
      }

      .member-type-card {
        border-radius: 8px;
        color: var(--v-grey-base);

        &--active {
          color: var(--v-grey-darken3);
          background-color: var(--v-white-base);
        }
      }

      @media screen and (min-width: 1024px) {
        .v-input--radio-group__input {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
</style>
