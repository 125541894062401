<template>
  <Pane v-model="documentsVersioningPane.isOpen"
        temporary
        :tabs="tabs"
        :current-tab.sync="tab"
        @input="onClosePane"
  >
    <template #content-manage>
      <DocumentVersioning :loading="postVersionFromImanagePending || versionsLoading"
                          :selected-documents="documentsVersioningPane.documents"
                          @add-version="onAddVersion"
                          @add-version-from-imanage="onAddVersionFromImanage"
                          @close-pane="onClosePane"
      />
    </template>
  </Pane>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DocumentVersioning from '@/common/document-versioning/DocumentVersioning'
import Pane from '@/common/Pane'
import {
  ADD_VERSION,
  CHANGE_DOCUMENTS_VERSIONING_PANE_TAB,
  CLOSE_DOCUMENTS_VERSIONING_PANE,
  DESELECT_ALL_DOCUMENTS,
  GET_DOCUMENTS_BY_ID,
  POST_VERSION_FROM_IMANAGE,
} from '@/store/modules/documents/action_types'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR } from '@/store/mutation_types'

const TRANSLATION_KEY_PREFIX = 'project.documents.pane.DocumentsVersioningPane'

export default {
  name: 'DocumentsVersioningPane',
  components: {
    DocumentVersioning,
    Pane,
  },
  props: {
    mnemo: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('documents', ['documentsVersioningPane', 'numberingEnabled', 'postVersionFromImanagePending', 'versionsLoading']),
    tab: {
      get () {
        return this.documentsVersioningPane.tab
      },
      set (val) {
        this.CHANGE_DOCUMENTS_VERSIONING_PANE_TAB(val)
      },
    },
    tabs () {
      const tabs = []
      if (this.documentsVersioningPane.documents.length === 1 && this.documentsVersioningPane.documents[0].type === 'file') {
        tabs.push({
          title: this.$t(`${TRANSLATION_KEY_PREFIX}.tabManage`),
          id: 'manage',
        })
      }
      return tabs
    },
  },
  methods: {
    ...mapActions('documents', [
      ADD_VERSION,
      CHANGE_DOCUMENTS_VERSIONING_PANE_TAB,
      CLOSE_DOCUMENTS_VERSIONING_PANE,
      DESELECT_ALL_DOCUMENTS,
      GET_DOCUMENTS_BY_ID,
      POST_VERSION_FROM_IMANAGE,
    ]),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR]),
    onAddVersion (data) {
      this.ADD_VERSION(data)
    },
    async onAddVersionFromImanage (data) {
      try {
        await this.POST_VERSION_FROM_IMANAGE({
          mnemo: this.mnemo,
          data: data,
        })

        this.ENQUEUE_SUCCESS_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.postVersionFromImanageSuccess`))

        this.GET_DOCUMENTS_BY_ID({ queryObject: { markRecent: true } })

        this.onClosePane()
      } catch (error) {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t(`${TRANSLATION_KEY_PREFIX}.postVersionFromImanageError`))
      }
    },
    onClosePane (value) {
      if (!value) {
        this.DESELECT_ALL_DOCUMENTS()
        this.CLOSE_DOCUMENTS_VERSIONING_PANE()
      }
    },
  },
}
</script>
