import {
  GET_SIGNING_VIEW,
} from './action_types'
import {
  SET_SIGNING_VIEW,
  SET_SIGNING_VIEW_PENDING,
  SET_SIGNING_VIEW_ERROR,
} from './mutation_types'
import router, { CERTEUROPE_SIGN_ROUTE_NAME } from '../../../router'
import signatureService from '../../../services/signature.service'

export const actions = {
  async [GET_SIGNING_VIEW] ({ commit }, { mnemo, envelopeId, token = '', withoutApiToken = false }) {
    commit(SET_SIGNING_VIEW_PENDING, true)
    commit(SET_SIGNING_VIEW_ERROR, null)
    try {
      const { data } = await signatureService.getSigningView(mnemo, envelopeId, token, withoutApiToken)
      commit(SET_SIGNING_VIEW, data)
      if (data.url) {
        if (data.provider === 'oo') {
          router.push({
            name: CERTEUROPE_SIGN_ROUTE_NAME,
            params: {
              mnemo,
              envelopeId,
            },
          })
        } else {
          window.location = data.url
        }
      }
    } catch (error) {
      commit(SET_SIGNING_VIEW_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNING_VIEW_PENDING, false)
    }
  },
}
