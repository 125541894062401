<template>
  <div class="closd-filders-selector pa-6">
    <v-card flat :disabled="disabled" class="w-100 mb-5">
      <v-tabs v-model="currentTab" fixed-tabs>
        <v-tab href="#documents">{{ $t("common.filders.ClosdFildersSelector.documentsTabTitle") }}</v-tab>
        <v-tab href="#checklist">{{ $t("common.filders.ClosdFildersSelector.checklistTabTitle") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab" class="closd-filders-selector__tabs-items">
        <v-tab-item value="documents">
          <template v-if="documentsAreLoading">
            <div class="text-center">
              <v-progress-circular
                color="primary"
                class="my-14"
                size="48"
                indeterminate
              />
            </div>
          </template>
          <template v-else>
            <template v-if="documentsRoot && documentsRoot.children.length !== 0">
              <AppClosdFildersTree
                id="documentsPicker"
                v-model="selectedDocuments"
                :rootFolder="documentsRoot"
                :multiple="multiple"
                :files-only="filesOnly"
                disable-folder-without-file
                :disable-files-too-large-to-be-signed="true"
                :maxSignableSize="maxSignableSize"
              >
                <template v-slot:label="{ item }">
                  <template v-if="item.disabled">
                    <AppTooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                      </template>
                      <span>{{$t('fileTooLarge')}}</span>
                    </AppTooltip>
                  </template>
                </template>
              </AppClosdFildersTree>
            </template>
            <template v-else>
              <app-text variant="small-regular" class="text-center mt-6">
                {{ $t("common.filders.ClosdFildersSelector.noDocuments") }}
              </app-text>
            </template>
          </template>
        </v-tab-item>

        <v-tab-item value="checklist">
          <template v-if="checklistDocumentsAreLoading">
            <div class="text-center">
              <v-progress-circular
                color="primary"
                class="my-14"
                size="48"
                indeterminate
              />
            </div>
          </template>
          <template v-else>
            <template v-if="atLeastOneDocumentIsAvailable">
              <AppClosdFildersTree
                v-model="selectedChecklistDocuments"
                :rootFolder="checklistDocumentsRoot"
                :multiple="multiple"
                :files-only="filesOnly"
                disable-folder-without-file
                :disable-files-too-large-to-be-signed="true"
                :maxSignableSize="maxSignableSize"
              >
                <template v-slot:label="{ item }">
                  <template v-if="item.disabled">
                    <AppTooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ item.name }}</span>
                      </template>
                      <span>{{$t('fileTooLarge')}}</span>
                    </AppTooltip>
                  </template>
                </template>
              </AppClosdFildersTree>
            </template>
            <template v-else>
              <app-text variant="small-regular" class="text-center mt-5">
                {{ $t("common.filders.ClosdFildersSelector.noTasks") }}
              </app-text>
            </template>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <AppButton v-if="atLeastOneDocumentIsAvailable"
               :disabled="!canValidateDocumentsSelection"
               type="primary"
               size="small"
               @click="validateDocumentsSelection"
    >
      <font-awesome-icon :icon="['far', 'plus']" class="mr-2" />
      {{ $t("common.filders.ClosdFildersSelector.addToSelection") }}
    </AppButton>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es/lodash'
import { defineComponent } from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'

import AppClosdFildersTree from '@/common/app-closd-filders-tree/AppClosdFildersTree.vue'
import AppTooltip from '@/common/AppTooltip.vue'
import { flattenFildersTree, isFileLowerThanMaxSignableSize } from '@/common/utils/files'
import AppButton from '@/design-system/buttons/AppButton.vue'
import { GET_DOCUMENTS_BY_ID } from '@/store/modules/documents/action_types'
import { GET_TODOS_FOLDER } from '@/store/modules/todo/action_types'

export default defineComponent({
  name: 'ClosdFildersSelector',
  components: {
    AppButton,
    AppClosdFildersTree,
    AppTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    filesOnly: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      currentTab: 'documents',
      documentsRoot: null,
      checklistDocumentsRoot: null,
      selectedDocuments: [],
      selectedChecklistDocuments: [],
    }
  },
  computed: {
    ...mapState('documents', {
      documents: state => state.documents,
      documentsAreLoading: state => state.loading,
    }),
    ...mapState('todo', {
      todosFolder: state => state.todosFolder,
      checklistDocumentsAreLoading: state => state.todosFolderPending,
    }),
    ...mapState('todo', ['todosFolder']),
    ...mapGetters('room', ['maxSignableSize', 'roomMnemo']),
    atLeastOneDocumentIsAvailable () {
      return this.documentsRoot && this.documentsRoot.children.length !== 0
    },
    canValidateDocumentsSelection () {
      const atLeastOneDocumentIsSelected = this.selectedDocuments.length !== 0 || this.selectedChecklistDocuments.length !== 0
      return atLeastOneDocumentIsSelected && !this.disabled
    },
  },
  watch: {
    // Those watchers are there to make sure only one item can be selected at a time
    // When multiple=false
    selectedDocuments (old, current) {
      if (!this.multiple && (current.length + this.selectedChecklistDocuments.length) > 1) {
        if (this.selectedDocuments.length > 1) {
          this.selectedDocuments = [current.pop()]
        }
        if (this.selectedDocuments.length !== 0 && this.selectedChecklistDocuments.length !== 0) {
          this.selectedChecklistDocuments = []
        }
      }
    },
    selectedChecklistDocuments (old, current) {
      if (!this.multiple && (current.length + this.selectedDocuments.length) > 1) {
        if (this.selectedChecklistDocuments.length > 1) {
          this.selectedChecklistDocuments = [current.pop()]
        }
        if (this.selectedChecklistDocuments.length !== 0 && this.selectedDocuments.length !== 0) {
          this.selectedDocuments = []
        }
      }
    },
  },
  created () {
    this.loadClosdDocuments()
    this.loadChecklistDocuments()
  },
  methods: {
    ...mapActions('documents', [GET_DOCUMENTS_BY_ID]),
    ...mapActions('todo', [GET_TODOS_FOLDER]),
    async loadClosdDocuments () {
      const fileType = ['pdf']
      if (process.env.VUE_APP_ENABLE_WORD_SIGNING) {
        fileType.push('doc', 'docx')
      }

      await this.GET_DOCUMENTS_BY_ID({
        mnemo: this.roomMnemo,
        queryObject: {
          ignoreTodoFolder: true,
          lazyMode: false,
          fileType,
        },
        id: 0,
      })

      const signableDocuments = this.mapSignableDocuments(this.documents.children)
      this.documentsRoot = {
        ...this.documents,
        children: orderBy(signableDocuments, ['type', 'basename'], ['desc', 'asc']),
      }
    },
    async loadChecklistDocuments () {
      const fileType = ['pdf']
      if (process.env.VUE_APP_ENABLE_WORD_SIGNING) {
        fileType.push('doc', 'docx')
      }

      await this.GET_TODOS_FOLDER({
        mnemo: this.roomMnemo,
        queryObject: {
          lazyMode: false,
          fileType,
        },
      })

      const signableChecklistDocuments = this.mapSignableDocuments(this.todosFolder.children)
      this.checklistDocumentsRoot = {
        ...this.todosFolder,
        children: orderBy(signableChecklistDocuments, ['type', 'basename'], ['desc', 'asc']),
      }
    },
    mapSignableDocuments (documents) {
      return documents.map((document) => {
        if (document.size && !isFileLowerThanMaxSignableSize(document, this.maxSignableSize)) {
          document.disabled = true
        } else if (document.type === 'folder') {
          document.children = this.mapSignableDocuments(document.children)
        }
        return document
      })
    },
    validateDocumentsSelection () {
      const selectedFiles = [
        ...flattenFildersTree(this.selectedDocuments),
        ...flattenFildersTree(this.selectedChecklistDocuments),
      ]

      this.$emit('select', selectedFiles)

      this.selectedDocuments = []
      this.selectedChecklistDocuments = []
    },
  },
})
</script>

<style scoped lang="scss">
.closd-filders-selector {
  align-items: center;
  border-radius: 7px;
  border: 2px dashed #E0E0E0;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__tabs-items {
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>
