<template>
  <AppDialog
    :isOpen.sync="visibility"
    :size="dialogMaxSize"
    @cancel="closeDialog"
  >
    <AddMemberForm v-if="dialogContent === GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_FORM"
                   :memberForm.sync="memberForm"
                   :group="group"
                   :okLoading="postMemberIsInProgress || postMemberFromTeamLoading"
                   @previewInvitation="dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.INVITATION_PREVIEW"
                   @change-add-member-type="addMemberType = $event"
                   @onCancel="closeDialog"
                   @onValidate="onValidate"
    />

    <MemberInvitationPreview v-if="dialogContent === GROUP_ADD_MEMBER_DIALOG_CONTENT.INVITATION_PREVIEW"
                             :memberForm="memberForm"
                             @closeInvitationPreview="dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_FORM"
    />

    <MemberProjectUpgrade v-if="dialogContent === GROUP_ADD_MEMBER_DIALOG_CONTENT.PROJECT_UPGRADE"
                          :errorData="memberProjectUpgradeErrorData"
                          :okLoading="postMemberIsInProgress"
                          @onCancel="dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_FORM"
                          @onUpgradeDecline="closeDialog"
                          @onUpgradeValidate="preparePostMember"
    />

    <MemberSubscription v-if="dialogContent === GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_SUBSCRIPTION"
                        :memberForm="memberForm"
                        :errorData="memberSubscriptionErrorData"
                        :okLoading="postMemberIsInProgress"
                        @onCancel="dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_FORM"
                        @onSubscriptionDecline="cancelUser"
                        @onSubscriptionValidate="preparePostMember"
    />

    <template v-if="dialogContent === GROUP_ADD_MEMBER_DIALOG_CONTENT.IRRECOVERABLE_ERROR">
      <v-card>
        <v-card-text class="pt-6 pb-0">
          <div>
            {{
              $t('project.members.dialogs.GroupImportDialog.error.ERR_FORBIDDEN_INTRO', {
                'subName': subName,
                'user': (memberForm.firstName + ' ' + memberForm.lastName),
              })
            }}
          </div>
          <div v-if="errorCode === 'ERR_FORBIDDEN_FOR_NON_SUB_USER'" class="mt-2">
            {{
              $t('project.members.dialogs.GroupImportDialog.error.ERR_FORBIDDEN_FOR_NON_SUB_USER', {
                'subName': subName,
                'user': (memberForm.firstName + ' ' + memberForm.lastName),
              })
            }}
          </div>
          <div v-if="errorCode === 'ERR_FORBIDDEN_FOR_NON_SUB_ADMIN'" class="mt-2">
            {{
              $t('project.members.dialogs.GroupImportDialog.error.ERR_FORBIDDEN_FOR_NON_SUB_ADMIN', {
                'subName': subName,
                'user': (memberForm.firstName + ' ' + memberForm.lastName),
              })
            }}
            <div class="mt-2">
              <div v-for="admin in subAdminContacts" :key="admin.email">- {{ admin.fullName }} ({{ admin.email }})</div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <AppButton @click="closeDialog">
            {{ $t('common.cancel') }}
          </AppButton>
        </v-card-actions>
      </v-card>
    </template>
  </AppDialog>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'

import AppButton from '@/common/buttons/AppButton'
import { MemberFormModel } from '@/models/memberForm.model'
import MemberInvitationPreview from '@/project/members/MemberInvitationPreview'
import { ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_WARNING_SNACKBAR } from '@/store/mutation_types'

import AppDialog from '../../../common/dialogs/AppDialog.vue'
import AddMemberForm from '../AddMemberForm'
import MemberProjectUpgrade from '../MemberProjectUpgrade'
import MemberSubscription from '../MemberSubscription'
import { MEMBER_TYPE_OPTIONS } from '../types.ts'

const MODAL_FADE_TRANSITION_TIME = 300

const GROUP_ADD_MEMBER_DIALOG_CONTENT = {
  MEMBER_FORM: 'member-form',
  INVITATION_PREVIEW: 'invitation-preview',
  PROJECT_UPGRADE: 'project-upgrade',
  MEMBER_SUBSCRIPTION: 'member-subscription',
  IRRECOVERABLE_ERROR: 'irrecoverable-error',
}

export default {
  name: 'GroupAddMemberDialog',
  components: {
    AppButton,
    AddMemberForm,
    MemberInvitationPreview,
    MemberProjectUpgrade,
    MemberSubscription,
    AppDialog,
  },
  mixins: [validationMixin],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      MEMBER_TYPE_OPTIONS,
      addMemberType: MEMBER_TYPE_OPTIONS.NEW_MEMBER,
      dialogContent: GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_FORM,
      errorCode: null,
      memberForm: new MemberFormModel(),
      memberProjectUpgradeErrorData: null,
      memberSubscriptionErrorData: null,
      postMemberIsInProgress: false,
      subAdminContacts: null,
      subName: null,
      visibility: true,
      GROUP_ADD_MEMBER_DIALOG_CONTENT,
    }
  },
  computed: {
    ...mapState('user', ['profile']),
    ...mapState('groups', ['groups', 'postMemberFromTeamLoading']),
    ...mapGetters('room', ['roomMnemo']),
    dialogMaxSize () {
      return this.dialogContent === GROUP_ADD_MEMBER_DIALOG_CONTENT.INVITATION_PREVIEW ? 'xl' : 'l'
    },
  },
  watch: {
    visibility (currentlyVisible) {
      if (!currentlyVisible) {
        setTimeout(() => {
          this.$emit('onClose')
        }, MODAL_FADE_TRANSITION_TIME)
      }
    },
  },
  mounted () {
    if (this.profile.locale) {
      this.memberForm.locale = this.profile.locale
    }
  },
  methods: {
    ...mapActions('groups', ['POST_MEMBER', 'GET_GROUPS', 'POST_USER_MISSED_ACCESS', 'POST_MEMBER_FROM_TEAM']),
    ...mapMutations([ENQUEUE_ERROR_SNACKBAR, ENQUEUE_SUCCESS_SNACKBAR, ENQUEUE_WARNING_SNACKBAR]),
    closeDialog () {
      this.visibility = false
    },
    async cancelUser () {
      await this.POST_USER_MISSED_ACCESS({
        mnemo: this.roomMnemo,
        data: { email: this.memberForm.email },
      })
      this.closeDialog()
    },
    onValidate () {
      if (this.addMemberType === MEMBER_TYPE_OPTIONS.NEW_MEMBER) {
        this.preparePostMember()
      } else {
        this.preparePostMemberFromTeam()
      }
    },
    async preparePostMember (params) {
      this.postMemberIsInProgress = true

      try {
        const {
          askForIdCheck,
          customInvitationMessage,
          projectManagersAreHidden,
          sendInvitationNow,
          withCustomInvitationMessage,
          ...memberData
        } = this.memberForm

        const data = {
          ...memberData,
          groupId: this.group.id,
          sendInvitationNow: sendInvitationNow,
          idVerification: askForIdCheck,
          hidePm: projectManagersAreHidden,
        }

        if (withCustomInvitationMessage) {
          data.customMessage = customInvitationMessage
        }

        await this.POST_MEMBER({
          data,
          params,
        })

        this.$store.commit('enqueueSnackbar', {
          color: 'success',
          timeout: 3000,
          message:
            `${this.memberForm.firstName} ${this.memberForm.lastName} ${this.$t('project.members.dialogs.GroupAddMemberDialog.userAddedSuccess')}`,
        })
        this.GET_GROUPS(this.roomMnemo)
        this.closeDialog()
      } catch (error) {
        if (error.response?.status === 300) {
          if (error.response?.data?.type === 'upgrade') {
            this.memberProjectUpgradeErrorData = error.response.data
            this.dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.PROJECT_UPGRADE
          } else if (error.response?.data) {
            this.memberSubscriptionErrorData = error.response.data
            this.dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.MEMBER_SUBSCRIPTION
          }
        } else if (error.response?.data?.errorCode) {
          switch (error.response.data.errorCode) {
            case 'ERR_USER_ALREADY_IN_ROOM':
            case 'ERR_INVALID_INPUT': {
              this.$store.commit('enqueueSnackbar', {
                color: 'error',
                timeout: 3000,
                message: this.$t(`project.members.dialogs.GroupAddMemberDialog.errors.${error.response.data.errorCode}`),
              })
              break
            }
            case 'ERR_FORBIDDEN_FOR_NON_SUB_USER': {
              this.errorCode = 'ERR_FORBIDDEN_FOR_NON_SUB_USER'
              this.dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.IRRECOVERABLE_ERROR
              this.subName = error.response.data.errorContext?.subName
              break
            }
            case 'ERR_FORBIDDEN_FOR_NON_SUB_ADMIN': {
              this.errorCode = 'ERR_FORBIDDEN_FOR_NON_SUB_ADMIN'
              this.dialogContent = GROUP_ADD_MEMBER_DIALOG_CONTENT.IRRECOVERABLE_ERROR
              this.subName = error.response.data.errorContext?.subName
              this.subAdminContacts = error.response.data.errorContext?.subAdmins
              break
            }
          }
        } else {
          this.$store.commit('enqueueSnackbar', {
            color: 'error',
            timeout: 3000,
            message: this.$t('common.msgFailErrorOccurred'),
          })
        }
      } finally {
        this.postMemberIsInProgress = false
      }
    },
    async preparePostMemberFromTeam () {
      const {
        sendInvitationNow,
        askForIdCheck,
        withCustomInvitationMessage,
        customInvitationMessage,
        team,
      } = this.memberForm

      try {
        const response = await this.POST_MEMBER_FROM_TEAM({
          mnemo: this.roomMnemo,
          data: {
            groupId: this.group.id,
            teamId: team.id,
            sendInvitationNow: sendInvitationNow,
            idVerification: askForIdCheck,
            customMessage: withCustomInvitationMessage ? customInvitationMessage : '',
          },
        })
        if (response?.data?.someUsersAlreadyInRoom) {
          this.ENQUEUE_WARNING_SNACKBAR(this.$t('project.members.AddMemberFromTeam.someUsersAlreadyInRoom'))
        } else {
          this.ENQUEUE_SUCCESS_SNACKBAR(this.$t('project.members.AddMemberFromTeam.addParticipantsSuccess', { groupName: this.group.name }))
        }
        this.GET_GROUPS(this.roomMnemo)
        this.closeDialog()
      } catch {
        this.ENQUEUE_ERROR_SNACKBAR(this.$t('project.members.AddMemberFromTeam.addParticipantsError'))
      }
    },
  },
}
</script>
