<template>
<div class="document-selection-list-item"
     :class="{
        'item--info': variant === 'info',
        'item--neutral': variant === 'neutral',
    }"
>
  <slot name="prepend">
    <app-badge v-if="document.version" :variant="variant">{{ `v${document.version}` }}</app-badge>
  </slot>
  <ClosdFildersIcon :document="document" :size="document.type === 'folder' ? 24 : 22"/>
  <AppTooltip top>
    <template #activator="{ on, attrs }">
    <div class="document-name"
            v-bind="attrs"
            v-on="on"
    >
        {{ displayedDocumentName }}
    </div>
    </template>
    {{ displayedDocumentName }}
  </AppTooltip>
  <div class="document-append">
    <slot name="append"></slot>
    <app-icon v-if="removable" icon-name="xmark" class="clickable ml-auto grey--text text--darken-1" @click="onRemoveDocument(document.id)" />
  </div>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import AppTooltip from '@/common/AppTooltip'
import ClosdFildersIcon from '@/common/filders/ClosdFildersIcon'

const TRANSLATION_KEY_PREFIX = 'common.document-selection.DocumentSelectionListItem'

export default {
  name: 'DocumentSelectionListItem',
  components: {
    AppTooltip,
    ClosdFildersIcon,
  },
  emits: ['remove'],
  props: {
    document: {
      type: [Object, File],
      required: true,
    },
    removable: {
      type: Boolean,
    },
    variant: {
      type: String,
      default: 'neutral',
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
    }
  },
  computed: {
    ...mapState('documents', ['numberingEnabled']),
    displayedDocumentName () {
      const documentName = this.document.basename || this.document.name
      return this.numberingEnabled && this.document.numbering ? this.document.numbering + ' ' + documentName : documentName
    },
  },
  methods: {
    onRemoveDocument (documentId) {
      this.$emit('remove', documentId)
    },
  },
}
</script>

<style lang="scss" scoped>
.document-selection-list-item {
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 48px;
  padding: 8px 16px;

  &.item--neutral {
    border: 1px solid var(--v-grey-lighten5);
    background-color: var(--v-grey-lighten6);
  }

  &.item--info {
    border: 2px solid var(--v-info-darken1);
    background-color: var(--v-info-lighten5);
  }

  .document-name {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .document-append {
    align-items: center;
    column-gap: 12px;
    display: flex;
    flex-grow: 1;
    justify-content: end;
    margin-left: 12px;
  }
}
::v-deep .v-skeleton-loader__text {
  margin-bottom: 0;
}
</style>
