import { track } from '@/common/pendo/agent'
import { getRoomRouteType } from '@/common/utils/rooms'
import { DataRoomToPost, RoomToPost } from '@/models/room.model'
import router, { ROOM_ROUTE_NAME } from '@/router'
import roomService from '@/services/room.service'
import { SET_CHECKLIST_FILTERS } from '@/store/modules/checklist/mutation_types'
import { SET_DOCUMENT_FILTERS } from '@/store/modules/documents/mutation_types'
import { SET_SIGNING_CHECKLIST_FILTERS } from '@/store/modules/signing-checklist/mutation_types'

import {
  CHASE_USERS,
  CHASE_VALIDATOR,
  CLOSE_ROOM,
  GET_MORE_SIGNATURES,
  GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT,
  GET_ROOM_PROVIDERS,
  GET_ROOM_RIGHTS,
  GET_ROOM_STATS,
  GET_SIGNATURES,
  GET_ROOM_NOTIFICATIONS,
  PATCH_ROOM,
  PATCH_ROOM_GLOBAL_RIGHTS,
  PATCH_ROOM_GROUP_RIGHTS,
  PATCH_ROOM_USER_RIGHTS,
  POST_ROOM_STORAGE_ADDON,
  NOTIFY_PM_NO_MORE_SPACE,
  NOTIFY_PM_PLAN_DATAROOM_FULL,
  UPDATE_ACCESS_TIME,
  MOVE_ROOM,
} from './action_types'
import {
  ADD_TO_SIGNATURES,
  REMOVE_CHASE_VALIDATOR_PENDING,
  RESET_ROOM,
  SET_CHASE_USERS_ERROR,
  SET_CHASE_USERS_PENDING,
  SET_CHASE_VALIDATOR_ERROR,
  SET_CHASE_VALIDATOR_PENDING,
  SET_CLOSE_ROOM_ERROR,
  SET_CLOSE_ROOM_PENDING,
  SET_CURRENT_ROOM,
  SET_CURRENT_ROOM_PROVIDERS,
  SET_LAST_ACCESS_TIMESTAMP,
  SET_LAST_MNEMO_ACCESSED,
  SET_MORE_SIGNATURES_ERROR,
  SET_MORE_SIGNATURES_PENDING,
  SET_NOTIFY_PM_NO_MORE_SPACE_PENDING,
  SET_NOTIFY_PM_PLAN_DATAROOM_FULL_PENDING,
  SET_PATCH_ROOM_ERROR,
  SET_PATCH_ROOM_GLOBAL_RIGHTS_ERROR,
  SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING,
  SET_PATCH_ROOM_GROUP_RIGHTS_ERROR,
  SET_PATCH_ROOM_GROUP_RIGHTS_PENDING,
  SET_PATCH_ROOM_PENDING,
  SET_PATCH_ROOM_USER_RIGHTS_ERROR,
  SET_PATCH_ROOM_USER_RIGHTS_PENDING,
  SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT,
  SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING,
  SET_ROOM_PROVIDERS_ERROR,
  SET_ROOM_PROVIDERS_PENDING,
  SET_ROOM_RIGHTS,
  SET_ROOM_RIGHTS_ERROR,
  SET_ROOM_RIGHTS_PENDING,
  SET_ROOM_STATS,
  SET_ROOM_STATS_ERROR,
  SET_ROOM_STATS_PENDING,
  SET_ROOM_STORAGE_ADDON_PENDING,
  SET_SIGNATURES,
  SET_SIGNATURES_ERROR,
  SET_SIGNATURES_PENDING,
  SET_ROOM_NOTIFICATIONS_PENDING,
  SET_ROOM_NOTIFICATIONS,
  SET_ROOM_NOTIFICATIONS_ERROR,
  SET_UPDATE_ACCESS_TIME_ERROR,
  SET_UPDATE_ACCESS_TIME_PENDING,
  SET_MOVE_ROOM_PENDING,
  SET_ROOM_TO_MOVE,
} from './mutation_types'

const ROOM_ACCESS_TIME_THROTTLE_MS = 60000

export const actions = {
  async [NOTIFY_PM_NO_MORE_SPACE] ({ commit }, { mnemo }) {
    commit(SET_NOTIFY_PM_NO_MORE_SPACE_PENDING, true)
    try {
      return await roomService.notifyPmNoMoreSpace(mnemo)
    } finally {
      commit(SET_NOTIFY_PM_NO_MORE_SPACE_PENDING, false)
    }
  },
  async [NOTIFY_PM_PLAN_DATAROOM_FULL] ({ commit }, { mnemo }) {
    commit(SET_NOTIFY_PM_PLAN_DATAROOM_FULL_PENDING, true)
    try {
      return await roomService.notifyPmPlanDataroomFull(mnemo)
    } finally {
      commit(SET_NOTIFY_PM_PLAN_DATAROOM_FULL_PENDING, false)
    }
  },
  async [CHASE_VALIDATOR] ({ commit, state }, { mnemo, envelopeId, data }) {
    if (data.length) {
      commit(SET_CHASE_VALIDATOR_PENDING, data[0])
    }
    try {
      return await roomService.chaseValidator(mnemo, envelopeId, data)
    } catch (error) {
      commit(SET_CHASE_VALIDATOR_ERROR, error)
      throw error
    } finally {
      if (data.length) {
        commit(REMOVE_CHASE_VALIDATOR_PENDING,
          state.chaseValidatorPendingIds.indexOf(data[0]))
      }
    }
  },
  async [PATCH_ROOM_GLOBAL_RIGHTS] ({ commit }, { mnemo, data }) {
    commit(SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING, true)
    try {
      const response = await roomService.patchRoomGlobalRights(mnemo, data)
      commit(SET_ROOM_RIGHTS, response.data.rights)
    } catch (error) {
      commit(SET_PATCH_ROOM_GLOBAL_RIGHTS_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_ROOM_GLOBAL_RIGHTS_PENDING, false)
    }
  },
  async [PATCH_ROOM_GROUP_RIGHTS] ({ commit }, { mnemo, groupId, data }) {
    commit(SET_PATCH_ROOM_GROUP_RIGHTS_PENDING, true)
    try {
      const response = await roomService.patchRoomGroupRights(mnemo, groupId, data)
      commit(SET_ROOM_RIGHTS, response.data.rights)
    } catch (error) {
      commit(SET_PATCH_ROOM_GROUP_RIGHTS_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_ROOM_GROUP_RIGHTS_PENDING, false)
    }
  },
  async [PATCH_ROOM_USER_RIGHTS] ({ commit }, { mnemo, userId, data }) {
    commit(SET_PATCH_ROOM_USER_RIGHTS_PENDING, true)
    try {
      const response = await roomService.patchRoomUserRights(mnemo, userId, data)
      commit(SET_ROOM_RIGHTS, response.data.rights)
    } catch (error) {
      commit(SET_PATCH_ROOM_USER_RIGHTS_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_ROOM_USER_RIGHTS_PENDING, false)
    }
  },
  async [GET_ROOM_RIGHTS] ({ commit }, mnemo) {
    commit(SET_ROOM_RIGHTS_PENDING, true)
    try {
      const response = await roomService.getRoomRights(mnemo)
      commit(SET_ROOM_RIGHTS, response.data.rights)
    } catch (error) {
      commit(SET_ROOM_RIGHTS_ERROR, error)
      throw error
    } finally {
      commit(SET_ROOM_RIGHTS_PENDING, false)
    }
  },
  async [UPDATE_ACCESS_TIME] ({ commit, state }, mnemo) {
    const lastAccessTimePlusThrottleTime = state.lastAccessTimestamp + ROOM_ACCESS_TIME_THROTTLE_MS
    if (mnemo === state.lastMnemoAccessed && (lastAccessTimePlusThrottleTime > Date.now())) {
      return
    }
    commit(SET_LAST_MNEMO_ACCESSED, mnemo)
    commit(SET_LAST_ACCESS_TIMESTAMP, Date.now())
    commit(SET_UPDATE_ACCESS_TIME_PENDING, true)
    try {
      await roomService.updateAccessTime(mnemo)
    } catch (error) {
      commit(SET_UPDATE_ACCESS_TIME_ERROR, error)
      throw error
    } finally {
      commit(SET_UPDATE_ACCESS_TIME_PENDING, false)
    }
  },
  async [GET_ROOM_STATS] ({ commit }, mnemo) {
    commit(SET_ROOM_STATS_PENDING, true)
    try {
      const response = await roomService.getRoomStats(mnemo)
      commit(SET_ROOM_STATS, response.data)
    } catch (error) {
      commit(SET_ROOM_STATS_ERROR, error)
      throw error
    } finally {
      commit(SET_ROOM_STATS_PENDING, false)
    }
  },
  async [CLOSE_ROOM] ({ commit }, { mnemo, data }) {
    commit(SET_CLOSE_ROOM_PENDING, true)
    try {
      await roomService.closeRoom(mnemo, data)
    } catch (error) {
      commit(SET_CLOSE_ROOM_ERROR, error)
      throw error
    } finally {
      commit(SET_CLOSE_ROOM_PENDING, false)
    }
  },
  async [PATCH_ROOM] ({ commit }, { mnemo, data }) {
    commit(SET_PATCH_ROOM_PENDING, true)
    try {
      await roomService.patchRoomInformation(mnemo, data)
    } catch (error) {
      commit(SET_PATCH_ROOM_ERROR, error)
      throw error
    } finally {
      commit(SET_PATCH_ROOM_PENDING, false)
    }
  },
  async [CHASE_USERS] ({ commit }, { mnemo, envelopeId, data }) {
    commit(SET_CHASE_USERS_PENDING, true)
    try {
      await roomService.chaseUsers(mnemo, envelopeId, data)
    } catch (error) {
      commit(SET_CHASE_USERS_ERROR, error)
      throw error
    } finally {
      commit(SET_CHASE_USERS_PENDING, false)
    }
  },
  async [GET_MORE_SIGNATURES] ({ commit, dispatch }, { mnemo, page }) {
    commit(SET_MORE_SIGNATURES_PENDING, true)
    try {
      const response = await roomService.getSignatures(mnemo, { page: page })
      commit(ADD_TO_SIGNATURES, response.data.data)
      if (response.data.pagination.pageCount > page) {
        dispatch(GET_MORE_SIGNATURES, { mnemo, page: page + 1 })
      }
    } catch (error) {
      commit(SET_MORE_SIGNATURES_ERROR, error)
      throw error
    } finally {
      commit(SET_MORE_SIGNATURES_PENDING, false)
    }
  },
  async [GET_SIGNATURES] ({ commit, dispatch }, mnemo) {
    commit(SET_SIGNATURES_PENDING, true)
    try {
      const response = await roomService.getSignatures(mnemo)
      commit(SET_SIGNATURES, response.data)
      if (response.data.pagination.pageCount > 1) {
        dispatch(GET_MORE_SIGNATURES, { mnemo, page: 2 })
      }
    } catch (error) {
      commit(SET_SIGNATURES_ERROR, error)
      throw error
    } finally {
      commit(SET_SIGNATURES_PENDING, false)
    }
  },
  async [GET_ROOM_NOTIFICATIONS] ({ commit }, { mnemo, params }) {
    commit(SET_ROOM_NOTIFICATIONS_PENDING, true)
    commit(SET_ROOM_NOTIFICATIONS_ERROR, null)
    try {
      const response = await roomService.getRoomNotifications(mnemo, { params: params })
      commit(SET_ROOM_NOTIFICATIONS, response.data)
    } catch (error) {
      commit(SET_ROOM_NOTIFICATIONS_ERROR, error)
      console.error(error)
      throw error
    } finally {
      commit(SET_ROOM_NOTIFICATIONS_PENDING, false)
    }
  },
  async [GET_ROOM_PROVIDERS] ({ commit }, mnemo) {
    commit(SET_ROOM_PROVIDERS_PENDING, true)
    try {
      const { data } = await roomService.getRoomProviders(mnemo)
      commit(SET_CURRENT_ROOM_PROVIDERS, data.providers)
    } catch (error) {
      commit(SET_ROOM_PROVIDERS_ERROR, error)
      throw error
    } finally {
      commit(SET_ROOM_PROVIDERS_PENDING, false)
    }
  },
  /**
   * @function getRoomByMnemo
   * @param {store} store
   * @param {string} mnemo
   */
  getRoomByMnemo: async function ({ commit, dispatch }, { mnemo, params = {} }) {
    commit('SET_ERROR', null)
    commit('SET_PROCESSING', true)
    return await roomService.getRoomByMnemo(mnemo, params)
      .then(response => {
        commit(SET_CURRENT_ROOM, response.data)
      })
      .catch(error => {
        commit('SET_ERROR', error)
      })
      .then(() => {
        commit('SET_PROCESSING', false)
      })
  },
  /**
   * @function postRoom
   * @param {store} store
   * @param {RoomToPost} room
   */
  postRoom: async function ({ commit }, room) {
    const isDataRoom = room instanceof DataRoomToPost
    if (room instanceof RoomToPost || isDataRoom) {
      commit('SET_POST_ROOM_PROCESSING', true)
      try {
        const response = await roomService.postRoom(room)
        commit('SET_NEW_ROOM_MODAL_IS_OPEN', false)
        commit(SET_CURRENT_ROOM, response.data)
        track(isDataRoom ? 'create_dr' : 'create_tm', {
          type: response.data.type,
          name: response.data.name,
        })
        router.push({
          name: ROOM_ROUTE_NAME,
          params: {
            mnemo: response.data.mnemo,
            roomType: getRoomRouteType(response.data),
          },
        })
      } catch (error) {
        commit('SET_ERROR', error)
        throw error
      } finally {
        commit('SET_POST_ROOM_PROCESSING', false)
      }
    }
  },
  /**
   * @function openNewRoomModal
   * @param {store} store
   * @param {boolean} value
   */
  openNewRoomModal: function ({ commit }, value) {
    commit('SET_NEW_ROOM_MODAL_IS_OPEN', value)
  },
  /**
   * @function patchRoom
   * @param {store} store
   * @param {Object} object - Room properties to patch
   */
  patchRoom: function ({ commit, state }, object) {
    roomService.patchRoom(state.currentRoom.mnemo, object)
  },
  killRoom: function ({ commit }) {
    commit(SET_CURRENT_ROOM, null)
    commit(RESET_ROOM)
    commit('groups/SET_GROUPS', [], { root: true })
    commit(`checklist/${SET_CHECKLIST_FILTERS}`, [], { root: true })
    commit(`documents/${SET_DOCUMENT_FILTERS}`, [], { root: true })
    commit(`signingChecklist/${SET_SIGNING_CHECKLIST_FILTERS}`, [], { root: true })
  },
  async [POST_ROOM_STORAGE_ADDON] ({ commit }, { mnemo, data }) {
    commit(SET_ROOM_STORAGE_ADDON_PENDING, true)
    try {
      const response = await roomService.postRoomStorageAddon(mnemo, data)
      commit(SET_CURRENT_ROOM, response.data.room)
    } finally {
      commit(SET_ROOM_STORAGE_ADDON_PENDING, false)
    }
  },
  async [GET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT] ({ commit }, { mnemo, params }) {
    commit(SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING, true)
    try {
      const response = await roomService.getRoomEnvelopesDownloadPreflight(mnemo, { params: params })
      commit(SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT, response.data)
    } finally {
      commit(SET_ROOM_ENVELOPES_DOWNLOAD_PREFLIGHT_PENDING, false)
    }
  },
  async [MOVE_ROOM] ({ commit }, { toFolderId, roomMnemo }) {
    commit(SET_MOVE_ROOM_PENDING, true)
    try {
      await roomService.moveRoomToFolder(toFolderId, roomMnemo)
      commit(SET_ROOM_TO_MOVE, {})
    } catch (error) {
      console.error(error)
      throw error
    } finally {
      commit(SET_MOVE_ROOM_PENDING, false)
    }
  },
}
