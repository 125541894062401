<template>
  <div class="d-flex flex-column row-gap-6">
    <template v-if="isCurrentUserPm || currentUserRights.canUploadVersions">
      <DocumentSelection :selected-documents="selectedDocuments">
        <template #item>
          <DocumentSelectionListItem v-for="document in selectedDocuments"
                                     :key="document.id"
                                     :document="document"
          >
            <template #append>
              <template v-if="isWopiEnabled">
                <v-skeleton-loader v-if="checkWopiLockedFilesPending" type="text" width="100" class="ml-auto" />
                <v-chip v-else-if="wopiLockedFiles.length > 0 && wopiLockedFiles.find(file => file.id === document.id)"
                        color="yellow lighten-4"
                        class="font-weight-medium px-2 d-flex align-center ml-auto no-shrink"
                        label
                        small
                        text-color="yellow darken-2"
                  >
                    <app-icon icon-name="triangle-exclamation" size="small" icon-weight="fas" />
                    <span class="ml-1">{{ $t(`${TRANSLATION_KEY_PREFIX}.editionOngoingTag`) }}</span>
                </v-chip>
              </template>
              <DocumentVersioningWopiDuplicationButton :document="document"
                                                       :duplication-available="isWopiDuplicationAvailable"
                                                       @duplicate="onDuplicate(document)"
              />
            </template>
          </DocumentSelectionListItem>
        </template>
      </DocumentSelection>
      <DocumentVersioningWopiDuplicationBox v-if="isWopiDuplicationBoxDisplayed" @hide="onHideBox" />
      <v-divider></v-divider>
      <div>
        <app-text variant="large-bold">{{ $t(`${TRANSLATION_KEY_PREFIX}.addVersionTitle`) }}</app-text>
        <template v-if="selectedDocuments.length > 0">
          <div class="d-flex align-center column-gap-2">
            <span>{{ $t(`${TRANSLATION_KEY_PREFIX}.addVersionText`) }}</span>
            <div class="d-flex align-center column-gap-1">
              <app-badge>{{ `v${selectedDocuments[0].version}` }}</app-badge>
              <app-icon icon-name="arrow-right" size="medium" />
              <app-badge variant="info">{{ `v${selectedDocuments[0].version + 1}` }}</app-badge>
            </div>
          </div>
        </template>
      </div>

      <template v-if="!newVersionFile">
        <v-file-input v-model="newVersionFile"
                      color="info darken-1"
                      :class="{ clickable : !isAddVersionFormDisabled }"
                      dense
                      :disabled="isAddVersionFormDisabled"
                      hide-details
                      outlined
                      prepend-icon="fal fa-paperclip"
                      :label="$t(`${TRANSLATION_KEY_PREFIX}.newVersionFilePlaceholder`)"
        ></v-file-input>

        <template v-if="imanageEnabledAndLogged">
          <app-text as="span" variant="large-bold" class="text-center">{{ $t('common.or') }}</app-text>
          <app-button type="tonal"
                      variant="neutral"
                      class="align-self-center"
                      @click="onOpenIManageFilesPicker"
          >
            <v-img src="/img/iManage_logo.png" width="25" />
            {{ $t(`${TRANSLATION_KEY_PREFIX}.importFromImanage`) }}
          </app-button>

          <IManageFilePickerDialog :is-open.sync="isImanageFilesPickerDialogOpen"
                                  :params="{ singleFile: true }"
                                  @files="onImanageFiles"
          />
        </template>
      </template>

      <template v-if="newVersionFile">
        <DocumentSelectionListItem :document="newVersionFile"
                                   removable
                                   variant="info"
                                   @remove="resetVersion"
        >
        <template #prepend>
          <app-badge variant="info">{{ `v${selectedDocuments[0].version + 1}` }}</app-badge>
        </template>
        </DocumentSelectionListItem>
      </template>

      <div class="d-flex flex-column row-gap-2">
        <v-checkbox v-model="cloneRightsFromPrevious"
                    hide-details
                    :disabled="isAddVersionFormDisabled"
                    :label="$t(`${TRANSLATION_KEY_PREFIX}.cloneRightsFromPrevious`)"
        />
        <v-checkbox v-model="sendNotification"
                    hide-details
                    :disabled="isAddVersionFormDisabled"
                    :label="$t(`${TRANSLATION_KEY_PREFIX}.sendNotificationLabel`)"
        />
        <template v-if="sendNotification">
          <VisualTextEditor v-model="notifyMessage"
                            counter="footer"
                            :error-messages="notifyMessageErrors"
                            :max-length="notifyMessageMaxLength"
                            :placeholder="$t(`${TRANSLATION_KEY_PREFIX}.sendNotificationMessagePlaceholder`)"
          />
        </template>
      </div>
    </template>

    <v-row dense>
      <v-col>
        <app-button fullWidth
                    type="outlined"
                    variant="neutral"
                    @click="onClosePane"
        >
          {{$t('common.cancel')}}
        </app-button>
      </v-col>
      <v-col>
        <app-button fullWidth
                    :loading="isAddVersionLoading"
                    :disabled="isAddVersionDisabled"
                    @click="onAddVersion"
        >
          {{$t('common.add')}}
        </app-button>
      </v-col>
    </v-row>

    <DocumentOnGoingEditionDialog v-if="isDocumentOnGoingEditionDialogOpen"
                                   current-action="addVersion"
                                   :documents="selectedDocuments"
                                   @confirm="confirmAddVersion"
                                   @close="isDocumentOnGoingEditionDialogOpen = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DocumentOnGoingEditionDialog from '@/common/dialogs/DocumentOnGoingEditionDialog'
import DocumentSelection from '@/common/document-selection/DocumentSelection'
import DocumentSelectionListItem from '@/common/document-selection/DocumentSelectionListItem'
import DocumentVersioningWopiDuplicationBox from '@/common/document-versioning/DocumentVersioningWopiDuplicationBox'
import DocumentVersioningWopiDuplicationButton from '@/common/document-versioning/DocumentVersioningWopiDuplicationButton'
import IManageFilePickerDialog from '@/common/imanage/IManageFilePickerDialog'
import { getFileExtension, isFileLowerThanMaxSignableSize } from '@/common/utils/files'
import {
  getOfficeDocumentType,
  OFFICE_EDITION_MAX_FILE_SIZE,
} from '@/common/utils/office'
import { escapeHtml } from '@/common/utils/strings'
import { getUserSetting, setUserSetting } from '@/common/utils/userSettings'
import VisualTextEditor from '@/common/visual-text-editor/VisualTextEditor'
import { CHECK_WOPI_LOCKED_FILES } from '@/store/modules/documents/action_types'
import { RESET_WOPI_LOCKED_FILES } from '@/store/modules/documents/mutation_types'

const NOTIFY_MESSAGE_MAX_LENGTH = 400
const WOPI_DUPLICATION_BOX_USER_SETTINGS_KEY = 'hide-wopi-duplication-box'
const TRANSLATION_KEY_PREFIX = 'common.document-versioning.DocumentVersioning'

export default {
  name: 'DocumentVersioning',
  components: {
    DocumentOnGoingEditionDialog,
    DocumentSelection,
    DocumentSelectionListItem,
    DocumentVersioningWopiDuplicationBox,
    DocumentVersioningWopiDuplicationButton,
    IManageFilePickerDialog,
    VisualTextEditor,
  },
  props: {
    loading: {
      type: Boolean,
    },
    selectedDocuments: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      TRANSLATION_KEY_PREFIX,
      cloneRightsFromPrevious: true,
      hideWopiDuplicationBox: false,
      isDocumentOnGoingEditionDialogOpen: false,
      isImanageFilesPickerDialogOpen: false,
      newVersionFile: null,
      newVersionFrom: null,
      notifyMessage: '',
      notifyMessageMaxLength: NOTIFY_MESSAGE_MAX_LENGTH,
      sendNotification: false,
    }
  },
  computed: {
    ...mapGetters('room', ['isCurrentUserPm', 'currentUserRights', 'isWopiEnabled', 'roomMnemo', 'isDataRoom']),
    ...mapGetters('imanage', ['imanageEnabledAndLogged']),
    ...mapState('documents', ['checkWopiLockedFilesPending', 'wopiLockedFiles']),
    ...mapGetters('user', ['currentUserId']),
    isAddVersionDisabled () {
      return !this.newVersionFile || this.isNotifyMessageTooLong || this.isDocumentOnGoingEditionDialogOpen
    },
    isAddVersionFormDisabled () {
      return this.isDocumentOnGoingEditionDialogOpen
    },
    isAddVersionLoading () {
      return this.checkWopiLockedFilesPending || this.loading
    },
    isNotifyMessageTooLong () {
      return this.notifyMessageLength > this.notifyMessageMaxLength
    },
    isWopiDuplicationAvailable () {
      return this.newVersionFile === null && !this.isDataRoom
    },
    isWopiDuplicationBoxDisplayed () {
      const shouldShowBox = this.selectedDocuments.filter(doc => {
        return doc.isUserFromSub && this.isDocumentSizeUnderOfficeEditionLimit(doc)
      })
      return this.shouldCheckForWopiLockOnFiles && !getUserSetting(this.currentUserId, WOPI_DUPLICATION_BOX_USER_SETTINGS_KEY) && this.isWopiDuplicationAvailable && shouldShowBox.length
    },
    notifyMessageErrors () {
      const errors = []

      if (this.isNotifyMessageTooLong) {
        errors.push(this.$t('common.validations.textTooLong'))
      }

      return errors
    },
    notifyMessageLength () {
      return escapeHtml(this.notifyMessage).length
    },
    possiblyWopiLockedFiles () {
      return this.selectedDocuments.filter(document => document.type === 'file' && document.canEdit)
    },
    shouldCheckForWopiLockOnFiles () {
      return this.isWopiEnabled && this.possiblyWopiLockedFiles.length > 0
    },
  },
  beforeDestroy () {
    this.RESET_WOPI_LOCKED_FILES()
  },
  mounted () {
    if (this.shouldCheckForWopiLockOnFiles) { this.checkWopiLockedFiles() }
  },
  watch: {
    isDocumentOnGoingEditionDialogOpen (newVal) {
      this.$emit('setIsDocumentOnGoingEditionDialogOpen', newVal)
    },
  },
  methods: {
    ...mapActions('documents', [CHECK_WOPI_LOCKED_FILES]),
    ...mapMutations('documents', [RESET_WOPI_LOCKED_FILES]),
    async checkWopiLockedFiles () {
      try {
        return await this.CHECK_WOPI_LOCKED_FILES({
            mnemo: this.roomMnemo,
            filesIds: this.possiblyWopiLockedFiles.map(file => file.id),
        })
      } catch (e) {
        console.error(e)
      }
    },
    async onAddVersion () {
      if (!this.shouldCheckForWopiLockOnFiles) {
        this.confirmAddVersion()
      } else {
        const response = await this.checkWopiLockedFiles()

        if (response.lockedFiles.length > 0) {
          this.isDocumentOnGoingEditionDialogOpen = true
        } else {
          this.confirmAddVersion()
        }
      }
    },
    onClosePane () {
      this.$emit('close-pane')
    },
    onDuplicate (document) {
      this.newVersionFrom = 'closd'
      this.newVersionFile = document
    },
    onHideBox (value) {
      this.hideWopiDuplicationBox = value
    },
    onOpenIManageFilesPicker () {
      this.isImanageFilesPickerDialogOpen = true
    },
    onImanageFiles (files) {
      this.newVersionFrom = 'imanage'

      const file = files.output_data?.selected[0]
      if (file) {
        this.newVersionFile = {
          id: file.id,
          name: file.name,
          extension: file.extension,
        }
      } else {
        this.newVersionFile = files.selected
      }
    },
    prepareAddVersion () {
      const formData = new FormData()

      if (this.newVersionFrom === 'closd') {
        formData.append('fromClosd', true)
        formData.append('fileSelect', this.newVersionFile.id)
      } else {
        formData.append('fromClosd', false)
        formData.append('fileSelect', this.newVersionFile)
      }

      formData.append('formCloneRightsFromPrevious', this.cloneRightsFromPrevious)
      formData.append('formToggleNotif', this.sendNotification)
      formData.append('formNotifMessage', this.notifyMessage)

      this.$emit('add-version', formData)
    },
    prepareAddVersionFromIManage (targetId) {
      const formData = {
        fileId: this.selectedDocuments[0].id,
        targetId: targetId,
        formCloneRightsFromPrevious: this.cloneRightsFromPrevious,
        formToggleNotif: this.sendNotification,
        formNotifMessage: this.notifyMessage,
      }

      this.$emit('add-version-from-imanage', formData)
    },
    resetVersion () {
      this.newVersionFile = null
      this.newVersionFrom = null
    },
    confirmAddVersion () {
      if (this.hideWopiDuplicationBox) {
        setUserSetting(this.currentUserId, WOPI_DUPLICATION_BOX_USER_SETTINGS_KEY, this.hideWopiDuplicationBox)
      }

      if (this.newVersionFrom === 'imanage') {
        this.prepareAddVersionFromIManage(this.newVersionFileFromImanage.id || this.newVersionFileFromImanage)
      } else {
        this.prepareAddVersion()
      }
      // Reset here because the dialog doesn't have time to close
      // to get the close event when click confirm
      this.isDocumentOnGoingEditionDialogOpen = false
    },
    isDocumentSizeUnderOfficeEditionLimit (doc) {
      if (!this.officeDocumentType) {
        return true
      }

      const editionMaxFileSize = OFFICE_EDITION_MAX_FILE_SIZE[this.officeDocumentType(doc)]
      return isFileLowerThanMaxSignableSize(doc, editionMaxFileSize)
    },
    officeDocumentType (doc) {
      const fileExtension = getFileExtension(doc)

      if (!fileExtension) {
        return null
      }

      return getOfficeDocumentType(fileExtension)
    },
  },
}
</script>

<style scoped lang="scss">
  ::v-deep .v-file-input {
    min-height: 48px;

    .fa-paperclip {
      color: #333;
      font-size: 20px;
    }
    .v-label {
      font-size: 14px;
    }
  }

  .v-application .v-text-field.v-input--is-disabled {
    background-color: transparent !important;
  }

  ::v-deep .v-input--is-disabled .v-input__slot {
    cursor: default !important;
  }

  .v-input--selection-controls {
    margin-top: 0;
  }
</style>
